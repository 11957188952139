@for (tableTotal of tableTotals; track tableTotal; let first = $first; let last = $last) {
  <div
    class="card-container"
    [ngClass]="{ inactive: !tableTotal.active }"
    (click)="tableTotalClick.emit(tableTotal)"
    [attr.data-testid]="tableTotal.label + 'card'"
    libRightClickMenuTrigger
    [matMenuTrigger]="trigger"
    [menuData]="{ tableTotal: tableTotal, first: first, last: last }"
    (position)="menuPosition = $event"
  >
    <div class="card card-condensed table-total-card" data-testid="table-total-card">
      <div class="total-info">
        <div class="item-title mat-body-2 color-primary-900 mb-1">{{ tableTotal.label }}</div>
        <div
          class="item-subtitle caption2 color-grey-800 formula"
          [title]="tableTotal.type_details.formula | metricTableTotalFormula"
        >
          {{ tableTotal.type_details.formula | metricTableTotalFormula }}
        </div>
        <div class="mt-1 color-grey-800">{{ tableTotal.type_details.units | unitSymbol: true }}</div>
      </div>
      <button
        class="context-menu"
        [matMenuTriggerFor]="menu"
        [matMenuTriggerData]="{ menuData: { tableTotal: tableTotal, first: first, last: last } }"
        [attr.data-testid]="tableTotal.label + 'menu'"
        (click)="$event.stopPropagation()"
      >
        <mat-icon class="icon-sm" svgIcon="more"></mat-icon>
      </button>
    </div>
  </div>
}

<div
  class="hidden-menu-trigger"
  [ngStyle]="{ left: menuPosition.x, top: menuPosition.y }"
  #trigger="matMenuTrigger"
  [matMenuTriggerFor]="menu"
></div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-menuData="menuData">
    <button mat-menu-item (click)="tableTotalClick.emit(menuData.tableTotal)">
      <mat-icon svgIcon="edit" class="icon-sm"></mat-icon>
      <span>{{ "Edit Table Total" | translate }}</span>
    </button>
    @if (!menuData.first) {
      <button
        mat-menu-item
        (click)="moveTableTotalCard.emit({ tableTotal: menuData.tableTotal, direction: eMoveDirection.up })"
      >
        <mat-icon svgIcon="arrow-full-up" class="icon-sm"></mat-icon>
        <span>{{ "Move Up" | translate }}</span>
      </button>
    }
    @if (!menuData.last) {
      <button
        mat-menu-item
        (click)="moveTableTotalCard.emit({ tableTotal: menuData.tableTotal, direction: eMoveDirection.down })"
      >
        <mat-icon svgIcon="arrow-full-down" class="icon-sm"></mat-icon>
        <span>{{ "Move Down" | translate }}</span>
      </button>
    }
    @if (!isAdmin || metricTableDefinition?.published) {
      @if (menuData.tableTotal.active) {
        <button mat-menu-item (click)="deactivateTotal.emit(menuData.tableTotal)">
          <mat-icon svgIcon="deactivate" class="icon-sm"></mat-icon>
          <span>{{ "Deactivate" | translate }}</span>
        </button>
      }
      @if (!menuData.tableTotal.active) {
        <button mat-menu-item (click)="activateTotal.emit(menuData.tableTotal)">
          <mat-icon svgIcon="activate" class="icon-sm"></mat-icon>
          <span>{{ "Activate" | translate }}</span>
        </button>
      }
    }
    @if (
      isAdmin
        ? !metricTableDefinition?.published
        : !((metricTableDefinition | isCoreTableInPlatform: metric : isAdmin) && metricTableDeactivationEnabled)
    ) {
      <button mat-menu-item (click)="deleteTableTotal(menuData.tableTotal)" class="color-error">
        <mat-icon class="icon-sm" svgIcon="trash"></mat-icon>
        <span>{{ "Delete" | translate }}</span>
      </button>
    }
  </ng-template>
</mat-menu>
