<lib-expandable-card
  [cardTitle]="'Novisto Framework Taxonomy ({taxonomiesCount})' | translate: { taxonomiesCount: taxonomies.length }"
  [maxHeight]="expandableCardMaxContentHeight"
  data-testid="novisto-framework-taxonomies"
>
  <div content class="expandable-card-sub-elements">
    @for (taxonomy of taxonomies; track taxonomy) {
      <lib-simple-text-card
        [cardTitle]="taxonomy.label"
        [text]="taxonomy.description ?? ''"
        [actionIcon]="enableDelete ? 'trash' : ''"
        (actionClick)="handleDelete.emit(taxonomy)"
      ></lib-simple-text-card>
    }

    @if (taxonomies.length <= 0) {
      <span>
        {{ "There is no framework taxonomy to display" | translate }}
      </span>
    }
  </div>

  @if (enableAdd) {
    <button actions lib-button buttonType="primary" (click)="handleAdd.emit()">+ {{ "Add" | translate }}</button>
  }
</lib-expandable-card>
