@if (curtainIsOpen$ | async) {
  <div class="curtain" role="dialog" aria-label="curtain">
    <div class="curtain-content">
      <div class="logo-wrapper">
        <lib-spinner [strokeWidth]="5" class="spinner" [diameter]="85"></lib-spinner>
        <div class="logo">
          <img src="assets/img/logo-n-white.svg" alt="Novisto Logo" />
        </div>
      </div>
      @if (curtainMessage$ | async; as message) {
        <h3 class="curtain-message">{{ message }}</h3>
      }
    </div>
  </div>
}
