import { Component, Input, ViewContainerRef } from '@angular/core';
import {
  ActionItem,
  CalculatedValueReferences,
  Metric,
  QuantitativeTypeDetails,
  SOURCE_CONFIGURATION,
  ValueDefinition,
  ValueDefinitionGroup,
} from '../../../../models';
import {
  AddVariableDialogComponent,
  AddVariableDialogData,
  Variables,
} from '../../add-variable-dialog/add-variable-dialog.component';
import { TranslateService } from '../../../../services/common';
import { UntypedFormControl } from '@angular/forms';
import { AlphabeticKeys } from '../../../../translations';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogsService } from '../../../../dialogs';
import { MetricApiService } from '../../../../services/types';
import { DeactivationUtils } from '../../../../classes/DeactivationUtils/deactivation-utils';
import { MetricStructureStateService } from '../../../services/metric-structure-state.service';

@Component({
  selector: 'lib-metric-structure-calculated-variables',
  templateUrl: './metric-structure-calculated-variables.component.html',
  styleUrls: ['./metric-structure-calculated-variables.component.scss'],
})
export class MetricStructureCalculatedVariablesComponent {
  @Input({ required: true }) valueDefinition!: ValueDefinition;
  @Input() metric?: Metric;
  @Input() units: ActionItem[] = [];
  @Input() set variablesFormControl(variablesFormControl: UntypedFormControl) {
    this.calcVariablesFormControl = variablesFormControl;
    this.getVariables(variablesFormControl.value as { [key: string]: CalculatedValueReferences });
  }
  @Input({ required: true }) sourceConfiguration!: SOURCE_CONFIGURATION;

  calcVariablesFormControl?: UntypedFormControl;
  variablesLoaded: boolean = true;
  variables?: ActionItem[];
  keys = this.translateService.listResources(AlphabeticKeys);
  cachedMetricsList: Metric[] = [];
  inactiveValueDefinitions: ValueDefinition[] = [];
  isAdmin: boolean = false;

  constructor(
    private dialogsService: DialogsService,
    private translateService: TranslateService,
    private metricsService: MetricApiService,
    private viewContainerRef: ViewContainerRef,
    private metricStructureService: MetricStructureStateService,
  ) {
    this.isAdmin = this.metricStructureService.isAdmin;
  }

  public launchAddVariableDialog(selectedCalculatedRef?: ActionItem<CalculatedValueReferences>): void {
    const dialogRef = this.dialogsService.open<AddVariableDialogComponent, AddVariableDialogData, Variables>(
      AddVariableDialogComponent,
      {
        viewContainerRef: this.viewContainerRef,
        data: {
          metric: this.metric!,
          targetValueDefinitionId: this.valueDefinition.id,
          variables: this.variables,
          selectedMetric: this.cachedMetricsList.find((metric) => metric.id === selectedCalculatedRef?.item?.metric_id),
          sourceConfiguration: this.sourceConfiguration,
          isAdmin: this.isAdmin,
        },
      },
    );
    dialogRef.afterClosed().subscribe((result?: Variables) => {
      if (result?.variablesDict) {
        this.getVariables(result.variablesDict);
      }
    });
  }

  public deleteVariable(variable: ActionItem): void {
    this.variables?.splice(this.variables?.indexOf(variable), 1);
    this.variables?.forEach((variable, index) => {
      variable.id = this.keys[index].title;
    });
    this.variables?.sort((a, b) => a.id.localeCompare(b.id));

    this.assignVariablesToControl();
  }

  public getVariables(variablesDict?: { [key: string]: CalculatedValueReferences }): void {
    this.variables = [];
    const variableObservables: Observable<any>[] = [];
    if (variablesDict) {
      Object.keys(variablesDict).forEach((key) => {
        const obs = this.getValueDefinitionDisplayDetails(variablesDict[key]).pipe(
          map((res) => {
            this.variables?.push(this.getVariableInfo(variablesDict[key], res.vd, res.grp, key));
            return res;
          }),
        );
        variableObservables.push(obs);
      });
      if (variableObservables?.length) {
        this.variablesLoaded = false;
        forkJoin(variableObservables).subscribe(() => {
          this.variables?.sort((a, b) => a.id.localeCompare(b.id));
          this.assignVariablesToControl();
          this.variablesLoaded = true;
        });
      }
    }
  }

  private getVariableInfo(
    cvr: CalculatedValueReferences,
    vd: ValueDefinition<QuantitativeTypeDetails>,
    grp: ValueDefinitionGroup,
    key: string,
  ): ActionItem<CalculatedValueReferences> {
    const unitSymbol = vd.type_details ? this.getUnitSymbol(vd.type_details) : '';
    const metric = this.cachedMetricsList.find((metric) => metric.id === cvr.metric_id);
    const grpIndex = metric?.value_definition_groups?.findIndex((x) => x.id === grp.id) ?? 0;
    const groupName = `${grp.label ?? this.translateService.instant('Group {iteration}', { iteration: grpIndex + 1 })}`;
    const variable: ActionItem<CalculatedValueReferences> = {
      id: key,
      title: vd.label ?? '',
      text: `(${unitSymbol})`,
      item: cvr,
      deactivated: DeactivationUtils.isDeactivated(vd),
    };
    if (cvr.metric_id === this.metric?.id) {
      variable.subtitle = this.translateService.instant('This metric - {groupName}', { groupName });
    } else {
      variable.subtitle = `${metric?.code} - ${groupName}`;
    }
    return variable;
  }

  public getValueDefinitionDisplayDetails(
    calRefValues: CalculatedValueReferences,
  ): Observable<{ vd: ValueDefinition<QuantitativeTypeDetails>; grp: ValueDefinitionGroup }> {
    return this.getMetric(calRefValues.metric_id).pipe(
      map((metric: Metric) => {
        const grp = metric.value_definition_groups?.find((grp) => grp.id === calRefValues.value_definition_group_id);

        const valueDef = grp?.value_definitions?.find((valDef) => valDef.id === calRefValues.value_definition_id);
        if (!grp || !valueDef) {
          throw new Error('value definition matching calculated reference value not found');
        }
        if (!valueDef.active) {
          this.inactiveValueDefinitions.push(valueDef);
        }
        return { vd: valueDef, grp };
      }),
    );
  }

  public getUnitSymbol(typeDetails: QuantitativeTypeDetails): string {
    const symbol: ActionItem | undefined = this.units.find(
      (unit) => unit.item.family === typeDetails.family && unit.item.code === typeDetails.units,
    );
    return symbol?.item.symbol || symbol?.item.code;
  }

  public getMetric(id: string): Observable<Metric> {
    const metric = this.metric?.id === id ? this.metric : this.cachedMetricsList.find((metric) => metric.id === id);
    if (metric) {
      return of(metric);
    }
    return this.metricsService
      .getMetric(id, {
        load_value_definition_groups: true,
        load_value_definitions: true,
      })
      .pipe(
        map((res) => {
          this.cachedMetricsList.push(res.data);
          return res.data;
        }),
      );
  }

  private assignVariablesToControl(): void {
    const variables =
      this.variables?.reduce(
        (refs: Record<string, CalculatedValueReferences>, variable) => ({ ...refs, [variable.id]: variable.item }),
        {},
      ) ?? {};
    const hasUpdates: boolean = this.areVariablesUpdated(
      (this.calcVariablesFormControl?.value || {}) as Record<string, CalculatedValueReferences>,
      variables,
    );

    this.calcVariablesFormControl?.setValue(variables);
    if (hasUpdates) {
      this.calcVariablesFormControl?.markAsDirty();
    } else {
      this.calcVariablesFormControl?.markAsPristine();
    }
  }

  private areVariablesUpdated(
    previousVariables: Record<string, CalculatedValueReferences>,
    updatedVariables: Record<string, CalculatedValueReferences>,
  ): boolean {
    if (Object.keys(previousVariables).length != Object.keys(updatedVariables).length) {
      return true;
    }

    return Object.keys(previousVariables).some(
      (key: string) => previousVariables[key].value_definition_id !== updatedVariables[key].value_definition_id,
    );
  }
}
