@switch (data.dataFormatType) {
  <!--choice: multiple=false, searchable=true-->
  @case (ValueDefinitionType.choice) {
    <lib-data-format-searchable-select-field
      [data]="data"
      [controlName]="controlName"
      [displayChoices]="getDisplayedChoicesList()"
      (delete)="deleteValue()"
    ></lib-data-format-searchable-select-field>
  }
  <!--choice_multiple: checkbox: multiple=true, searchable=false-->
  @case (ValueDefinitionType.choice_multiple) {
    <div class="multi-select" tabindex="0" id="{{ data.id }}">
      <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
      <div class="fx-col w-100 choice-formfield" [ngClass]="{ error: control.touched && control.invalid }">
        <div class="fx-col">
          @for (option of getDisplayedChoicesList(); track option) {
            <mat-checkbox
              color="primary"
              [checked]="data.data.value?.includes(option)"
              [value]="option"
              (change)="onMultiSelectToggle($event, option)"
              class="mat-body-1"
            >
              {{ option }}</mat-checkbox
            >
          }
        </div>
        @if (control.valid || control.touched) {
          <mat-hint>{{ data.data.hint }}</mat-hint>
        }
        @if (control.touched && control.hasError("required")) {
          <mat-error>
            {{ requiredErrorMsg }}
          </mat-error>
        }
      </div>
    </div>
  }
  <!--choice_searchable: autocomplete: multiple=true, searchable=true-->
  @case (ValueDefinitionType.choice_searchable) {
    <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
    <lib-multi-select-chip-input
      class="large"
      [hint]="data.data.hint"
      [control]="control"
      [options]="(filteredChoices | async) || []"
    >
    </lib-multi-select-chip-input>
  }
  <!--choice_radio: radio : multiple=false, searchable=false-->
  @case (ValueDefinitionType.choice_radio) {
    <div class="choice-radio" tabindex="0" id="{{ data.id }}">
      <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
      <div class="choice-formfield">
        <mat-radio-group color="primary" class="fx-col" [formControl]="control">
          @for (option of getDisplayedChoicesList(); track option) {
            <mat-radio-button [value]="option" class="mat-body-1">
              {{ option }}
            </mat-radio-button>
          }
        </mat-radio-group>
        <mat-hint>{{ data.data.hint }}</mat-hint>
      </div>
    </div>
  }
}
