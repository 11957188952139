<div class="tabs">
  @for (item of items; track item) {
    <ng-container *libPermission="item.permissions">
      <ng-container *libFeatureFlag="{ featureFlags: item.featureFlag }">
        <a
          role="tab"
          [name]="item.title"
          [routerLink]="item.id"
          [queryParamsHandling]="preserveQueryParams ? 'preserve' : ''"
          routerLinkActive="active"
          [title]="item.title"
          [ngClass]="{ disabled: item.disabled }"
          (click)="onChange(item)"
        >
          {{ item.title }}
        </a>
      </ng-container>
    </ng-container>
  }
</div>
