import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { ActionItem } from '../../../models';

interface PageChange {
  page: number;
}

@Component({
  selector: 'lib-carousel',
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
})
export class CarouselComponent<T> {
  @Input() items: ActionItem<T>[] = [];
  @Input() numVisible: number = 4;
  @Input() numScroll: number = 4;
  @Input() circular: boolean = false;

  @ContentChild('content', { static: false }) contentTemplate?: TemplateRef<unknown>;

  @Output() pageChange: EventEmitter<number> = new EventEmitter();

  onPageChange(pageChangeEvent: PageChange): void {
    this.pageChange.emit(pageChangeEvent.page);
  }
}
