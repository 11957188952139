import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-info-banner',
  templateUrl: './lib-info-banner.component.html',
  styleUrls: ['./lib-info-banner.component.scss'],
})
export class LibInfoBannerComponent {
  @Input() message?: string;
  @Input() type: 'warning' | 'info' | 'error' | 'warning-transparent' = 'info';
  @Input() isHtmlText = false;
  @Input() cta: string = '';

  @Output() callToAction = new EventEmitter<void>();
}
