import { Observable } from 'rxjs';

import {
  ApiResponse,
  ApplicationApiDefinition,
  CodeCheck,
  ConditionalTrigger,
  ConditionalTriggerUpsertPayload,
  FiscalYear,
  FieldInformationRequest,
  IndicatorRelatedMetrics,
  Metric,
  MetricLoaderFlags,
  MetricTableCalculationDefinition,
  MetricTableDefinition,
  MetricTableDefinitionUpsertPayload,
  MinimalDataRequest,
  SearchOptions,
  ValueDefinition,
  CreateMetricTableColumnDefinitionPayload,
  MetricTableColumnDefinition,
  UpdateMetricTableColumnDefinitionPayload,
  MetricTableDefinitionUpdatePayload,
  RelatedFieldType,
  RelatedField,
  Taxonomy,
  AddFieldRelationsRequest,
  AssociateTaxonomiesRequest,
  ValueDefinitionFrequency,
  ValueDefinitionFrequencyStructure,
  BulkUpdateValueDefinitionFrequencyRequest,
  MetricFieldVisibilityPerFiscalYear,
  MetricVisibleFields,
} from '../models';

export abstract class BaseApiService {
  abstract apiName: keyof ApplicationApiDefinition;
  abstract resource: string;
  abstract servicePath: string;
}
export abstract class MetricApiService extends BaseApiService {
  abstract getMetricDefinitions(metric: string): Observable<ApiResponse<ValueDefinition[]>>;

  abstract listMetrics(metricIds: string[]): Observable<ApiResponse<Metric[]>>;

  abstract listMetricDataRequests(metricId: string): Observable<ApiResponse<MinimalDataRequest[]>>;

  abstract search(searchOptions: SearchOptions): Observable<ApiResponse<Metric[]>>;

  abstract createMetric(payload: any): Observable<ApiResponse<Metric>>;

  abstract checkIfMetricCodeExists(
    metric_code: string,
    metric_code_prefix?: string,
  ): Observable<ApiResponse<CodeCheck>>;

  abstract getMetric(id: string, params?: MetricLoaderFlags): Observable<ApiResponse<Metric>>;

  abstract updateMetric(metric_id: string, payload: any): Observable<ApiResponse<Metric>>;

  abstract createGroup(metricID: string, payload?: any): Observable<ApiResponse<Metric>>;

  abstract updateGroup(metricId: string, valueDefinitionGroupID: string, payload: any): Observable<ApiResponse<Metric>>;

  abstract deleteGroup(metricID: string, valueDefinitionGroupId: string): Observable<ApiResponse<Metric>>;

  abstract moveGroup(
    metricID: string,
    valueDefinitionGroupID: string,
    position: number,
  ): Observable<ApiResponse<Metric>>;

  abstract createField(metricId: string, valueDefinitionGroupId: string, payload: any): Observable<ApiResponse<Metric>>;

  abstract updateField(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    payload: any,
  ): Observable<ApiResponse<Metric>>;

  abstract deleteField(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    forceDelete?: boolean,
  ): Observable<ApiResponse<Metric>>;

  abstract moveField(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    position: number,
  ): Observable<ApiResponse<Metric>>;

  abstract moveFieldFromGroup(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    position: number,
    targetValueDefinitionGroupId: string,
  ): Observable<ApiResponse<Metric>>;

  abstract getMetricTable(
    metricID: string,
    tableId: string,
    checkValues?: boolean,
    loadLeafTaxonomies?: boolean,
  ): Observable<ApiResponse<MetricTableDefinition>>;

  abstract listMetricTables(metricId: string): Observable<ApiResponse<MetricTableDefinition[]>>;

  abstract createMetricTable(
    metricID: string,
    payload: MetricTableDefinitionUpsertPayload,
  ): Observable<ApiResponse<Metric>>;

  abstract updateMetricTable(
    metricID: string,
    tableId: string,
    payload: MetricTableDefinitionUpdatePayload,
  ): Observable<ApiResponse<Metric>>;

  abstract deleteMetricTable(metricID: string, tableId: string): Observable<ApiResponse<Metric>>;

  abstract createMetricTableColumnDefinition(
    metricId: string,
    tableId: string,
    payload: CreateMetricTableColumnDefinitionPayload,
    forceDelete?: boolean,
    callback?: () => void,
  ): Observable<ApiResponse<MetricTableColumnDefinition>>;

  abstract deleteMetricTableColumnDefinition(
    metricId: string,
    tableId: string,
    columnId: string,
    forceDelete?: boolean,
  ): Observable<ApiResponse<MetricTableColumnDefinition>>;

  abstract deleteMetricTableColumnOption(
    metricId: string,
    tableId: string,
    columnId: string,
    optionId: string,
  ): Observable<undefined>;

  abstract updateMetricTableColumnDefinition(
    metricId: string,
    tableId: string,
    columnId: string,
    payload: UpdateMetricTableColumnDefinitionPayload,
    callback?: () => void,
  ): Observable<ApiResponse<MetricTableColumnDefinition>>;

  abstract moveMetricTableColumnDefinition(
    metricId: string,
    tableId: string,
    columnId: string,
    position: number,
  ): Observable<ApiResponse<MetricTableColumnDefinition>>;

  abstract createMetricTableTotal(
    metricId: string,
    tableId: string,
    payload: MetricTableCalculationDefinition,
  ): Observable<ApiResponse<Metric>>;

  abstract updateMetricTableTotal(
    metricId: string,
    tableId: string,
    payload: MetricTableCalculationDefinition,
  ): Observable<ApiResponse<Metric>>;

  abstract deleteMetricTableTotal(metricId: string, tableId: string, totalId: string): Observable<ApiResponse<Metric>>;

  abstract deactivateMetricTableTotal(
    metricId: string,
    tableId: string,
    totalId: string,
  ): Observable<ApiResponse<Metric>>;

  abstract activateMetricTableTotal(
    metricId: string,
    tableId: string,
    totalId: string,
  ): Observable<ApiResponse<Metric>>;

  abstract moveMetricTableTotal(
    metricId: string,
    tableId: string,
    totalId: string,
    position: number,
  ): Observable<ApiResponse<Metric>>;

  abstract moveMetricTable(metricId: string, tableId: string, position: number): Observable<ApiResponse<Metric>>;

  abstract getConditionalTriggersForValueDefinition(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
  ): Observable<ApiResponse<ConditionalTrigger[]>>;

  abstract createConditionalTrigger(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    payload: ConditionalTriggerUpsertPayload,
  ): Observable<ApiResponse<Metric>>;

  abstract updateConditionalTrigger(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    conditionTriggerId: string,
    payload: ConditionalTriggerUpsertPayload,
  ): Observable<ApiResponse<Metric>>;

  abstract deleteConditionalTrigger(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    conditionTriggerId: string,
  ): Observable<ApiResponse<Metric>>;

  abstract getRelatedMetrics(metricId: string): Observable<ApiResponse<IndicatorRelatedMetrics>>;

  abstract getTaxonomies(
    metricId: string,
    completeFrameworks?: boolean,
    order_by?: string,
    order_by_direction?: 'asc' | 'desc',
  ): Observable<ApiResponse<Taxonomy[]>>;

  abstract updateMetricTableInformation(
    metricId: string,
    tableId: string,
    payload: FieldInformationRequest,
  ): Observable<ApiResponse<Metric>>;

  abstract getLatestFiscalYearWithCollectValues(
    metricId: string,
    valueDefinitionGroupIds: string[],
    valueDefinitionIds: string[],
    metricTableIds?: string[],
  ): Observable<ApiResponse<FiscalYear | null>>;

  abstract deactivateValueDefinition(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
  ): Observable<ApiResponse<Metric>>;

  abstract deactivateValueDefinitionGroup(
    metricId: string,
    valueDefinitionGroupId: string,
  ): Observable<ApiResponse<Metric>>;

  abstract activateValueDefinitionGroup(
    metricId: string,
    valueDefinitionGroupId: string,
  ): Observable<ApiResponse<Metric>>;

  abstract deactivateMetricTable(metricId: string, metricTableId: string): Observable<ApiResponse<Metric>>;

  abstract activateValueDefinition(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
  ): Observable<ApiResponse<Metric>>;

  abstract activateMetricTable(metricId: string, metricTable: string): Observable<ApiResponse<Metric>>;

  abstract publishMetric(metricId: string): Observable<ApiResponse<Metric>>;

  abstract publishField(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
  ): Observable<ApiResponse<Metric>>;

  abstract publishMetricTable(metricId: string, metricTableDefinitionId: string): Observable<ApiResponse<Metric>>;

  abstract updateFieldInformation(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    payload: FieldInformationRequest,
  ): Observable<ApiResponse<Metric>>;

  abstract addFieldRelatedFields(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    payload: AddFieldRelationsRequest,
  ): Observable<ApiResponse<RelatedField[]>>;

  abstract addMetricTableRelatedFields(
    metricId: string,
    metricTableDefinitionId: string,
    payload: AddFieldRelationsRequest,
  ): Observable<ApiResponse<RelatedField[]>>;

  abstract deleteFieldRelatedFields(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    relatedFieldId: string,
  ): Observable<undefined>;

  abstract deleteMetricTableRelatedFields(
    metricId: string,
    metricTableDefinitionId: string,
    relatedFieldId: string,
  ): Observable<undefined>;

  abstract getFieldRelatedFields(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    relatedFieldType?: RelatedFieldType,
  ): Observable<ApiResponse<RelatedField[]>>;

  abstract getMetricTableRelatedFields(
    metricId: string,
    metricTableDefinitionId: string,
    relatedFieldType?: RelatedFieldType,
  ): Observable<ApiResponse<RelatedField[]>>;

  abstract getFieldTaxonomies(
    metricId: string,
    vdgId: string,
    vdId: string,
    completeFrameworks?: boolean,
    frequencyCode?: string,
    order_by?: string,
    order_by_direction?: 'asc' | 'desc',
  ): Observable<ApiResponse<Taxonomy[]>>;

  abstract getMetricTableTaxonomies(
    metricId: string,
    metricTableDefinitionId: string,
    completeFrameworks?: boolean,
    frequencyCode?: string,
    order_by?: string,
    order_by_direction?: 'asc' | 'desc',
  ): Observable<ApiResponse<Taxonomy[]>>;

  abstract addTaxonomies(metricId: string, payload: AssociateTaxonomiesRequest): Observable<ApiResponse<Taxonomy[]>>;

  abstract addFieldTaxonomies(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    payload: AssociateTaxonomiesRequest,
  ): Observable<ApiResponse<Taxonomy[]>>;

  abstract addMetricTableTaxonomies(
    metricId: string,
    metricTableDefinitionId: string,
    payload: AssociateTaxonomiesRequest,
  ): Observable<ApiResponse<Taxonomy[]>>;

  abstract deleteTaxonomy(metricId: string, taxonomyId: string): Observable<undefined>;

  abstract deleteFieldTaxonomy(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    taxonomyId: string,
  ): Observable<undefined>;

  abstract deleteMetricTableTaxonomy(
    metricId: string,
    metricTableDefinitionId: string,
    taxonomyId: string,
  ): Observable<undefined>;

  abstract listValueDefinitionFrequencies(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
  ): Observable<ApiResponse<ValueDefinitionFrequencyStructure[]>>;

  abstract bulkUpdateValueDefinitionFrequencies(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    bulkUpdateRequest: BulkUpdateValueDefinitionFrequencyRequest,
  ): Observable<ApiResponse<ValueDefinitionFrequency[]>>;

  abstract getMetricFieldVisibility(metricId: string): Observable<ApiResponse<MetricFieldVisibilityPerFiscalYear[]>>;

  abstract getMetricVisibleFields(metricId: string): Observable<ApiResponse<MetricVisibleFields>>;
}
