@for (item of items; track $index; let first = $first) {
  <div class="menu-item">
    <ng-container
      *ngTemplateOutlet="item.isSectionTitle ? itemTitle : itemElement; context: { item: item, first: first }"
    ></ng-container>
  </div>
}

<ng-template #itemTitle let-item="item" let-first="first">
  <h4
    class="menu-item--title mat-subtitle-2"
    [ngClass]="first ? 'menu-item--title--first' : 'menu-item--title--not-first'"
  >
    {{ item.title }}
  </h4>
</ng-template>

<ng-template #itemElement let-item="item">
  <button
    matRipple
    class="menu-item--element mat-typography light-bottom-right-shadow"
    (click)="onItemClick(item)"
    [attr.aria-label]="item.title"
    [attr.data-testid]="item.id"
  >
    <mat-icon class="menu-item--element--icon" [svgIcon]="item.icon"></mat-icon>
    {{ item.title }}
  </button>
</ng-template>
