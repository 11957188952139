<div
  data-testid="drop-area"
  class="formfield"
  [ngClass]="{
    error: valueFormControl.errors?.length,
    disabled: valueFormControl.disabled,
  }"
  (drop)="dropFile($event)"
  (dragover)="preventEventDefault($event)"
>
  <lib-form-field-label [label]="getLabel()" [control]="valueFormControl"></lib-form-field-label>
  <a
    class="card card-dashed align-center p-0 justify-center"
    tabindex="1"
    data-testid="fileCard"
    #focusElement
    [ngClass]="{ disabled: fileDocumentsList.length === maxFilesLimit }"
  >
    @if (!uploading) {
      <div class="mat-body-2 color-grey-800">
        {{ "Drag files to import, or" | translate }}
        @if (!customBrowseDialog) {
          <span>
            <button lib-button buttonType="browse">
              {{ "browse" | translate }}<input type="file" (change)="selectFileFromBrowse($event)" /></button
          ></span>
        }
        @if (customBrowseDialog) {
          <span>
            <button lib-button buttonType="link" (click)="launchCustomBrowseDialog()">
              {{ "browse" | translate }}
            </button></span
          >
        }
      </div>
    }
    @if (uploading) {
      <div class="fx-row align-center">
        <div class="mr-4 mat-body-2 color-grey-800">{{ "Uploading file. Please wait..." | translate }}</div>
        <div class="spinner">
          <lib-spinner ariaLabel="upload-spinner" [diameter]="20"></lib-spinner>
        </div>
      </div>
    }
  </a>
  @if (valueFormControl.untouched || valueFormControl.valid) {
    <mat-hint class="fx-row justify-space-between">
      <div>{{ hint }}</div>
      <div>{{ "150MB" | translate }}</div>
    </mat-hint>
  }
  @if (valueFormControl.touched && valueFormControl.invalid) {
    @for (error of valueFormControl.errors | errorKeys; track error) {
      <mat-error> {{ messages?.[error] }}</mat-error>
    }
  }
</div>

<div class="card-dummies-container" [ngClass]="{ loaded: filesLoaded, 'mt-2': fileDocumentsList.length }">
  <div class="h-100 card-item-dummies">
    @for (i of [1, 2]; track i) {
      <div data-testid="loadingDummies" class="card card-link w-25 bkg-blink">&nbsp;</div>
    }
  </div>
  @for (doc of fileDocumentsList; track doc) {
    <a
      class="card card-link w-25 file"
      [ngClass]="{ 'card-downloading': isDocumentDownloading(doc.id) }"
      [attr.data-testid]="'file-card-' + doc.id"
    >
      @if (!isDocumentDownloading(doc.id)) {
        <div class="fx-row justify-space-between">
          <div>
            <mat-icon class="icon-sm mr-2 color-primary-600" svgIcon="link"></mat-icon>
            <button lib-button [attr.data-testid]="'fileLink-' + doc.id" buttonType="link" (click)="downloadDoc(doc)">
              {{ doc?.name }}.{{ doc?.format }}
            </button>
          </div>
          @if (valueFormControl.enabled) {
            <a role="button" [attr.aria-label]="'deleteIcon-' + doc.id" (click)="deleteDoc(doc)">
              <mat-icon class="icon-sm" svgIcon="trash"></mat-icon
            ></a>
          }
        </div>
      }
      @if (isDocumentDownloading(doc.id)) {
        <div class="fx-row justify-space-between white-spinner">
          <span class="color-white">{{ doc?.name }}.{{ doc?.format }}</span>
          <lib-spinner [ariaLabel]="'download-spinner-' + doc.id" [diameter]="15"></lib-spinner>
        </div>
      }
    </a>
  }
</div>
