@if (showExpandAllButton) {
  <div class="expand-all-control">
    <button
      lib-button
      buttonType="link"
      data-testid="expand-all-button"
      (click)="expandOrCollapseAll()"
      [label]="expandAllNodes ? ('Collapse All' | translate) : ('Expand All' | translate)"
    >
      &nbsp;
      <span class="expand-icon" [ngClass]="{ expanded: expandAllNodes }">
        <mat-icon svgIcon="arrow-head-down"></mat-icon>
      </span>
    </button>
  </div>
}
<p-treeTable
  [value]="dataSource"
  [columns]="columns"
  [scrollable]="true"
  [(selection)]="selectedNodes"
  (onNodeSelect)="nodeSelect($event)"
  (onNodeUnselect)="nodeUnselect($event)"
  (onNodeExpand)="nodeExpand($event)"
  (onNodeCollapse)="nodeCollapse($event)"
  [styleClass]="withGridLines ? 'p-treetable-gridlines' : ''"
  [selectionMode]="selectionMode?.toString()"
  data-testid="treeTable"
>
  @if (showHeader; as columns) {
    <ng-template pTemplate="header" let-columns>
      <tr>
        @for (col of columns; track col) {
          <th>
            {{ col.name }}
          </th>
        }
      </tr>
    </ng-template>
  }

  <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
    <tr [ttRow]="rowNode" [ttSelectableRow]="selectable" (click)="rowClick(rowData)">
      @for (col of columns; track col; let i = $index) {
        <td
          [ngClass]="{
            'disabled-selection': rowData.disabled,
            'selected-node': (selectedNodes | isNodeSelected: rowData) && !rowData.disabled && rowData?.is_selectable,
          }"
        >
          @if (i === 0) {
            <p-treeTableToggler
              [attr.data-testid]="'expand-toggler-' + rowData[col.dataKey]"
              [rowNode]="rowNode"
            ></p-treeTableToggler>
          }
          @if (selectable && (allNodesSelectable || rowData?.is_selectable) && i === 0) {
            <p-treeTableCheckbox
              [attr.data-testid]="'selection-checkbox-' + rowData[col.dataKey]"
              [value]="rowNode"
              [disabled]="rowData.disabled"
            ></p-treeTableCheckbox>
          }
          <ng-template #defaultCell>
            {{ rowData | get: col.dataKey }}
          </ng-template>
          <ng-container
            *ngTemplateOutlet="
              col?.cellTemplate || defaultCell;
              context: { $implicit: rowData | get: col.dataKey, row: rowData }
            "
          ></ng-container>
        </td>
      }
    </tr>
  </ng-template>
</p-treeTable>
