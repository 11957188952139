<mat-stepper (selectionChange)="selectionChange($event)" [selectedIndex]="selectedIndex">
  @for (step of steps; track step) {
    @if (step) {
      <mat-step [completed]="false" [label]="step.title ?? ''" [aria-labelledby]="step.disabled ? 'disabled_af' : ''">
        @if (step.customLabel) {
          <ng-template matStepLabel>
            <ng-container *ngTemplateOutlet="step.customLabel"></ng-container>
          </ng-template>
        }
        @if (step.lazyLoading) {
          <ng-template matStepContent>
            <ng-container *ngTemplateOutlet="step.content"></ng-container>
          </ng-template>
        } @else {
          <ng-container *ngTemplateOutlet="step.content"></ng-container>
        }
      </mat-step>
    }
  }
</mat-stepper>
