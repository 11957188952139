@if (shouldDisplayActions()) {
  <div mat-dialog-actions>
    <a
      role="button"
      class="btn btn-success"
      [ngClass]="{ 'disabled btn-disabled': disable }"
      (click)="confirmEvent.emit()"
    >
      {{ buttonText }}
    </a>
  </div>
}
