<button mat-menu-item class="menu-title" disabled>{{ menuTitle }}</button>
@if (initialItems) {
  @if (control && initialItems.length > minMenuScrollItems) {
    <div class="menu-search" (click)="$event.stopPropagation()">
      <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
      <input
        matInput
        type="text"
        [placeholder]="'Search' | translate"
        autocomplete="off"
        (keydown)="$event.stopPropagation()"
        [formControl]="control"
        data-testid="search-input"
      />
      @if (control.value) {
        <a class="clear-search" (click)="control.setValue('')">
          <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
        </a>
      }
    </div>
  }
  @if (allOptionItem) {
    <hr />
    <button mat-menu-item (click)="setItem.emit(allOptionItem)">{{ "All" | translate }}</button>
  }
  @if (filteredItems; as items) {
    <div [ngClass]="{ 'menu-items-container': items.length > minMenuScrollItems }">
      @if (categories?.length) {
        @for (categorie of categories; track $index) {
          <div>
            <hr class="hr-light" />
            <button mat-menu-item class="menu-title" disabled>
              {{ categorie | translate }}
            </button>
            @for (item of items | filter: filterItemsFunction : categorie; track $index) {
              <button
                mat-menu-item
                [title]="item.title"
                (click)="setItem.emit(item)"
                [ngClass]="{ active: selectedItemId === item.id }"
                [attr.aria-label]="item.id"
                [class]="item.levelClass ?? ''"
              >
                <span class="item-text">{{ item.title }}</span>
              </button>
            }
          </div>
        }
      } @else {
        <div>
          <hr class="hr-light" />
          @for (item of items; track $index) {
            <button
              mat-menu-item
              [title]="item.title"
              (click)="setItem.emit(item)"
              [ngClass]="{ active: selectedItemId === item.id }"
              [attr.aria-label]="item.id"
              [class]="item.levelClass ?? ''"
            >
              <span class="item-text">{{ item.title }}</span>
            </button>
          }
        </div>
      }
    </div>
  }
}
