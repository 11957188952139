import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ButtonProps } from '../../search/models';
import { ActionItem } from '../../models';

@Component({
  selector: 'lib-filter-v2',
  templateUrl: './filter-v2.component.html',
  styleUrl: './filter-v2.component.scss',
})
export class FilterV2Component implements OnChanges {
  @Input() buttonProps?: ButtonProps;
  @Input() items: ActionItem[] = [];
  @Input() activeActionItem?: ActionItem;
  @Input() defaultValue?: ActionItem;

  @Output() optionSelected = new EventEmitter<ActionItem>();

  public itemsWithActiveStatus: MenuItem[] = [];
  public buttonTitle?: string = '';

  ngOnChanges(): void {
    const menuItems: MenuItem[] = this.items.map((actionItem) => this.convertActionItemToMenuItem(actionItem));

    this.itemsWithActiveStatus = this.setActiveMenuItems(menuItems, this.activeActionItem?.id);

    this.buttonTitle =
      this.defaultValue?.id === this.activeActionItem?.id ? this.buttonProps?.title : this.activeActionItem?.title;
  }

  private convertActionItemToMenuItem(actionItem: ActionItem): MenuItem {
    if (actionItem.isSeparator) {
      return { separator: true };
    }

    return {
      id: actionItem.id,
      items: actionItem.children?.map((actionItem: ActionItem) => this.convertActionItemToMenuItem(actionItem)),
      label: actionItem.title,
      icon: actionItem.icon,
      command: () => this.optionSelected.emit(actionItem),
    };
  }

  private setActiveMenuItems(menuItems: MenuItem[], activeId?: string): MenuItem[] {
    const { updatedItems } = this.updateItems(menuItems, activeId);
    return updatedItems;
  }

  private updateItems(items: MenuItem[], activeId?: string): { updatedItems: MenuItem[]; isActive: boolean } {
    let isAnyChildActive = false;

    const updatedItems = items.map((item) => {
      const { updatedItems: childItems, isActive: isChildActive } = item.items
        ? this.updateItems(item.items, activeId)
        : { updatedItems: undefined, isActive: false };

      const isActive = item.id === activeId || isChildActive;

      isAnyChildActive ||= isActive;

      return {
        ...item,
        items: childItems,
        active: isActive,
      };
    });

    return { updatedItems, isActive: isAnyChildActive };
  }
}
