import { Icon } from '../../../icons/icons.model';
import { ActionItem } from '../../ui';
import { CascadeProps } from '../../../search';

export { FilterBarOption, FilterBarSelection, FilterType };

interface FilterBarOption {
  id: string;
  title: string;
  icon?: Icon;
  options: ActionItem[];
  optionType: FilterType;
  displayAll: boolean;
  defaultValue?: string;
  selectedValue?: string;
  multiSelect?: boolean;
  multiSelectDefaultValues?: string[];
  cascadeSelect?: boolean;
  cascadeProps?: CascadeProps;
  prefix?: string;
  secondaryHeaders?: ActionItem[];
}

interface FilterBarSelection {
  id: string;
  selection: ActionItem[];
  isPrimary?: boolean;
}

enum FilterType {
  list,
  list_v2,
  searchList,
}
