<div class="tip">
  @if (iconInfo.icon) {
    <div class="fx-row align-center mb-0">
      <mat-icon [svgIcon]="iconInfo.icon" class="tip-icon color-{{ iconInfo.color }} mr-2"></mat-icon>
    </div>
  }
  <div
    class="text tip-text tip-text-{{ iconInfo.color }} mat-body pl-3"
    [innerHTML]="value?.type_details?.value | sanitizeHtml"
  ></div>
</div>
