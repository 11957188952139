@if (control) {
  <div class="formfield" data-testid="textAreaFormField" [ngClass]="{ required: required, disabled: control.disabled }">
    <lib-form-field-label [label]="label" [control]="control" [for]="_inputId"></lib-form-field-label>
    <mat-form-field class="w-100 superscript-hint" floatLabel="auto">
      @if (prefix) {
        <span class="color-grey-800 mr-1" matPrefix>{{ prefix }}</span>
      }
      <textarea
        [rows]="rows"
        matInput
        [formControl]="control"
        class="color-grey-900"
        [cdkTextareaAutosize]="cdkTextareaAutosize"
        [cdkAutosizeMinRows]="rows"
        [cdkAutosizeMaxRows]="cdkAutosizeMaxRows"
        [id]="_inputId"
        [libTrimWhitespace]="trimWhitespaceMethod"
        [placeholder]="placeholder"
        [attr.aria-label]="_inputId"
        (focus)="onFocus()"
        (blur)="onBlur()"
        (keyup)="updateValue($event)"
        #focusElement
        libFocusState
        libStripNonPrintableCharacters
      ></textarea>
      @if (hint || maxLength) {
        <mat-hint class="fx-row justify-space-between w-100">
          @if (hint) {
            <span>{{ hint }}</span>
          }
          @if (maxLength) {
            <span> {{ control.value?.length ?? 0 }}/{{ maxLength }}</span>
          }
        </mat-hint>
      }
      @if (control.status === "PENDING") {
        <lib-spinner [diameter]="16" matSuffix></lib-spinner>
      }
      @for (error of control.errors | errorKeys; track error) {
        <mat-error>
          {{ errorMessages[error] }}
          {{
            error === "maxlength" && maxLength
              ? ("({length}/{maxLength})" | translate: { length: control.value?.length ?? 0, maxLength })
              : ""
          }}
        </mat-error>
      }
    </mat-form-field>
  </div>
}
