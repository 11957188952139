<mat-menu #menu="matMenu">
  <ng-template matMenuContent>
    @if (optionProps?.title === "Source" || optionProps?.title === "Parent") {
      @if (sourceConfiguration) {
        <lib-source-menu-filter
          [initialSources]="optionProps?.options ?? []"
          [selectedSource]="optionProps?.activeOption"
          [sourceConfiguration]="sourceConfiguration"
          [allSourcesItem]="allSourcesItem"
          (setItem)="optionSelected.emit($event)"
        >
        </lib-source-menu-filter>
      }
    } @else {
      <header>
        <a mat-menu-item disabled>{{ optionProps?.title }}</a>
      </header>
      @if (optionProps && optionProps.defaultValue) {
        <hr />
        <a mat-menu-item (click)="optionSelected.emit(optionProps.defaultValue)">
          {{ getDefaultValueTitle() }}
        </a>
      }
      <hr />
      @if (optionProps?.id === sortResourceType) {
        @for (option of optionProps?.options; track option) {
          <a
            mat-menu-item
            (click)="optionSelected.emit(option)"
            [ngClass]="{
              active: optionProps?.activeOption?.id === option.id,
            }"
          >
            {{ option.title }}
          </a>
        }
      }
      @if (optionProps?.id !== sortResourceType) {
        <div class="options-list">
          @for (option of optionProps?.options; track option) {
            <a
              mat-menu-item
              (click)="optionSelected.emit(option)"
              [ngClass]="{
                active: optionProps?.activeOption?.id === option.id,
              }"
            >
              {{ option.title }}
            </a>
          }
        </div>
      }
    }
  </ng-template>
</mat-menu>

<lib-filter-button
  [buttonProps]="buttonProps"
  [optionProps]="optionProps"
  [matMenuTriggerFor]="buttonProps?.disabled ? null : menu"
>
</lib-filter-button>
