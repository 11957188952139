<div class="caption3 color-grey-800 mt-1 mb-4">{{ "Displayed values" | translate }}</div>
@if (displayedValuesLoading$ | async) {
  <lib-dummy [presentation]="ePresentation.card" data-testid="dummy-matTable"></lib-dummy>
}
@if (displayedValues$ | async; as displayedValues) {
  @if ((displayedValuesLoading$ | async) === false) {
    @if (showAllDisplayedValues$ | async) {
      <li>{{ "All values" | translate }}</li>
    }
    @if ((showAllDisplayedValues$ | async) === false) {
      @for (dv of displayedValues; track dv) {
        <div>
          @if (
            ![eValueDefinitionDisplayType.subtitle, eValueDefinitionDisplayType.tip].includes(dv.type) && dv.is_checked
          ) {
            <li [ngClass]="{ 'color-error': dv.overridden_label }">
              {{ dv.overridden_label || dv.label }}
            </li>
          }
        </div>
      }
    }
  }
}
<div class="mt-6">
  <div class="medium">
    <a class="btn btn-stroked" (click)="launchEditValuesDialog()">
      <mat-icon class="icon-md mr-1" svgIcon="show"></mat-icon><span>{{ "Edit displayed values" | translate }}</span>
    </a>
  </div>
</div>

<lib-divider class="mt-4"></lib-divider>
