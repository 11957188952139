@if (control) {
  <div
    libFormField
    [size]="size"
    [required]="required"
    [control]="control"
    [readonly]="readonly"
    [removePadding]="removePadding"
    [labelPosition]="labelPosition"
    [dataTestId]="'text-input'"
    [title]="readonly ? control.value : ''"
  >
    <lib-form-field-label [label]="label" [control]="control" [for]="_inputId"></lib-form-field-label>
    <mat-form-field appearance="fill" [subscriptSizing]="subscriptSizing">
      @if (prefix) {
        <span class="color-grey-800 mr-1" matPrefix>{{ prefix }}</span>
      }
      <input
        #input
        matInput
        [type]="type"
        [placeholder]="placeholder"
        [formControl]="control"
        [id]="_inputId"
        [name]="_inputId"
        [attr.aria-label]="_inputId"
        #focusElement
        libFocusState
        libStripNonPrintableCharacters
        [libTrimWhitespace]="trimWhitespaceMethod"
        [readonly]="readonly"
        [libAutoFocusInput]="autofocus"
        data-testid="text-input-input"
        (blur)="handleBlur.emit()"
        [autocomplete]="autocomplete"
      />
      @if (suffix) {
        <span matSuffix data-testid="inputSuffix">{{ suffix }}</span>
      }
      @if (clearText && control.value) {
        <a data-testid="clear-text" matSuffix (click)="control.setValue('')">
          <mat-icon class="icon-sm" svgIcon="close"> </mat-icon>
        </a>
      }
      @if (togglePassword && control.status !== "PENDING") {
        <a data-testid="toggle-password" matSuffix (click)="togglePwdType()" class="mat-icon-formfield">
          @if (type === "password") {
            <mat-icon svgIcon="show"></mat-icon>
          }
          @if (type === "text") {
            <mat-icon svgIcon="hide"></mat-icon>
          }
        </a>
      }
      @if (hint || maxCharacterCount) {
        <mat-hint class="fx-row justify-space-between align-baseline">
          @if (hint) {
            <span>{{ hint }}</span>
          }
          @if (maxCharacterCount) {
            <span> {{ control.value?.length ?? 0 }}/{{ maxCharacterCount }}</span>
          }
        </mat-hint>
      }
      @if (control.status === "PENDING") {
        <lib-spinner class="pv-0" [diameter]="16" matSuffix></lib-spinner>
      }
      @for (error of control.errors | errorKeys; track error) {
        <mat-error>
          {{ errorMessages[error] }}
          {{
            error === "maxlength" && maxCharacterCount
              ? ("({length}/{maxLength})"
                | translate: { length: control.value?.length ?? 0, maxLength: maxCharacterCount })
              : ""
          }}
        </mat-error>
      }
    </mat-form-field>
  </div>
}
