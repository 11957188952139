<div class="grid grid-2 grid-auto-rows">
  <div class="color-grey-800">{{ "Code" | translate }}</div>
  <div class="color-grey-900">{{ metric.code }}</div>
  @if (metric.category === eMetricCategory.THIRD_PARTY) {
    <div class="color-grey-800">{{ "Name" | translate }}</div>
    <div class="color-grey-900">{{ metric.description }}</div>
  }
  <div class="color-grey-800">{{ "Topics" | translate }}</div>
  <div class="color-grey-900">
    @for (topic of metric.topics; track topic; let isLast = $last) {
      <span>{{ topic.name }}{{ isLast ? "" : ", " }}</span>
    }
  </div>
  @if (!refMetricsV2EnabledFF) {
    <div class="color-grey-800">{{ "Related to" | translate }}</div>
  }
  @if (!refMetricsV2EnabledFF) {
    <div class="color-grey-900">
      @for (related_metric of metric.related_metrics | filter: filterRelatedMetrics; track related_metric) {
        <a
          class="btn btn-xs mr-1 mb-1"
          [ngClass]="{
            'btn-primary': related_metric.source === eRelatedMetricSource.platform,
            'btn-stroked': related_metric.source === eRelatedMetricSource.shared,
            'btn-primary btn-disabled': !related_metric.source || related_metric.source === eRelatedMetricSource.core,
          }"
        >
          {{ related_metric.equivalent_metric.code }}
        </a>
      }
    </div>
  }
  @if (!refMetricsV2EnabledFF) {
    <div class="color-grey-800">{{ "Compatible with" | translate }}</div>
  }
  @if (!refMetricsV2EnabledFF) {
    <div class="color-grey-900">
      @for (code of metric.standard_codes; track code) {
        <a class="btn btn-xs btn-stroked btn-disabled mr-1 mb-1 enable-pointer-events">
          <span libEllipsifyMe [maxCharCount]="35">{{ code.code }}</span>
        </a>
      }
    </div>
  }
</div>
