@if (message) {
  <div [ngClass]="type + '-banner'">
    <div class="icon-container">
      <mat-icon [attr.aria-label]="type + '-icon'" [svgIcon]="type" class="icon-lg"></mat-icon>
    </div>
    <div [ngClass]="type + '-separator'"></div>
    <div class="banner-text">
      <div>
        @if (!isHtmlText) {
          {{ message }}
        } @else {
          <div [innerHTML]="message"></div>
        }
        @if (cta) {
          <button lib-button class="p-0" [buttonType]="'link'" (click)="callToAction.emit()">{{ cta }}</button>
        }
      </div>
    </div>
  </div>
}
