@if (!(valueDefinitionGroup | isEntityFromCoreRefV2Metric: metric)) {
  <div
    #group
    cdkDropList
    [cdkDropListData]="valueDefinitionGroup.value_definitions"
    (cdkDropListDropped)="drop($event)"
    [attr.data-testid]="valueDefinitionGroup.id"
  >
    <lib-metric-structure-group-form-details
      [valueDefinitionGroup]="valueDefinitionGroup"
      [index]="index"
      [canMoveDown]="canMoveDown"
      [canMoveUp]="canMoveUp"
      [metric]="metric"
      [selectedItemId]="selectedItemId"
      [isCoreValueDefinitionGroupOfReferenceV2Metric]="valueDefinitionGroup | isEntityFromCoreRefV2Metric: metric"
      [indexOffSet]="indexOffSet"
    ></lib-metric-structure-group-form-details>
  </div>
} @else {
  <div [attr.data-testid]="'core-vdg-' + valueDefinitionGroup.id">
    <lib-metric-structure-group-form-details
      [valueDefinitionGroup]="valueDefinitionGroup"
      [index]="index"
      [canMoveDown]="canMoveDown"
      [canMoveUp]="canMoveUp"
      [metric]="metric"
      [selectedItemId]="selectedItemId"
      [isCoreValueDefinitionGroupOfReferenceV2Metric]="valueDefinitionGroup | isEntityFromCoreRefV2Metric: metric"
    ></lib-metric-structure-group-form-details>
  </div>
}
