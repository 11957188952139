<div class="panel-search" [ngClass]="{ 'item-selected': isItemSelected }">
  <div class="data-table-side">
    @if (tableDataSource.data.length > 0) {
      @if (isFilterable && rowActionIcon) {
        <ng-container [matColumnDef]="rowActionIcon">
          <mat-form-field>
            <mat-label>{{ "Filter" | translate }}</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="{{ 'filter' | translate }}" />
          </mat-form-field>
        </ng-container>
      }
      @if (canDisplay) {
        <table
          mat-table
          [dataSource]="tableDataSource"
          matSort
          (matSortChange)="sortTable($event)"
          class="mat-elevation-z0 w-100 custom-table"
        >
          @if (rowActionIcon?.length && rowActionIcon) {
            <ng-container [matColumnDef]="rowActionIcon">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element" [id]="rowActionIcon" (click)="emitRowAction(element)">
                <button mat-button>
                  <mat-icon>{{ rowActionIcon }}</mat-icon>
                </button>
              </td>
            </ng-container>
          }
          @for (tableColumn of tableColumns; track tableColumn) {
            <ng-container [matColumnDef]="tableColumn.name">
              @if (tableColumn.isSortable) {
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  [mat-sort-header]="tableColumn.name"
                  [arrowPosition]="tableColumn.position === 'right' ? 'before' : 'after'"
                  [ngStyle]="{ width: tableColumn.width ?? 'unset' }"
                  [attr.aria-label]="tableColumn.name"
                >
                  <b class="text column-name">{{ tableColumn.name }}</b>
                </th>
              } @else {
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  [class.text-right]="tableColumn.position === 'right'"
                  class="header-cell"
                  [ngStyle]="{ width: tableColumn.width ?? 'unset' }"
                  [attr.aria-label]="tableColumn.name"
                >
                  <b class="text column-name">{{ tableColumn.name }}</b>
                </th>
              }
              <td
                mat-cell
                *matCellDef="let element"
                [class.text-right]="tableColumn.position === 'right'"
                class="table-cell"
              >
                @if (element) {
                  <div>
                    <ng-template #defaultCell>
                      {{ element[tableColumn.dataKey] }}
                    </ng-template>
                    <ng-container
                      *ngTemplateOutlet="
                        tableColumn.cellTemplate || defaultCell;
                        context: { $implicit: element[tableColumn.dataKey] }
                      "
                    ></ng-container>
                  </div>
                }
              </td>
            </ng-container>
          }
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngStyle]="{ cursor: selectRow.observers.length ? 'pointer' : 'default' }"
            class="element-row"
            (click)="emitSelectRow(row)"
            data-testid="tableRow"
          ></tr>
        </table>
      }
    } @else {
      <lib-empty-results [displayData]="emptyResult"></lib-empty-results>
    }

    @if (isPageable) {
      <mat-paginator
        class="table-paginator"
        [ngClass]="{ 'hidden-paginator': tableDataSource.data.length <= 0 }"
        [pageSizeOptions]="paginationSizes"
        [pageSize]="defaultPageSize"
        (page)="pageChanged($event)"
        showFirstLastButtons
      >
      </mat-paginator>
    }
  </div>

  <div class="panel-item">
    <router-outlet></router-outlet>
  </div>
</div>
