import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import {
  DataRequestSourceStatus,
  DataRequestStatus,
  DataRequestUserResponsibility,
  DataRequestFrequencyGroupStatus,
} from '../../models';

export {
  DataRequestsRequestStatus,
  DataRequestsSourceStatus,
  DataRequestsUserResponsibility,
  DataRequestsParticipantsResponsibilityPlural,
  DataRequestsRequestFrequencyGroupStatus,
};

const DataRequestsRequestStatus = {
  [DataRequestStatus.DRAFT]: _('Not started'),
  [DataRequestStatus.INACTIVE]: _('Inactive'),
  [DataRequestStatus.ACTIVE]: _('In progress'),
  [DataRequestStatus.IMPORTED]: _('Imported'),
  [DataRequestStatus.ARCHIVED]: _('Archived'),
};

const DataRequestsRequestFrequencyGroupStatus = {
  [DataRequestFrequencyGroupStatus.NOT_STARTED]: _('Not Started'),
  [DataRequestFrequencyGroupStatus.IMPORTED]: _('Imported'),
  [DataRequestFrequencyGroupStatus.IN_PROGRESS]: _('In Progress'),
};

const DataRequestsSourceStatus = {
  [DataRequestSourceStatus.NOT_STARTED]: _('Not started'),
  [DataRequestSourceStatus.STARTED]: _('In contribution'),
  [DataRequestSourceStatus.IN_REVIEW]: _('In review'),
  [DataRequestSourceStatus.IN_APPROVAL]: _('In approval'),
  [DataRequestSourceStatus.IMPORTED]: _('Imported'),
  [DataRequestSourceStatus.IMPORTING]: _('Importing'),
  [DataRequestSourceStatus.PRE_FILLING]: _('Pre-filling'),
  [DataRequestSourceStatus.ACTIVATING]: _('Activating'),
};

const DataRequestsUserResponsibility = {
  [DataRequestUserResponsibility.CONTRIBUTOR]: _('Contributor'),
  [DataRequestUserResponsibility.REVIEWER]: _('Reviewer'),
  [DataRequestUserResponsibility.APPROVER]: _('Approver'),
  [DataRequestUserResponsibility.COMMENTOR]: _('Commentor'),
};

const DataRequestsParticipantsResponsibilityPlural = {
  [DataRequestUserResponsibility.CONTRIBUTOR]: _('Contributors'),
  [DataRequestUserResponsibility.REVIEWER]: _('Reviewers'),
  [DataRequestUserResponsibility.APPROVER]: _('Approvers'),
  [DataRequestUserResponsibility.COMMENTOR]: _('Commentors'),
};
