@if (control || uncontrolled) {
  <div [class]="'formfield ' + size" [ngClass]="{ disabled: control?.disabled }">
    @if (control) {
      <mat-slide-toggle
        title="{{ label | translate }}"
        [formControl]="control"
        (change)="sliderToggle.emit($event.checked)"
        [hideIcon]="true"
        data-testid="slide-toggle"
        color="secondary"
      >
        {{ label }}
      </mat-slide-toggle>
    } @else {
      <mat-slide-toggle
        [id]="id"
        title="{{ label | translate }}"
        [aria-labelledby]="id"
        [attr.name]="id"
        [checked]="checked"
        [disabled]="disabled"
        (change)="sliderToggle.emit($event.checked)"
        [hideIcon]="true"
        data-testid="slide-toggle"
      >
        {{ label }}
      </mat-slide-toggle>
    }
    @if (hint) {
      <mat-hint class="w-100">
        <span>{{ hint }}</span>
      </mat-hint>
    }
  </div>
}
