<div class="panel panel-tools" data-testid="panelTools" [ngClass]="{ 'panel-expanded': selectedTool }">
  <div class="panel-tools-bar" data-testid="panelToolsBar" [ngClass]="{ 'hide-tools': expandedTabView }">
    <a class="close-tools" (click)="toggleSelectTool()" data-testid="openToolPanelButton">
      @if (selectedTool) {
        <mat-icon class="icon-sm" svgIcon="arrow-head-right" data-testid="rightArrowIcon"></mat-icon>
      }
      @if (!selectedTool) {
        <mat-icon class="icon-sm" svgIcon="arrow-head-left" data-testid="leftArrowIcon"></mat-icon>
      }
    </a>
    @for (tabTool of tabTools; track tabTool) {
      <a
        (click)="selectTool(tabTool)"
        [ngClass]="{ active: tabTool.id === selectedTool?.id }"
        [libViewerAction]="tabTool.viewerAction"
        [attr.data-testid]="tabTool.id"
        [matTooltip]="tabTool.title"
        matTooltipPosition="before"
      >
        <mat-icon svgIcon="{{ tabTool.icon }}" class="icon-lg"></mat-icon>
      </a>
    }
  </div>

  <div class="panel-tools-bar" data-testid="expandedPanelToolsBar" [ngClass]="{ 'hide-tools': !expandedTabView }">
    @if (expandedTabView) {
      <div class="toggle-expand-link" (click)="toggleExpandTabView()">
        <a
          class="close-tools"
          role="button"
          aria-label="close expanded view"
          [title]="'Go to {title}' | translate: { title: selectedTool?.title }"
        >
          <mat-icon class="icon-md" svgIcon="close"></mat-icon>
        </a>
      </div>
    }
  </div>

  <ng-template #defaultTemplate></ng-template>

  <ng-container
    *ngTemplateOutlet="panelToolsRef || defaultTemplate; context: { $implicit: selectedTool }"
  ></ng-container>
</div>
