import { CreatedUpdatedEntity, Framework, Industry, Section, Tag, Topic } from '../entities';
import { Report, ReportCategoryType, ReportIntegrationType } from '../templates';
import { Metric, ValueDefinition, ValueDefinitionGroup } from '../metrics';
import { DocumentCategory, DocumentType, PlaformDocumentCategory } from '../../documents';
import { TypeDetails } from '../type_details';
import { ValueDefinitionDisplayType } from '../ui';

export interface CreateTemplateReportPayload {
  category: string;
  code: string;
  name: string;
  reference_v2: boolean;
  carry_metrics_from_version_id?: string;
  frameworks?: string[];
  industries?: string[];
  integration?: string | null;
}

export interface UpdateTemplateReportPayload {
  code: string;
  name: string;
  category: string;
  integration?: string | null;
}

export interface TemplateReportVersionPayload {
  carry_metrics?: boolean;
  end: string | null;
  start: string;
  version: string;
}

export interface BaseTemplateReport extends CreatedUpdatedEntity {
  id: string;
  active?: boolean;
  sections?: TemplateReportSection[];
  start?: Date | string;
  end?: Date | string;
  name?: string;
  version?: string;
  version_number?: number;
}

export interface TemplateReportSection {
  id: string;
  code: string;
  label: string;
  position: number;
  parent_id?: string;
  children?: TemplateReportSection[];
  metrics: Metric[];
  display?: SectionMetricDisplay[];
}

export interface DisplayedValue {
  value_definition_id: string;
  label: string;
  overridden_label: string | null;
  is_checked: boolean;
  type: ValueDefinitionDisplayType;
  type_details: TypeDetails;
}

export interface CreateTemplateReportSectionPayload {
  code: string;
  label: string;
  parent_id?: string;
}

export interface ValueDefinitionMapping {
  id: string;
  template_report_mapping_id?: string;
  to_template_report_version_id?: string;
  to_metric: Metric;
  to_value_definition: ValueDefinition;
  to_value_definition_group: ValueDefinitionGroup;
  from_template_report_version_id?: string;
  from_metric: Metric;
  from_value_definition: ValueDefinition;
  from_value_definition_group: ValueDefinitionGroup;
  note: string;
  active: boolean;
}

export interface TemplateReportMapping extends CreatedUpdatedEntity {
  id: string;
  template_report_version_id: string;
  metric_id: string;
  value_definition_id: string;
  value_definition_group_id: string;
  from_template_report_version_id: string;
  from_metric_id: string;
  from_value_definition_id: string;
  from_value_definition_group_id: string;
  note: string;
}

export interface ValueDefinitionMapping {
  id: string;
  template_report_mapping_id?: string;
  to_template_report_version_id?: string;
  to_metric: Metric;
  to_value_definition: ValueDefinition;
  to_value_definition_group: ValueDefinitionGroup;
  from_template_report_version_id?: string;
  from_metric: Metric;
  from_value_definition: ValueDefinition;
  from_value_definition_group: ValueDefinitionGroup;
  active: boolean;
  note: string;
}

export enum TemplateReportVersionStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface TemplateReportVersion extends BaseTemplateReport {
  version: string;
  version_number: number;
  template_report_id: string;
  status: TemplateReportVersionStatus;
}

export interface TemplateReport extends CreatedUpdatedEntity {
  id: string;
  name: string;
  code?: string;
  active?: boolean;
  reference_v2: boolean;
  category: ReportCategoryType;
  versions?: TemplateReportVersion[];
  frameworks: Framework[];
  industries: Industry[];
  tags: Tag[];
  topics: Topic[];
  integration?: ReportIntegrationType;
}

export interface TemplateReportCheckResponse {
  available: boolean;
}

export interface TemplateReportsFilters {
  active?: boolean;
  category?: ReportCategoryType;
  frameworks?: string[];
  industries?: string[];
  tags?: string[];
  topics?: string[];
}

export interface TemplateReportsFilteringOptions {
  filters?: TemplateReportsFilters;
  search_term?: string;
  page?: number;
  page_size?: number;
  active?: boolean | null;
  order_by?: string;
  order_by_direction?: 'asc' | 'desc';
}

export interface GetTemplateReportQueryParams {
  includeSections?: boolean;
  includeMetrics?: boolean;
  loadFrameworks?: boolean;
  loadTags?: boolean;
  loadTopics?: boolean;
  loadIndustries?: boolean;
}

export interface TemplateReportSectionMetric {
  section: TemplateReportSection;
  metric: Metric;
}

export interface TemplateReportSectionMetricPayload extends UpdateTemplateReportSectionMetricPayload {
  sectionId: string;
}

export enum ExportTemplateReportFormat {
  csv = 'csv',
  docx = 'docx',
  pdf = 'pdf',
  xlsx = 'xlsx',
}

export interface ExportTemplateReportPayload {
  file_name: string;
  category?: DocumentCategory | PlaformDocumentCategory | null;
  doc_type?: DocumentType | null;
  export_format: ExportTemplateReportFormat;
}

export interface DisplayMetric {
  metric_id: string;
  value_definition_group_id?: string;
  value_definition_id?: string;
  value_label?: string;
}

export interface SectionMetricDisplay {
  code: string;
  description: string;
  display_metrics: DisplayMetric[];
  metametric: boolean;
}

export interface UpdateTemplateReportSectionMetricPayload {
  placeholder_code?: string | null;
  placeholder_description?: string | null;
}

export interface TemplateReportSectionNode extends TemplateReportSection {
  isExpanded?: boolean;
}

export interface ReportSectionNode extends Section {
  isExpanded?: boolean;
}

export interface TemplateReportVersionsFilteringOptions {
  limit?: number;
  offset?: number;
  order_by?: string;
  order_by_direction?: 'asc' | 'desc';
  search_term?: string;
}

export interface TemplateReportMappingsFilteringOptions {
  page?: number;
  page_size?: number;
  metric_code_search?: string;
  field_label_search?: string;
  value_definition_type?: string;
}

export interface CreateTemplateReportVersionMappingPayload {
  template_report_version_id: string;
  metric_id: string;
  value_definition_id: string;
  value_definition_group_id: string;
  from_template_report_version_id: string;
  from_metric_id: string;
  from_value_definition_id: string;
  from_value_definition_group_id: string;
  note?: string;
}

export type MinimalReport = Pick<Report, 'id' | 'name' | 'description'>;

export interface SearchReportPayloadFilters {
  report_frequencies?: string[];
  business_units?: string[];
  topics?: string[];
  category?: string;
  frameworks?: string[];
  status?: string;
  tags?: string[];

  search_term?: string;
}

export interface SearchReportPayload extends ReportOrderData {
  filters: SearchReportPayloadFilters;
  page_size?: number;
  page?: number;
}

export interface ReportOrderData {
  order_by?: string;
  order_by_direction?: 'asc' | 'desc';
}

export interface QuestionnaireReportValidationError {
  error_message: string;
  file_id: string;
  file_name: string;
  indicator_code: string;
  indicator_description: string;
  indicator_id: string;
  value_definition_id: string;
  value_definition_label: string;
  value_definition_type: string;
  metric_override_code?: string;
}
