import { Pipe, PipeTransform } from '@angular/core';
import { FiscalYearPeriod } from '../../models';
import dayjs from 'dayjs';

@Pipe({
  name: 'frequencyFieldPeriod',
})
export class FrequencyFieldPeriodPipe implements PipeTransform {
  transform(fiscalYearPeriod: FiscalYearPeriod): string {
    return this.formatDateRange(fiscalYearPeriod.start, fiscalYearPeriod.end);
  }

  formatDateRange(startDateStr?: string, endDateStr?: string): string {
    if (!startDateStr || !endDateStr) {
      return '';
    }
    const startDate = dayjs(startDateStr);
    const endDate = dayjs(endDateStr);
    if (!startDate.isValid() || !endDate.isValid()) {
      return '';
    }
    return `${startDate.format('MMM D, YYYY')} - ${endDate.format('MMM D, YYYY')}`;
  }
}
