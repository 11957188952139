@if (data$ | async; as data) {
  <h4>{{ "Set category defaults" | translate }}</h4>
  <p class="mb-5">{{ "All categories require a default option list to be selected" | translate }}</p>
  @for (item of data | objectValues; track trackBy($index, item)) {
    <div class="category-row">
      <span class="category-label mb-4">{{ item.category.name }}</span>
      <div class="category-select-container">
        <lib-searchable-select-input
          [options]="item.options"
          [isLoadingOptions]="item.isLoading"
          [control]="item.control"
          [reachedEnd]="item.reachedEnd"
          [minMenuScrollItems]="0"
          [bindOptionLabelFn]="bindOptionLabelFn"
          [compareFn]="compareFn"
          (openedChange)="openedChange($event, data[item.category.id])"
          (loadOptions)="loadOptionLists(data[item.category.id], $event.from, $event.searchValue)"
        ></lib-searchable-select-input>
      </div>
      <span class="mb-4">
        @if (item.control.value) {
          <mat-icon class="check-icon" svgIcon="check"></mat-icon>
        } @else {
          <mat-icon class="close-icon" svgIcon="close"></mat-icon>
        }
      </span>
    </div>
  }
}
