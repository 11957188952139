@if (control) {
  <div
    libFormField
    [size]="size"
    [required]="required"
    [control]="control"
    [labelPosition]="labelPosition"
    [dataTestId]="'select-input'"
  >
    <lib-form-field-label
      [label]="label"
      [control]="labelControl || control"
      [id]="_labelId"
      [fieldInfo]="fieldInfo"
    ></lib-form-field-label>
    <mat-form-field>
      <mat-select
        [formControl]="control"
        [aria-labelledby]="_labelId"
        [aria-label]="ariaLabel"
        (selectionChange)="onSelectionChange($event.value)"
        panelClass="select-input-option-panel"
        #focusElement
        libFocusState
      >
        @if (!required && showEmptyOption) {
          <mat-option [value]="undefined">--</mat-option>
        }
        @for (option of options; track option) {
          <mat-option
            class="multiline-mat-option"
            [value]="option.id"
            [disabled]="option.deactivated ?? false"
            [matTooltip]="option.deactivated ? disabledOptionsTooltip : ''"
          >
            {{ option.title }}
          </mat-option>
        }
      </mat-select>
      @if (hint) {
        <mat-hint>{{ hint }}</mat-hint>
      }
      @for (error of control.errors | errorKeys; track error) {
        <mat-error> {{ errorMessages[error] }}</mat-error>
      }
    </mat-form-field>
  </div>
}
