<div class="panel-properties-content-header mh-2">
  <div class="panel-properties-content-header-title">
    <h4 class="mat-subtitle-2">{{ "Settings" | translate }}</h4>
  </div>
  <div class="panel-properties-content-header-actions"></div>
</div>

<div class="panel-properties-content-container fx-col">
  <div class="fields-container pb-1">
    @if (metric) {
      <lib-metric-settings-details
        [metric]="metric"
        [refMetricsV2EnabledFF]="metric.reference_v2 || refMetricsV2EnabledFF"
      ></lib-metric-settings-details>
    }
    <lib-divider class="mv-4"></lib-divider>

    <form class="form mt-3" [formGroup]="metricForm">
      @if (isAdmin && metric?.category === eMetricCategory.REFERENCE) {
        <p>
          {{ (this.metric?.reference_v2 ? "Metric version 2.0" : "Metric version 1.0") | translate }}
        </p>
      }

      @if (codeFormControl.enabled) {
        <div class="formfield medium required">
          <lib-text-input
            [label]="'code' | translate"
            [prefix]="metric?.category === eMetricCategory.CUSTOM ? 'CUS' : metric?.framework?.code?.toUpperCase()"
            [hint]="'20 characters max' | translate"
            [control]="codeFormControl"
            [messages]="codeFieldValidationMsgs"
          ></lib-text-input>
        </div>
      }

      @if (descriptionFormControl.enabled) {
        <div class="formfield large required">
          <lib-text-area-input
            [label]="'Name or description' | translate"
            [control]="descriptionFormControl"
            [messages]="descriptionFieldValidationMsgs"
          ></lib-text-area-input>
        </div>
      }

      @if (guidanceFormControl.enabled) {
        <div class="formfield large">
          @if (technicalProtocolEnabled) {
            <lib-rich-text-input
              [control]="guidanceFormControl"
              [hint]="
                'Enter your organization’s internal standards or specifications. This is visible to all users when viewing the metric, including request participants.'
                  | translate
              "
              [label]="'Metric Technical Protocol' | translate"
            ></lib-rich-text-input>
          } @else {
            <lib-rich-text-input
              [control]="guidanceFormControl"
              [hint]="'Optional' | translate"
              [label]="'Guidance' | translate"
            ></lib-rich-text-input>
          }
        </div>
      }

      @if (topicsFormControl.enabled) {
        <lib-multi-select-chip-input
          class="large"
          [label]="'Topics' | translate"
          [hint]="'Start typing to see topics' | translate"
          [control]="topicsFormControl"
          [options]="topicOptions"
          bindLabel="title"
          compareWith="id"
        >
        </lib-multi-select-chip-input>
      }

      @if (!(this.refMetricsV2EnabledFF || this.metric?.reference_v2)) {
        @if (relatedMetricsFormControl.enabled) {
          <lib-multi-select-chip-input
            class="large readonly"
            [label]="'Related to' | translate"
            [hint]="'Start typing to see metrics' | translate"
            [control]="relatedMetricsFormControl"
            [options]="metricOptions"
            bindLabel="title"
            compareWith="id"
            (filterValueChanged)="updateMetricsOptions($event)"
            [forceControlValue]="true"
          >
          </lib-multi-select-chip-input>
        }
        @if (standardCodesFormControl.enabled) {
          <lib-multi-select-chip-input
            class="large readonly"
            [label]="'Compatible with' | translate"
            [hint]="'Start typing to see standard codes' | translate"
            [control]="standardCodesFormControl"
            [options]="(standardCodesOptions$ | async) ?? []"
            (filterValueChanged)="searchStandardCodes($event)"
            bindLabel="title"
            compareWith="id"
            [forceControlValue]="true"
          >
          </lib-multi-select-chip-input>
        }
      }

      @if (tagsFormControl.enabled) {
        <lib-tags-multi-select-input class="large" [control]="tagsFormControl"> </lib-tags-multi-select-input>
      }
    </form>

    @if (isAdmin && metric?.category === eMetricCategory.THIRD_PARTY) {
      <div class="mt-4">
        <lib-taxonomies-card
          [enableAdd]="true"
          [enableDelete]="true"
          [taxonomies]="taxonomies"
          (handleAdd)="addTaxonomies()"
          (handleDelete)="deleteTaxonomy($event)"
        >
        </lib-taxonomies-card>
      </div>
    }
  </div>
  <div>
    <lib-divider class="pb-4"></lib-divider>

    <div class="form-submit">
      <button
        lib-button
        buttonType="success"
        [disabled]="metricForm.pristine || metricForm.invalid"
        [isLoading]="!!(updating$ | async)"
        (click)="saveProperties()"
        [label]="'Save' | translate"
        class="mr-4"
      ></button>
      <button lib-button buttonType="link" (click)="closeSettings()">{{ "Cancel" | translate }}</button>
    </div>
  </div>
</div>
