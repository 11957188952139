<div class="h-100 fx-col justify-center align-center empty-results">
  <mat-icon
    data-testid="icon"
    aria-hidden="false"
    [attr.aria-label]="displayData.image ?? 'laptop-neutral'"
    class="icon-xxl color-primary-900"
    [svgIcon]="displayData.image ?? 'laptop-neutral'"
  ></mat-icon>
  <div class="shadow mt-6"></div>

  <div class="headline1 color-grey-900 mt-4 title">
    {{ displayData.title }}
  </div>

  @if (showDivider) {
    <lib-divider class="w-100 mv-1"></lib-divider>
  }
  @if (displayData.subtitle) {
    <div class="headline2 color-grey-900 mt-2 subtitle" [libViewerAction]="displayData.viewerAction">
      {{ displayData.subtitle }}
    </div>
  }
  @if (displayData.linkText) {
    <div class="text caption2 color-grey-800 mt-2">
      <lib-link [routerLink]="displayData.linkAddress" [text]="displayData.linkText"></lib-link>
    </div>
  }
  @if (displayData.button) {
    <a
      data-testid="emptyResultsBtn"
      class="btn mt-3"
      [ngClass]="displayData.btnTypeCssClass ?? btnClass.success"
      (click)="onBtnClick()"
      [libViewerAction]="displayData.viewerAction"
      >{{ displayData.button }}</a
    >
  }
</div>
