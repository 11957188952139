<div class="search-container" [ngClass]="{ 'full-width': fullWidth }">
  <div class="search-keywords">
    <mat-icon class="search-icon" svgIcon="search"></mat-icon>
    <input
      type="text"
      [placeholder]="searchBarPlaceholder"
      [ngModel]="searchQuery"
      (ngModelChange)="onSearchQueryChanged($event)"
      (keyup.enter)="onSearchQueryChanged(undefined, true)"
    />
  </div>
  @if (searchQuery) {
    <a class="clear-search" (click)="clearSearch()" role="button">
      <mat-icon class="icon-sm" svgIcon="close"></mat-icon>
    </a>
  }
</div>
