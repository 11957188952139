<mat-menu #menu="matMenu">
  <ng-template matMenuContent>
    <header>
      <a mat-menu-item disabled>{{ optionProps?.title }}</a>
    </header>

    @if (optionProps && optionProps.defaultValue) {
      <hr />
      <a mat-menu-item (click)="optionSelected.emit(optionProps.defaultValue)">
        {{ defaultValueTitle }}
      </a>
    }

    <hr />

    @for (option of optionProps?.options; track option) {
      <a
        mat-menu-item
        (click)="optionSelected.emit(option)"
        [ngClass]="{
          active: optionProps?.activeOption?.id === option.id,
        }"
      >
        {{ option.title }}
      </a>
    }
  </ng-template>
</mat-menu>

<div
  class="btn btn-filter filter-container"
  [matMenuTriggerFor]="menu"
  [ngClass]="[buttonProps?.disabled ? 'btn-disabled disabled' : '', buttonProps?.active ? 'active' : '']"
  data-testid="filter-button-container"
>
  @if (buttonProps && buttonProps.icon) {
    <span>
      <mat-icon class="icon-sm mr-1" [svgIcon]="buttonProps.icon"></mat-icon>
    </span>
  }
  <div class="mat-body-1">{{ "Filtered by:" | translate }} {{ buttonTitle }}</div>
</div>
