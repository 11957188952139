<div
  class="multi-select choice-checkbox-field"
  tabindex="0"
  data-testid="checkboxFormField"
  [ngClass]="{ required: required, disabled: control.disabled, error: control.touched && control.invalid }"
  #focusElement
>
  <div class="choice-checkbox-field-wrapper">
    @if ((control.valid || control.untouched) && hint) {
      <mat-hint>{{ hint }}</mat-hint>
    }
    @if (control.touched && control.invalid) {
      @for (error of control.errors | errorKeys; track error) {
        <mat-error> {{ errorMessages[error] }}</mat-error>
      }
    }
    <lib-multi-checkbox-input [options]="options" [control]="control"></lib-multi-checkbox-input>
  </div>
</div>
