<gridster [options]="gridsterConfig">
  @for (dashboardWidget of widgets; track dashboardWidget) {
  <gridster-item [item]="dashboardWidget | dashboardWidgetToGridsterItem">
    @switch (dashboardWidget.widget_type) { @case (eWidgetType.CHART_WIDGET) {
    <lib-chart-widget
      [widget]="dashboardWidget"
      [metricDetailsUrl]="metricUrls.get(dashboardWidget.id) ?? ''"
    ></lib-chart-widget>
    } @case (eWidgetType.DATA_POINT_WIDGET) {
    <lib-single-data-point-widget
      [widget]="dashboardWidget"
      [fiscalYearsItem]="fiscalYearItems"
      [metricDetailsUrl]="metricUrls.get(dashboardWidget.id) ?? ''"
    ></lib-single-data-point-widget>
    } @case (eWidgetType.VALUE_DEFINITION_TARGET_WIDGET) {
    <lib-value-definition-target-widget
      [widget]="dashboardWidget"
      [metricDetailsUrl]="metricUrls.get(dashboardWidget.id) ?? ''"
    ></lib-value-definition-target-widget>
    } }
  </gridster-item>
  }
</gridster>
