<div class="text-wrapper">
  <p
    [class.expanded]="isExpanded"
    class="more-text-container"
    [style.--max-lines]="maxLines"
    data-testid="more-text-testid"
  >
    @if (isHtml) {
      <span [innerHTML]="text | sanitizeHtml"></span>
    } @else {
      {{ text }}
    }

    @if (needsTruncation && !isExpanded) {
      <button lib-button buttonType="link" class="show-more" (click)="toggleText()">
        ...{{ "Show More" | translate }}
      </button>
    }
  </p>
  @if (needsTruncation && isExpanded) {
    <button lib-button buttonType="link" class="show-less" (click)="toggleText()">
      {{ "Show Less" | translate }}
    </button>
  }
</div>
