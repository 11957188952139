<button class="profile" [matMenuTriggerFor]="accountMenu" aria-label="profile navigation menu">
  <span>{{ userFirstLetter }}</span>
</button>

<mat-menu #accountMenu="matMenu">
  <ng-template matMenuContent>
    <div class="profile-description">
      <div class="profile-badge">{{ userFirstLetter }}</div>
      <div class="mat-subtitle-2">{{ user | fullName }}</div>
      <div class="email">{{ user.email }}</div>
      <div>
        {{ userDescription }}
      </div>
    </div>
    <lib-divider></lib-divider>
    @if (profileRouterLink) {
      <a
        mat-menu-item
        class="item"
        [routerLink]="profileRouterLink"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active-item"
      >
        <mat-icon class="icon-link" svgIcon="client"></mat-icon>
        <span>{{ "My profile" | translate | titlecase }}</span>
      </a>
    }
    @if (passwordRouterLink && passwordManageOptionEnabled) {
      <a
        mat-menu-item
        class="item"
        [routerLink]="passwordRouterLink"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active-item"
      >
        <mat-icon class="icon-link" svgIcon="locked"></mat-icon>
        <span>{{ "Manage Credentials" | translate | titlecase }}</span>
      </a>
    }
    @if (passwordRouterLink || profileRouterLink) {
      <lib-divider></lib-divider>
    }
    <a mat-menu-item class="color-error" (click)="onLogout()">
      <mat-icon class="icon-link" svgIcon="arrow-full-right"></mat-icon>
      <span>{{ "Sign out" | translate | titlecase }}</span>
    </a>
  </ng-template>
</mat-menu>
