<div mat-dialog-title>
  <h3 class="mat-h3">{{ "Session has expired" | translate }}</h3>
</div>
<div mat-dialog-content>
  <div class="mb-3">
    {{ "Your session has expired. Enter your password to continue." | translate }}
  </div>
  <div class="formfield required">
    <div class="label">{{ "Password" | translate }}</div>
    <mat-form-field>
      <input matInput [type]="pwdType" [formControl]="password" />
      <a (click)="togglePwdType()" matSuffix class="mat-icon-formfield">
        @if (pwdType === "password") {
          <mat-icon svgIcon="show"></mat-icon>
        }
        @if (pwdType === "text") {
          <mat-icon svgIcon="hide"></mat-icon>
        }
      </a>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <a (click)="login()" class="btn btn-success" [ngClass]="{ 'btn-loading': loading, 'btn-disabled': !password.value }">
    {{ "Continue" | translate }}
  </a>
  <button lib-button buttonType="link" (click)="logout()">{{ "Logout" | translate }}</button>
</div>
