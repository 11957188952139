@if (data$ | async; as data) {
  <lib-page-context-bar [pageName]="'Option Lists' | translate">
    <ng-container *libPermission="[managePermission]" main-actions>
      <button lib-button buttonType="primary" (click)="setSidebarVisible(true)">
        + {{ "Add option list" | translate }}
      </button>
      @if (withDefaultColumn) {
        <button lib-button (click)="setCategoryDefaultSidebarVisible(true)">
          <div data-testid="defaultCategoriesBtn">
            <mat-icon class="icon-lg" svgIcon="settings"></mat-icon>
          </div>
        </button>
      }
    </ng-container>
  </lib-page-context-bar>
  @if (data.tableFilters.length) {
    <lib-filter-bar
      [secondaryFilters]="data.tableFilters"
      [withSearchBar]="true"
      [searchBarPlaceholder]="'Type to search' | translate"
      (onSearchChanged)="onSearchChange($event)"
      (onFilterChanged)="onFilterChange($event)"
    ></lib-filter-bar>
  }
  <div class="p-5">
    @if (!data.isLoading && data.total) {
      <lib-table-grid
        [columns]="optionListTableColumns"
        [values]="data.optionLists"
        [isCustomSort]="true"
        [isPaginable]="true"
        [isResizable]="true"
        [totalItemCount]="data.total"
        [actionMenuItems]="optionListActionMenu"
        (pageChanged)="onPageChange($event)"
        (sortChanged)="onSortChange($event)"
        (rowSelected)="viewOptionList.emit($event)"
      >
      </lib-table-grid>
    } @else {
      @if (!data.isLoading) {
        <lib-empty-results [displayData]="emptyResults"></lib-empty-results>
      } @else {
        <lib-dummy [presentation]="ePresentation.matTable" data-testid="dummy-matTable"></lib-dummy>
      }
    }
  </div>
}

<ng-template #categoryCell let-element>
  <span>{{ element && optionListCategories[element] ? optionListCategories[element].title : "" }}</span>
</ng-template>

<ng-template #defaultCell let-element>
  @if (element) {
    <span><mat-icon class="default-icon" svgIcon="check"></mat-icon></span>
  }
</ng-template>

<ng-template #statusCell let-element>
  <lib-tag
    [value]="(element ? 'Active' : 'Inactive') | translate"
    [tagType]="element ? eTagType.INFO : eTagType.GREY"
  ></lib-tag>
</ng-template>

<ng-template #typeCell let-element>
  <span>{{ element ? eOptionListTypeTranslations[element] : "" }}</span>
</ng-template>

<lib-sidebar
  [secondaryBtn]="'Cancel' | translate"
  [primaryBtn]="'Save' | translate"
  [primaryBtnDisabled]="!form?.valid"
  [title]="'Add option list' | translate"
  [visible]="isSidebarVisible"
  (secondaryClick)="setSidebarVisible(false)"
  (primaryClick)="save()"
  (visibleChange)="setSidebarVisible($event)"
>
  <div class="p-4">
    @if (form) {
      <lib-option-list-form
        [form]="form"
        [optionListCategories]="optionListCategories | objectValues"
        [withPublicField]="withPublicField"
        [withTypeField]="withTypeColumn"
      ></lib-option-list-form>
    }
  </div>
</lib-sidebar>

<lib-sidebar
  [secondaryBtn]="'Close' | translate"
  [title]="'Option list settings' | translate"
  [visible]="isCategoryDefaultSidebarVisible"
  (secondaryClick)="setCategoryDefaultSidebarVisible(false)"
  (visibleChange)="setCategoryDefaultSidebarVisible($event)"
>
  <div class="p-4"><lib-option-list-category-defaults></lib-option-list-category-defaults></div>
</lib-sidebar>
