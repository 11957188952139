<lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
<mat-form-field class="w-100" floatLabel="auto">
  <mat-label>{{ data.data.type_details.placeholder }}</mat-label>
  <mat-select
    [formControl]="control"
    [multiple]="data.data.type_details.multi_choices"
    [matTooltip]="data.data.value"
    id="{{ data.id }}"
    panelClass="form-select-panel"
    (click)="filterControl.setValue('')"
    (opened)="setFocusOnSearch()"
  >
    @if ((displayChoices.length || 0) > minMenuScrollItems) {
      <div class="select-search fx-row align-center" (click)="$event.stopPropagation()">
        <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
        <input
          placeholder="{{ 'Search' | translate }}"
          autocomplete="off"
          [formControl]="filterControl"
          tabIndex="1"
          #matSelectPanelInput
        />
        @if (filterControl.value) {
          <a class="clear-search" (click)="filterControl.setValue('')">
            <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
          </a>
        }
        <hr />
      </div>
    }
    <div class="options-container">
      @for (option of filteredChoices | async; track option) {
        <mat-option class="multiline-mat-option" [value]="option" [ngClass]="{ active: control.value === option }">
          {{ option }}
        </mat-option>
      }
      @if (!(filteredChoices | async)?.length) {
        <mat-option>{{ "No results found" | translate }}</mat-option>
      }
    </div>
  </mat-select>
  <mat-hint>{{ data.data.hint }}</mat-hint>
  @if (control.hasError("required")) {
    <mat-error> {{ "Missing value" | translate }}</mat-error>
  }
</mat-form-field>
