import { Value } from '../../values';
import { Frequency } from '../../valueDefinitionFrequencies';

export function isPeriodValue(value?: Value): boolean {
  return !!value?.fiscal_year_period;
}

export function isValueWithSubPeriods(value?: Value): boolean {
  // This check is mandatory because period value doesn't have a value_definition_frequency.
  if (value?.value_definition_frequency?.frequency) {
    return value.value_definition_frequency.frequency != Frequency.YEARLY;
  } else {
    return false;
  }
}
