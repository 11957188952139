import { Entity } from '../entities';
import { PaginationFilter } from '../pagination';
import { DashboardWidget } from './widgets';

export interface Dashboard extends Entity {
  visibility: DashboardPublicVisibility;
  widgets: DashboardWidget[];
}

export interface DashboardTemplate {
  id: string | null;
}

export interface DashboardCreationRequest {
  name: string;
  v2?: boolean;
}

export interface DashboardRenameRequest {
  name: string;
}

export interface DashboardUpdatePublicVisibilityRequest {
  visibility: DashboardPublicVisibility;
}

export interface DashboardCopyRequest {
  name: string;
}

export enum DashboardPublicVisibility {
  PUBLIC = 'public',
  ORGANIZATION = 'organization',
  RESTRICTED = 'restricted',
}

export interface DashboardsFilteringOptions extends PaginationFilter {
  filters: {
    search_term?: string;
    owner?: string;
    organizational_visibility?: DashboardPublicVisibility;
    v2?: boolean;
  };
}
