<div class="mv-3">
  @if (showMasterExpandControls) {
    <div class="fx-row expand-all-wrapper">
      @if (expandedPanelsCount !== items.length) {
        <button lib-button (click)="accordion.openAll()">
          {{ "Expand All" | translate }}
          <mat-icon class="ml-1" svgIcon="arrow-head-down"></mat-icon>
        </button>
      }
      @if (expandedPanelsCount === items.length) {
        <button lib-button (click)="accordion.closeAll()">
          {{ "Collapse All" | translate }}
          <mat-icon class="ml-1" svgIcon="arrow-head-up"></mat-icon>
        </button>
      }
    </div>
  }

  <mat-accordion multi #accordion="matAccordion">
    @for (item of items; track item; let i = $index) {
      <mat-expansion-panel
        (expandedChange)="onExpandedChange($event)"
        [expanded]="expandIndices?.includes(i)"
        class="mat-expansion-cards-panel"
      >
        <mat-expansion-panel-header class="ph-4" [collapsedHeight]="cardHeight" [expandedHeight]="cardHeight">
          @if (headerTemplateRef) {
            <ng-container
              [ngTemplateOutlet]="headerTemplateRef"
              [ngTemplateOutletContext]="{ $implicit: item, index: i }"
            >
            </ng-container>
          }
        </mat-expansion-panel-header>
        <div class="ph-4">
          <hr class="m-0 light" />
          <div class="pv-3">
            @if (contentTemplateRef) {
              <ng-container [ngTemplateOutlet]="contentTemplateRef" [ngTemplateOutletContext]="{ $implicit: item }">
              </ng-container>
            }
          </div>
        </div>
      </mat-expansion-panel>
    }
  </mat-accordion>
</div>
