<lib-panel-details cdkDropListGroup [title]="'Types'">
  <lib-metric-structure-fields-selection
    class="h-100"
    slot="collection"
    [disableFields]="!(isAdmin | canAddField: metricData)"
    [valueDefinitionTemplates]="valueDefinitionTemplates"
    [disableSpecialTypes]="disableSpecialTypes"
  ></lib-metric-structure-fields-selection>
  <div slot="item" class="panel-metric" [ngClass]="{ 'item-selected': selectedItem }">
    @if (metricData) {
      <lib-metric-structure-form-panel [metric]="metricData!" class="panel-content"></lib-metric-structure-form-panel>
      @if (fieldVisibility$ | async; as fieldsVisibility) {
        <lib-metric-structure-properties-panel
          [metric]="metricData!"
          [metricStructureSelectable]="selectedItem"
          [sourceConfiguration]="sourceConfiguration"
          [fiscalYears]="fiscalYears"
          [fieldVisibility]="fieldsVisibility"
          class="panel-properties h-100"
          (closePanel)="onPanelClose()"
        ></lib-metric-structure-properties-panel>
      }
    }
  </div>
</lib-panel-details>
