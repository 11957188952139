@if (metric) {
  <a
    class="card metric-card"
    (click)="editMetric.emit()"
    [ngClass]="{ active: highlightMetric }"
    data-testid="metric-card"
  >
    <div>
      <div class="mat-small color-grey-800">
        {{ metric.code }}
      </div>
      <div class="mat-body color-primary-900">
        {{ metric.description }}
      </div>
    </div>
    <button class="card-menu context-menu-button" (click)="openMetricContextMenu($event)">
      <mat-icon svgIcon="more" class="icon-md"></mat-icon>
    </button>
  </a>
}

<div
  class="context-menu-trigger"
  [style.left]="menuPosition.x"
  [style.top]="menuPosition.y"
  #metricTrigger="matMenuTrigger"
  [matMenuTriggerFor]="metricMenu"
></div>

<mat-menu #metricMenu="matMenu" (closed)="highlightMetric = false">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="editMetric.emit()">
      <mat-icon class="icon-sm" svgIcon="custom"></mat-icon>
      <span>{{ "Customize" | translate }}</span>
    </button>
    <button mat-menu-item (click)="previewMetric.emit()">
      <mat-icon class="icon-sm" svgIcon="show"></mat-icon>
      <span>{{ "See metric" | translate }}</span>
    </button>

    <ng-container *libPermission="managePermissions">
      @if (!isFirst || !isLast) {
        <lib-divider></lib-divider>
      }

      @if (!isFirst) {
        <button mat-menu-item (click)="moveMetricUp()">
          <mat-icon class="icon-sm" svgIcon="arrow-full-up"></mat-icon>
          <span>{{ "Move up" | translate }}</span>
        </button>
      }
      @if (!isLast) {
        <button mat-menu-item (click)="moveMetricDown()">
          <mat-icon class="icon-sm" svgIcon="arrow-full-down"></mat-icon>
          <span>{{ "Move down" | translate }}</span>
        </button>
      }

      <lib-divider></lib-divider>

      <button mat-menu-item class="color-error" (click)="deleteMetric()">
        <mat-icon svgIcon="trash" class="icon-sm"></mat-icon>
        <span>{{ "Remove" | translate }}</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
