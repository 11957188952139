import { ValueDefinitionSize } from './ui';
import { Entity } from './entities';
import { ChoiceTypeDetails, TypeDetails } from './type_details';
import { ConditionalTrigger, MinimalMetric, Validator, ValueDefinitionType, ValueGroupSet } from './metrics';
import { ConsolidationRules, ConsolidationTriggers } from './consolidation';
import { MinimalReport } from './reports';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Frequency, ValueDefinitionFrequency } from './valueDefinitionFrequencies';
import { FiscalYearPeriod } from './fiscal-years';

export const NOT_APPLICABLE_NUMERIC_VALUE = 'N/A';

export interface BaseValue<T extends TypeDetails = any, U = any> extends Entity {
  type: ValueDefinitionType;
  type_details: T;
  label?: string;
  position: number;
  size: ValueDefinitionSize;
  newline: boolean;
  required?: boolean;
  value_definition_group_id: string;
  value_definition_id: string;
  core_value_definition_id?: string;
  validators?: Validator[];
  value?: U;
  unit?: string;
  hint?: string;
  conditional_triggers?: ConditionalTrigger[];
  consolidation_rule: ConsolidationRules;
  consolidation_trigger?: ConsolidationTriggers;
  bypass_consolidation_levels?: number[] | null;
  null_category?: PeerDataValueNullCategories;
  unresolved_regular_comments_and_replies?: number;
  unresolved_rejection_comments_and_replies?: number;
  technical_protocol?: string;
  value_definition_frequency?: ValueDefinitionFrequency | null;
  fiscal_year_period?: FiscalYearPeriod;
  fiscal_year_period_id?: string;
  year_to_date_value_id?: string;
  value_group_set_frequency_code?: string;
  value_group_set_business_unit_id?: string;
}

export interface BooleanValue {
  additional_text: string;
  value: boolean | null;
}

export interface ChoiceValue {
  additional_text?: string;
  values: string[];
}

export interface SubtitleValue {
  value: string;
}

export interface FileV1Value extends FileValue {}

export interface FileV2Value extends Omit<FileValue, 'file_id'> {
  file_id: string;
}

export interface FileValue {
  file_id?: string | undefined;
  url?: string | null;
  page_number?: number | null;
  explanation?: string | null;
  prefilled?: boolean | null;
}

export function isChoiceValue(value: unknown): value is ChoiceValue {
  return typeof value === 'object' && value !== null && Object.prototype.hasOwnProperty.call(value, 'values');
}

export const isChoiceValueType = (v: Value): v is Value<ChoiceTypeDetails, ChoiceValue> =>
  v.type === ValueDefinitionType.choice;

export type ValueType =
  | string
  | string[]
  | ChoiceValue
  | BooleanValue
  | SubtitleValue
  | FileValue[]
  | Date
  | number
  | null;

export interface Value<T extends TypeDetails = any, U extends ValueType = any> extends BaseValue<T, U> {
  value_group_id?: string;
  is_automatically_calculated?: boolean;
  is_consolidated?: boolean;
  is_read_only?: boolean;
  value_breakdown_frequency?: ValueBreakdownFrequency[];
}

export interface DataRequestValue<T extends TypeDetails = any> extends BaseValue<T> {
  data_request_value_group_id?: string;
  prefilled?: boolean;
  value_breakdown_frequency?: DataRequestValueBreakdownFrequency[];
  is_automatically_calculated?: boolean;
  is_consolidated?: boolean;
}

export interface ValueWithUserDetails<T extends TypeDetails = any, U extends ValueType = any> extends Value<T, U> {
  updated_by_name?: string;
}

export function isValue(value: BaseValue): value is Value {
  return 'value_group_id' in value;
}

export function isDataRequestValue(value: BaseValue): value is DataRequestValue {
  return 'data_request_value_group_id' in value;
}

export function getValueGroupId(value: Value | DataRequestValue): string | undefined {
  if (isValue(value)) {
    return value.value_group_id;
  } else if (isDataRequestValue(value)) {
    return value.data_request_value_group_id;
  }

  return undefined;
}

export function isYTD(value: Value): boolean {
  if (
    !value.year_to_date_value_id &&
    !value.fiscal_year_period_id &&
    value.value_definition_frequency?.frequency !== Frequency.YEARLY &&
    value.value_breakdown_frequency &&
    value.value_breakdown_frequency.length > 0
  ) {
    return true;
  }
  return false;
}

export interface ReportHistoricalValue {
  template_version_id?: string;
  mapped_report?: MinimalReport;
  mapped_metric?: MinimalMetric;
  value_definition_group_id: string;
  value_definition_ids: string[];
  values: Value[];
  frequency_code: string;
  value_group_set?: ValueGroupSet[];
}

export enum PeerDataValueNullCategories {
  no_value_provided = 'no_value_provided',
  not_publicly_available = 'not_publicly_available',
  not_applicable = 'not_applicable',
  not_available = 'not_available',
}

export const PeerDataValueNullCategoriesLabels = {
  [PeerDataValueNullCategories.no_value_provided]: _('No value provided'),
  [PeerDataValueNullCategories.not_publicly_available]: _('Not publicly available'),
  [PeerDataValueNullCategories.not_applicable]: _('Not applicable'),
  [PeerDataValueNullCategories.not_available]: _('Not available'),
};

export interface ValueBreakdownFrequency<T = number> extends Value {
  value?: T | null;
  value_group_id?: string;
  value_definition_id: string;
  year_to_date_value_id?: string;
  fiscal_year_period?: FiscalYearPeriod;
  updated_by_name?: string;
  is_automatically_calculated?: boolean;
  is_consolidated?: boolean;
}

export interface DataRequestValueBreakdownFrequency<T = number> extends DataRequestValue {
  value?: T | null;
  data_request_value_group_id?: string;
  value_definition_id: string;
  year_to_date_value_id?: string;
  fiscal_year_period?: FiscalYearPeriod;
  updated_by_name?: string;
  is_automatically_calculated?: boolean;
  is_consolidated?: boolean;
}
