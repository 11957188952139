@if (data$ | async; as data) {
  @if (isAdmin || settingsOptionListsEnabled) {
    @if (legacySelection) {
      <lib-info-banner
        class="mv-2"
        [message]="'Editing this list requires selecting from the lists in Option List Settings.' | translate"
        type="warning"
      ></lib-info-banner>
    }
    @if (!isLoading) {
      <lib-select-input
        class="formfield mt-2"
        [label]="'Option list category' | translate"
        [control]="selectionListCategoryFormControl"
        [options]="data.optionListCategories"
      >
      </lib-select-input>
    }
    @if (selectionListCategoryFormControl.value || legacyCoreSelecitonWithoutCategory) {
      <div>
        <div class="fx-row align-center">
          <lib-select-input
            class="formfield option-lists-dropdown"
            [label]="'Option list' | translate"
            [control]="selectionListFormControl"
            [options]="data.optionLists"
            (selectionChangeEvent)="optionListChange()"
          >
          </lib-select-input>
          <ng-container *libPermission="[ePermission.SELECTION_SETS_MANAGE, ePermission.OPTION_LISTS_MANAGE]">
            @if (
              selectionListFormControl.value &&
              !legacySelection &&
              (!valueDefinition.type_details?.selection_set_source ||
                (!isAdmin && valueDefinition.type_details?.selection_set_source === eChoiceFieldSource.platform))
            ) {
              <button
                lib-button
                lib-button
                class="mh-2 option-lists-btn"
                [buttonType]="'primary'"
                (click)="redirectToOptionLists()"
              >
                {{ "See options" | translate }}
              </button>
            }
          </ng-container>
        </div>
        @if (hint) {
          <p class="color-grey-800 caption2 m-0">{{ hint }}</p>
        }
      </div>
    }
    <div class="fx-row align-center mt-2">
      <lib-slide-toggle
        [label]="'Apply Explanation Text Field configuration to all options' | translate"
        [control]="selectionListApplyAllFormControl"
      >
      </lib-slide-toggle>
      <mat-icon svgIcon="info" [title]="applyAllHint"></mat-icon>
    </div>
  } @else {
    <div class="fx-row align-center">
      <lib-select-input
        class="formfield option-lists-dropdown"
        [label]="'Option list' | translate"
        [control]="selectionListFormControl"
        [options]="data.optionLists"
        [messages]="{ invalidChoices: 'Please add choices by clicking the edit button' | translate }"
        (selectionChangeEvent)="updateAnswers()"
      >
      </lib-select-input>
      @if (canCreateCustomChoices) {
        <button
          lib-button
          class="ml-2"
          [disabled]="selectionListFormControl.disabled"
          [buttonType]="'primary'"
          (click)="launchCustomSelectionDialog()"
        >
          {{ "Edit" | translate }}
        </button>
      }
    </div>
  }
}
