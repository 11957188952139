@if (menuItems.length) {
  <div class="fx-row align-center">
    <button
      lib-button
      libClickStopPropagation
      class="p-0 mr-1"
      [buttonType]="'inherit'"
      (click)="action.emit(menuItems[0])"
    >
      {{ menuItems[0].label }}
    </button>
    @if (menuItems.length > 1) {
      <lib-popup-menu
        libClickStopPropagation
        class="popup-menu"
        [icon]="'chevron-down'"
        [menuItems]="menuItems.slice(1)"
        [label]="'+' + (menuItems.length - 1)"
        (action)="action.emit($event)"
      ></lib-popup-menu>
    }
  </div>
}
