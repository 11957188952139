<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="actionsMenu"
  data-testid="actions-menu"
></div>
<mat-menu #actionsMenu="matMenu" (closed)="clearSelectedItems()">
  <ng-template matMenuContent>
    @for (item of actions; track item) {
      <a mat-menu-item (click)="onActionMenuSelect(item)">
        <mat-icon class="icon-sm" svgIcon="{{ item.icon }}"></mat-icon>
        {{ item.title }}
      </a>
    }
  </ng-template>
</mat-menu>

@if (!noLoaders) {
  <div class="h-100 item-dummies">
    <div class="repeat bkg-white">
      @for (i of [1, 2, 3, 4, 5, 6, 7]; track i) {
        <div class="mb-2">
          <div class="caption2 mb-1 rounded-corners bkg-blink w-25">&nbsp;</div>
          <div class="mat-body-1 rounded-corners bkg-blink w-50">&nbsp;</div>
        </div>
      }
    </div>
  </div>
}

<div class="text" [ngClass]="{ 'text-clamp text-clamp-4': clamp }" style="position: relative">
  @if (documents$ | async; as documents) {
    @for (group of sanitizedOrderedValueGroups; track group; let isLastGroup = $last) {
      @if (isElementDisplayed(group)) {
        @if (!group.table_id) {
          @for (valueSet of group.values; track valueSet) {
            @if (isElementDisplayed(valueSet)) {
              <div
                class="m-0 value-container"
                [ngClass]="{
                  'mr-1 fx-row': valueSet.type !== 'tip',
                  'no-action': disableEdit,
                  active: isValueDefHighlighted(valueSet, group),
                }"
                (contextmenu)="onContextMenu($event, valueSet, group)"
                (mouseover)="setHoverIndex(valueSet, group)"
                (mouseout)="setHoverIndex()"
              >
                @if (!(group.repeatable && valueSet.type === "calculated")) {
                  <div class="fx-col">
                    @if (
                      !nullCategoryIncompatibleTypes.includes(valueSet.type) && showNullCategory && !valueSet.value
                    ) {
                      @if (valueSet.null_category) {
                        <div class="value">
                          {{ ePeerDataValueNullCategoriesLabels[valueSet.null_category] }}
                        </div>
                      } @else {
                        <div class="value">
                          {{ ePeerDataValueNullCategoriesLabels[ePeerDataValueNullCategories.not_available] }}
                        </div>
                      }
                    } @else {
                      @switch (valueSet.type) {
                        <!--subtitle-->
                        @case (eValueDefinitionType.subtitle) {
                          <h4 class="mat-subtitle-2">{{ valueSet.type_details?.value }}</h4>
                        }
                        <!--text-->
                        @case (eValueDefinitionType.text) {
                          @if (showLabel) {
                            <div class="mb-1 label">{{ valueSet.label }}</div>
                          }
                          @if (valueSet.type_details.textarea) {
                            <div
                              class="value"
                              [ngClass]="{ active: selectedValue === valueSet }"
                              (click)="selectDefaultAction()"
                            >
                              <span [innerHTML]="valueSet.value | sanitizeHtml"></span>
                              <ng-container
                                *ngTemplateOutlet="
                                  hoverOptions;
                                  context: { value: valueSet, singleLine: true, group: group }
                                "
                              >
                              </ng-container>
                            </div>
                          }
                          @if (!valueSet.type_details.textarea) {
                            <div
                              class="color-grey-900 value"
                              [ngClass]="{ active: selectedValue === valueSet }"
                              (click)="selectDefaultAction()"
                            >
                              <div data-testid="richTextValue" [innerHTML]="valueSet.value | sanitizeHtml"></div>
                              <ng-container
                                *ngTemplateOutlet="hoverOptions; context: { value: valueSet, group: group }"
                              >
                              </ng-container>
                            </div>
                          }
                          @if (valueSet.value === null || !valueSet.value) {
                            <div>
                              <ng-container *ngTemplateOutlet="noValue; context: { value: valueSet }"></ng-container>
                            </div>
                          }
                        }
                        <!--file v2-->
                        @case (eValueDefinitionType.file_v2) {
                          @if (showLabel) {
                            <div class="mb-1 label">{{ valueSet?.label }}</div>
                          }
                          @if (valueSet.value === null || !valueSet.value?.length) {
                            <ng-container *ngTemplateOutlet="noValue; context: { value: valueSet }"></ng-container>
                          } @else {
                            <lib-metric-view-file-v2
                              [value]="valueSet"
                              [documents]="documents"
                              (downloadFile)="downloadFile($event, valueSet)"
                            ></lib-metric-view-file-v2>
                          }
                        }
                        <!--tip-->
                        @case (eValueDefinitionType.tip) {
                          @if (showTip) {
                            <lib-data-formats-tip [value]="valueSet"></lib-data-formats-tip>
                          }
                        }
                        <!--boolean-->
                        @case (eValueDefinitionType.boolean) {
                          @if (showLabel) {
                            <div class="mb-1 label">{{ valueSet.label }}</div>
                          }
                          @if (valueSet.value) {
                            <div
                              class="color-grey-900 value fx-row"
                              (click)="selectDefaultAction()"
                              [ngClass]="{ active: selectedValue === valueSet }"
                            >
                              <div>
                                <span [innerHTML]="getBooleanDisplayValue(valueSet) ?? '' | sanitizeHtml"></span>
                                <ng-container
                                  *ngTemplateOutlet="
                                    hoverOptions;
                                    context: { value: valueSet, singleLine: true, group: group }
                                  "
                                >
                                </ng-container>
                              </div>
                            </div>
                          }
                          @if (valueSet.value === null) {
                            <div>
                              <ng-container *ngTemplateOutlet="noValue; context: { value: valueSet }"></ng-container>
                            </div>
                          }
                        }
                        <!--choice-->
                        @case (eValueDefinitionType.choice) {
                          @if (showLabel) {
                            <div class="mb-1 label">{{ valueSet.label }}</div>
                          }
                          @if (valueSet.value && valueSet.value.values) {
                            <div
                              class="color-grey-900 value"
                              (click)="selectDefaultAction()"
                              [ngClass]="{
                                'fx-row': valueSet.type_details?.multi_choices,
                                active: selectedValue === valueSet,
                              }"
                            >
                              <div>
                                @if (valueSet.type_details?.multi_choices) {
                                  @for (item of valueSet.value.values; track item; let isLast = $last) {
                                    <ul class="m-0 pl-4" [ngClass]="{ 'pb-1': !isLast }">
                                      <li [innerHTML]="item | sanitizeHtml"></li>
                                    </ul>
                                  }
                                } @else {
                                  <span [innerHTML]="valueSet.value.values?.[0] | sanitizeHtml"></span>
                                }
                                <ng-container
                                  *ngTemplateOutlet="
                                    hoverOptions;
                                    context: { value: valueSet, singleLine: true, group: group }
                                  "
                                >
                                </ng-container>
                              </div>
                            </div>
                          }
                          @if (!valueSet.value || !valueSet.value?.values || !valueSet.value?.values?.length) {
                            <div>
                              <ng-container *ngTemplateOutlet="noValue; context: { value: valueSet }"></ng-container>
                            </div>
                          }
                        }
                        <!--document link type-->
                        @case (eValueDefinitionType.document) {
                          @if (documentContext) {
                            <lib-metric-view-document-link
                              [value]="valueSet"
                              [itemType]="documentContext.itemType"
                              [documents]="documents"
                              [documentPayloadOptions]="
                                documentsService.getDocumentPayloadOptions(valueSet, {
                                  itemType: documentContext.itemType,
                                })
                              "
                              class="fx-col"
                            ></lib-metric-view-document-link>
                          }
                        }
                        <!--number type-->
                        @case (
                          [eValueDefinitionType.number, eValueDefinitionType.calculated]
                            | multiSwitchCase: valueSet.type
                        ) {
                          @if (showLabel) {
                            <div class="mb-1 label">{{ valueSet.label }}</div>
                          }
                          @if (valueSet.value) {
                            <div
                              class="color-grey-900 value"
                              (click)="selectDefaultAction()"
                              [ngClass]="{ active: selectedValue === valueSet }"
                            >
                              <span
                                [innerHTML]="
                                  valueSet.value
                                    | numberMask
                                      : valueSet.type_details?.max_decimals
                                      : getUnit(valueSet)
                                      : true
                                      : false
                                      : true
                                    | sanitizeHtml
                                "
                              ></span>
                              <ng-container
                                *ngTemplateOutlet="
                                  hoverOptions;
                                  context: { value: valueSet, singleLine: true, group: group }
                                "
                              >
                              </ng-container>
                            </div>
                          }
                          @if (valueSet.value === null || !valueSet.value?.length) {
                            <div>
                              <ng-container *ngTemplateOutlet="noValue; context: { value: valueSet }"></ng-container>
                            </div>
                          }
                        }
                        <!--default-->
                        @default {
                          @if (showLabel) {
                            <div class="mb-1 label">{{ valueSet.label }}</div>
                          }
                          @if (valueSet.value) {
                            <div
                              class="color-grey-900 value"
                              (click)="selectDefaultAction()"
                              [ngClass]="{ active: selectedValue === valueSet }"
                            >
                              <span
                                [innerHTML]="
                                  valueSet.value + ' ' + (valueSet.type_details?.units ? getUnit(valueSet) : '')
                                    | sanitizeHtml
                                "
                              ></span>
                              <ng-container
                                *ngTemplateOutlet="
                                  hoverOptions;
                                  context: { value: valueSet, singleLine: true, group: group }
                                "
                              >
                              </ng-container>
                            </div>
                          }
                          @if (valueSet.value === null || !valueSet.value?.length) {
                            <div>
                              <ng-container *ngTemplateOutlet="noValue; context: { value: valueSet }"></ng-container>
                            </div>
                          }
                        }
                      }
                    }
                  </div>
                }
                @if (valueSet.type !== "tip" && valueSet.type !== "subtitle" && valueSet.type !== "label") {
                  <div class="overlay" [attr.data-testid]="'overlay-' + valueSet.id" (click)="selectDefaultAction()">
                    @if (!disableEdit) {
                      <div class="edit">
                        <mat-icon class="color-primary-600 icon-sm" svgIcon="edit"></mat-icon>
                      </div>
                    }
                  </div>
                }
              </div>
            }
          }
          @if (!isLastGroup && group.values?.length) {
            <hr class="dark w-100" [attr.data-testid]="'separator-' + group.value_definition_group_id" />
          }
        } @else {
          @if (group | showGroupLabel: sanitizedOrderedValueGroups) {
            <h4 class="mat-subtitle-2 mb-2">
              {{ group.label }}
            </h4>
          }
          <ng-container *ngTemplateOutlet="metricTable; context: { group: group }"></ng-container>
        }
      }
    }
  }
</div>

<ng-template #metricTable let-group="group">
  @if (group | cellValueDisplay; as cellValue) {
    <div class="metric-table-container">
      @for (inputCol of cellValue.inputColumns; track inputCol) {
        <div
          class="mb-0 mr-1 value-container"
          [ngClass]="{
            'no-action': disableEdit,
            active: isValueDefHighlighted(inputCol, group),
          }"
          (contextmenu)="onContextMenu($event, inputCol, group)"
          (mouseover)="setHoverIndex(inputCol, group)"
          (mouseout)="setHoverIndex()"
        >
          @if (showLabel) {
            <div>
              <p class="label">{{ inputCol.label }}</p>
              <ul>
                @for (contextColValue of cellValue.contextColumns; track contextColValue) {
                  <li class="label">{{ contextColValue }}</li>
                }
              </ul>
            </div>
          }
          <div
            class="color-grey-900 value"
            (click)="selectDefaultAction()"
            [ngClass]="{ active: selectedValue === inputCol }"
          >
            @if (inputCol.value) {
              <p>
                <span
                  [innerHTML]="
                    inputCol.value
                      | numberMask: inputCol.type_details?.max_decimals : getUnit(inputCol) : true : false : true
                      | sanitizeHtml
                  "
                ></span>
                <ng-container
                  *ngTemplateOutlet="hoverOptions; context: { value: inputCol, singleLine: true, group: group }"
                >
                </ng-container>
              </p>
            } @else {
              <ng-container *ngTemplateOutlet="noValue; context: { value: inputCol }"></ng-container>
            }
          </div>
        </div>
      }
    </div>
  }
</ng-template>

<ng-template #hoverOptions let-valueSet="value" let-singleLine="singleLine" let-group="group">
  @if (valueSet?.value && hoverAction) {
    <div class="options" [ngClass]="{ 'pl-1': singleLine, multiline: !singleLine, active: selectedValue === valueSet }">
      @if (!multiOptions || valueSet.is_read_only) {
        <div class="fx-row copy-icon-container" (click)="copyValue(valueSet, $event)" data-testid="hover-copy-icon">
          <mat-icon class="icon-sm mr-1 copy-icon" svgIcon="copy"></mat-icon><span>{{ "copy" | translate }}</span>
        </div>
      } @else {
        <div (click)="onContextMenu($event, valueSet, group)" data-testid="value-context-menu">
          <mat-icon class="icon-sm mr-1" svgIcon="more"></mat-icon>
        </div>
      }
    </div>
  }
</ng-template>

<ng-template #noValue let-valueSet="value">
  @if (valueSet?.required === true && valueSet.type !== eValueDefinitionType.calculated) {
    <div class="color-error">
      {{ "Missing Value" | translate }}
    </div>
  }
  @if (valueSet?.required === false || valueSet.type === eValueDefinitionType.calculated) {
    <div>
      {{ "-" | translate }}
    </div>
  }
</ng-template>
