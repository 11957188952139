@if (choiceFormGroup) {
  <form [formGroup]="choiceFormGroup" [class]="'formfield choice-formfield ' + size" (libBeforeUnload)="updateField()">
    <lib-form-field-label [label]="label" [control]="valueFormControl"></lib-form-field-label>
    @switch (widgetType) {
      @case (eChoiceFieldWidgetType.single_select) {
        @if (allowAddOption) {
          <lib-auto-complete-input
            data-testid="autoCompleteInput"
            [placeholder]="'Click in the field to show available options or enter your own' | translate"
            [control]="choiceFormGroup.valuesControl"
            [hint]="hint"
            [options]="choicesList"
            [canAddNewOption]="allowAddOption"
            [minimalNumberOfCharToDisplayAutocomplete]="0"
            [restoreInputOnBlur]="true"
          >
          </lib-auto-complete-input>
        } @else {
          <lib-searchable-select-input
            data-testid="searchableSelect"
            [control]="choiceFormGroup.valuesControl"
            [options]="filteredChoiceList"
            [hint]="hint"
            (loadOptions)="filterChoices(choicesList, $event)"
          ></lib-searchable-select-input>
        }
      }
      @case (eChoiceFieldWidgetType.checkboxes) {
        <lib-metric-editor-checkbox-field
          data-testid="multiSelectCheckbox"
          [control]="choiceFormGroup.valuesControl"
          [options]="choicesList"
          [hint]="hint"
          #inputFieldRef
        ></lib-metric-editor-checkbox-field>
      }
      @case (eChoiceFieldWidgetType.multi_chip) {
        <lib-multi-select-chip-input
          data-testid="multiSelectChipInput"
          [control]="choiceFormGroup.valuesControl"
          [hint]="hint"
          [options]="choicesList"
          [forceControlValue]="true"
          [freeSolo]="allowAddOption"
          (addValue)="addValue($event)"
          #inputFieldRef
        ></lib-multi-select-chip-input>
        <mat-hint>
          <span>
            {{
              (allowAddOption
                ? "Type to search for available options, or to create a new option. New options appear in blue"
                : "Type to search for available options"
              ) | translate
            }}
          </span>
        </mat-hint>
      }
      @case (eChoiceFieldWidgetType.radio_buttons) {
        <lib-metric-editor-radio-field
          data-testid="choiceRadioSelect"
          [control]="choiceFormGroup.valuesControl"
          [options]="choicesList"
          [hint]="hint"
          #inputFieldRef
        ></lib-metric-editor-radio-field>
      }
    }
    @if (choiceFormGroup.additionalTextControl) {
      <div class="ml-4 mt-1">
        <lib-text-area-input
          [label]="explanationLabel"
          [control]="choiceFormGroup.additionalTextControl"
          [maxLength]="explanationMaxLength"
          (handleBlur)="choiceFormGroup.blurAdditionalTextControl()"
        ></lib-text-area-input>
      </div>
    }
  </form>
}
