@switch (typeDetails.widget_type) {
  @case (eChoiceWidgetType.single_select) {
    <lib-metric-structure-label
      [isTriggered]="isTriggered"
      [isTrigger]="isTrigger"
      [isInactive]="valueDefinition | isDeactivated"
      >{{ valueDefinition.label }}</lib-metric-structure-label
    >
    <mat-form-field data-testid="single-select" [ngClass]="{ 'deactivated-field': valueDefinition | isDeactivated }">
      <mat-select>
        @for (option of options$ | async; track option) {
          <mat-option [value]="option">
            {{ option }}
          </mat-option>
        }
      </mat-select>
      <mat-hint>{{ valueDefinition.hint }}</mat-hint>
    </mat-form-field>
  }
  @case (eChoiceWidgetType.multi_chip) {
    <lib-metric-structure-label
      [isTriggered]="isTriggered"
      [isTrigger]="isTrigger"
      [isInactive]="valueDefinition | isDeactivated"
      >{{ valueDefinition.label }}</lib-metric-structure-label
    >
    <div
      data-testid="multi-chip"
      class="fx-col w-100"
      [ngClass]="{ 'deactivated-field': valueDefinition | isDeactivated }"
    >
      <lib-multi-select-chip-input [control]="dummyControl" [hint]="valueDefinition.hint"></lib-multi-select-chip-input>
    </div>
  }
  @case (eChoiceWidgetType.radio_buttons) {
    <lib-metric-structure-label
      [isTriggered]="isTriggered"
      [isTrigger]="isTrigger"
      [isInactive]="valueDefinition | isDeactivated"
      >{{ valueDefinition.label }}</lib-metric-structure-label
    >
    <div
      data-testid="radio-btn"
      class="fx-col w-100"
      [ngClass]="{ 'deactivated-field': valueDefinition | isDeactivated }"
    >
      <mat-hint class="mb-2">{{ valueDefinition.hint }} </mat-hint>
      @for (option of options$ | async; track option) {
        <mat-radio-group color="primary">
          <mat-radio-button [value]="option" class="mat-body-2">
            {{ option }}
          </mat-radio-button>
        </mat-radio-group>
      }
    </div>
  }
  @case (eChoiceWidgetType.checkboxes) {
    <lib-metric-structure-label
      [isTriggered]="isTriggered"
      [isTrigger]="isTrigger"
      [isInactive]="valueDefinition | isDeactivated"
      >{{ valueDefinition.label }}</lib-metric-structure-label
    >
    <div
      data-testid="checkboxes"
      class="fx-col w-100"
      [ngClass]="{ 'deactivated-field': valueDefinition | isDeactivated }"
    >
      <mat-hint class="mb-2">{{ valueDefinition.hint }} </mat-hint>
      @for (option of options$ | async; track option) {
        <mat-checkbox color="primary" [value]="option" class="mat-body-2">
          {{ option }}
        </mat-checkbox>
      }
    </div>
  }
}
