@if (booleanFormGroup) {
  <form [formGroup]="booleanFormGroup" (libBeforeUnload)="updateField()">
    <div tabindex="0" libFocusState [focusStateControl]="valueFormControl" class="focus-container">
      <div
        data-testid="radioField"
        [class]="'formfield ' + size"
        [ngClass]="{ required: required, disabled: valueFormControl.disabled }"
      >
        <lib-form-field-label [label]="label" [control]="valueFormControl"></lib-form-field-label>
        <div class="formfield">
          <mat-hint class="pb-1">{{ hint }}</mat-hint>
          @if (booleanFormGroup.controls.value) {
            <lib-radio-buttons
              [control]="booleanFormGroup.controls.value"
              [choices]="options"
              [column]="true"
            ></lib-radio-buttons>
          }
        </div>
      </div>
      <div class="ml-4">
        @if (booleanFormGroup.displayAdditionalText?.show && booleanFormGroup.controls.additionalText) {
          <lib-text-area-input
            [label]="booleanFormGroup.displayAdditionalText?.label ?? ''"
            [control]="booleanFormGroup.controls.additionalText"
            [maxLength]="explanationMaxLength"
            (handleBlur)="booleanFormGroup.blurAdditionalTextControl()"
          ></lib-text-area-input>
        }
      </div>
    </div>
  </form>
}
