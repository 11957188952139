<div class="label {{ labelClass }}" [ngClass]="{ red: isTriggered }">
  @if (isTriggered) {
    <mat-icon class="icon-md" svgIcon="condition-trigger"></mat-icon>
  }
  <div class="fx-row">
    @if (isInactive) {
      <span class="mr-1 status-chip">{{ "Inactive" | translate }}</span>
    }
    <span [ngClass]="{ inactive: isInactive }">
      <ng-content></ng-content>
    </span>
  </div>
  @if (isTrigger) {
    <mat-icon class="icon-md ml-1 color-error" svgIcon="condition"></mat-icon>
  }
</div>
