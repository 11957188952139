<div class="panel-properties-content-header mh-4">
  <div class="panel-properties-content-header-title fx-row justify-space-between w-100">
    <div class="fx-row justify-center align-center">
      <mat-icon [svgIcon]="fieldHeaderDetails?.code || 'settings'" class="mr-2"></mat-icon>
      <h4 class="mat-subtitle-2">{{ fieldHeaderDetails?.name }}</h4>
    </div>
    @if (valueDefinition.id !== eValueDefinitionTemplateType.template) {
      <div class="fx-row justify-center align-center">
        @if (deactivationEnabled || (isAdmin && valueDefinition.published)) {
          @if (
            (valueDefinition | isDeactivated) ||
            (valueDefinition.hidden_by_taxonomy && !valueDefinition.ignore_taxonomies)
          ) {
            <button
              lib-button
              buttonType="stroked"
              [disabled]="(valueDefinitionGroup | isDeactivated) || formDisabled"
              (click)="activateField()"
            >
              {{ "Activate" | translate }}
            </button>
          } @else {
            <button
              lib-button
              buttonType="stroked"
              [disabled]="(valueDefinitionGroup | isDeactivated) || formDisabled"
              (click)="deactivateField()"
            >
              {{ "Deactivate" | translate }}
            </button>
          }
        }
        @if (valueDefinition | canDeleteValueDefinition: metric : isAdmin) {
          <button
            lib-button
            buttonType="icon"
            icon="trash"
            (click)="deleteField($event)"
            data-testid="deleteFieldButton"
            class="ml-2"
          ></button>
        }
      </div>
    }
  </div>
</div>

@if (dynamicFieldForm$ | async; as dynamicFieldForm) {
  <div class="panel-properties-content-container fx-col">
    <div #fieldsContainer class="fields-container pb-1">
      @if (
        (isRepeatableGroup$ | async) === true &&
        (contextFieldPermittedValueDefinitionTypes | includes: valueDefinition.type)
      ) {
        <div class="tip mt-2 mb-3 ml-1 mr-2">
          <div class="fx-row align-center">
            <mat-icon svgIcon="warning" class="tip-icon color-warning mr-2"></mat-icon>
          </div>
          <div data-testid="repeatableGroupTip" class="text content-text tip-text-warning mat-body color-grey-900 pl-3">
            {{
              "This field is part of a Repeatable Group. Define this field as Context or Input in order to configure the repeatable group for export in the Group Settings Panel."
                | translate
            }}
          </div>
        </div>
      }
      <ng-container *libFeatureFlag="{ featureFlags: ['metric_structure_deactivation_enabled'] }">
        @if (valueDefinition.end_fiscal_year_frequency_code) {
          <lib-info-banner
            [message]="
              'Field deactivated for the Fiscal Year {fiscalYear} onwards'
                | translate: { fiscalYear: valueDefinition.end_fiscal_year_frequency_code }
            "
            type="warning"
          ></lib-info-banner>
        }
      </ng-container>
      <form
        class="form no-gap"
        [formGroup]="dynamicFieldForm"
        [ngClass]="{
          disabled: formDisabled,
        }"
        data-testid="form"
      >
        @if (valueDefinition | isValueDefinitionOfType: [eValueDefinitionDisplayType.calculated]) {
          <div class="formfield tip large">
            <div class="fx-row align-center">
              <mat-icon svgIcon="info" class="tip-icon color-grey-800 mr-2"></mat-icon>
            </div>
            <div class="text tip-text tip-text-dark mat-body color-grey-900 pl-3">
              @if (valueDefinition | isValueDefinitionOfType: [eValueDefinitionDisplayType.calculated]) {
                {{ "Number calculation can execute simple formulas for number fields inside this metric." | translate }}
              }
            </div>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.label) {
          <div class="formfield large">
            <lib-text-input
              [label]="'Field label' | translate"
              [control]="dynamicFieldForm.propertiesControls.label"
            ></lib-text-input>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.shortLabel) {
          <div class="formfield large">
            <lib-text-input
              [label]="'Short label' | translate"
              [control]="dynamicFieldForm.propertiesControls.shortLabel"
              [hint]="'Overrides field label in exports' | translate"
              [maxCharacterCount]="70"
            ></lib-text-input>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.toggleRequired) {
          <div class="formfield large pb-1">
            <lib-slide-toggle
              [control]="dynamicFieldForm.propertiesControls.toggleRequired"
              [label]="'This field is required' | translate"
            >
            </lib-slide-toggle>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.toggleTextarea) {
          <div class="formfield large pt-1">
            <lib-slide-toggle
              [control]="dynamicFieldForm.propertiesControls.toggleTextarea"
              [label]="'Allow text on multiple lines' | translate"
              (sliderToggle)="disableDisplay()"
            >
            </lib-slide-toggle>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.toggleMultipleFiles) {
          <div class="formfield large pt-1">
            <lib-slide-toggle
              [control]="dynamicFieldForm.propertiesControls.toggleMultipleFiles"
              [label]="'Allow multiple files' | translate"
              (sliderToggle)="toggleMaxFiles($event)"
            >
            </lib-slide-toggle>
          </div>
        }
        @if (
          dynamicFieldForm.propertiesControls.maxFiles && dynamicFieldForm.propertiesControls.toggleMultipleFiles?.value
        ) {
          <div class="formfield medium ml-8">
            <lib-text-input
              [label]="'File attachment limit' | translate"
              [hint]="'Maximum 50' | translate"
              [control]="dynamicFieldForm.propertiesControls.maxFiles!"
              [messages]="filesLimitErrorMsgs"
              type="number"
            ></lib-text-input>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.placeholder) {
          <div class="formfield large">
            <lib-text-input
              [label]="'Field placeholder text' | translate"
              [control]="dynamicFieldForm.propertiesControls.placeholder"
            ></lib-text-input>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.hint) {
          <div class="formfield large">
            <lib-text-input
              [label]="'Field hint' | translate"
              [maxCharacterCount]="
                (valueDefinition | isValueDefinitionOfType: [eValueDefinitionDisplayType.text_rich]) ? 150 : 50
              "
              [control]="dynamicFieldForm.propertiesControls.hint"
            ></lib-text-input>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.characterLimit) {
          <div class="formfield medium newline">
            <lib-text-input
              [label]="'Character limit' | translate"
              [hint]="'0 for no limit' | translate"
              type="number"
              [control]="dynamicFieldForm.propertiesControls.characterLimit"
              [messages]="characterLimitErrorMsgs"
            ></lib-text-input>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.instruction) {
          <div class="formfield large required">
            <div class="rich-text-formfield">
              <lib-rich-text-input
                [label]="'Instruction' | translate"
                [control]="dynamicFieldForm.propertiesControls.instruction"
              ></lib-rich-text-input>
            </div>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.subtitle) {
          <div class="formfield large required">
            <lib-text-input
              [label]="'Subtitle' | translate"
              [hint]="'Required' | translate"
              [maxCharacterCount]="100"
              [control]="dynamicFieldForm.propertiesControls.subtitle"
            ></lib-text-input>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.dateFormat) {
          <div class="formfield medium required newline">
            <lib-select-input
              [label]="'Date format' | translate"
              [control]="dynamicFieldForm.propertiesControls.dateFormat"
              [options]="dateOptionsAnswers"
            >
            </lib-select-input>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.minDateRange) {
          <div class="formfield large newline">
            <lib-date-input
              class="formfield small"
              [label]="'Minimum Date Range' | translate"
              [control]="dynamicFieldForm.propertiesControls.minDateRange"
              [hint]="'Optional' | translate"
              [messages]="dateErrorMessages"
            >
            </lib-date-input>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.maxDateRange) {
          <div class="formfield large">
            <lib-date-input
              class="formfield small"
              [label]="'Maximum Date Range' | translate"
              [control]="dynamicFieldForm.propertiesControls.maxDateRange"
              [hint]="'Optional' | translate"
              [messages]="dateErrorMessages"
            >
            </lib-date-input>
          </div>
        }
        @if (
          valueDefinition
            | isValueDefinitionOfType: [eValueDefinitionDisplayType.boolean, eValueDefinitionDisplayType.choice]
        ) {
          <lib-divider class="large mt-4 ml-2 mr-2"></lib-divider>
          <div class="formfield large tag color-grey-800">{{ "Configuration" | translate }}</div>
        }
        @if (dynamicFieldForm.propertiesControls.labelTrue) {
          <div class="formfield large required">
            <lib-text-input
              [label]="'True Option' | translate"
              [control]="dynamicFieldForm.propertiesControls.labelTrue"
            ></lib-text-input>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.promptOnTrue) {
          <div class="formfield large">
            <lib-slide-toggle
              [label]="'Display explanation field for true' | translate"
              [control]="dynamicFieldForm.propertiesControls.promptOnTrue"
              (sliderToggle)="disableDisplay()"
            >
            </lib-slide-toggle>
          </div>
        }
        @if (
          dynamicFieldForm.propertiesControls.promptOnTrue?.value && dynamicFieldForm.propertiesControls.labelPromptTrue
        ) {
          <div class="large required ml-8">
            @if (dynamicFieldForm.propertiesControls.requiredOnTrue) {
              <lib-slide-toggle
                class="formfield"
                [label]="'True explanation field is required' | translate"
                data-testid="trueExplanationRequired"
                [control]="dynamicFieldForm.propertiesControls.requiredOnTrue"
              >
              </lib-slide-toggle>
            }
            <lib-text-input
              [label]="'True explanation field label' | translate"
              [hint]="'This will appear as a textbox with a 5000 characters limit' | translate"
              [control]="dynamicFieldForm.propertiesControls.labelPromptTrue"
              class="formfield"
            ></lib-text-input>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.labelFalse) {
          <div class="formfield large required">
            <lib-text-input
              [label]="'False Option' | translate"
              [control]="dynamicFieldForm.propertiesControls.labelFalse"
            ></lib-text-input>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.promptOnFalse) {
          <lib-slide-toggle
            class="formfield large"
            [label]="'Display explanation field for false' | translate"
            [control]="dynamicFieldForm.propertiesControls.promptOnFalse"
            (sliderToggle)="disableDisplay()"
          >
          </lib-slide-toggle>
        }
        @if (
          dynamicFieldForm.propertiesControls.promptOnFalse?.value &&
          dynamicFieldForm.propertiesControls.labelPromptFalse
        ) {
          <div class="large required ml-8">
            @if (dynamicFieldForm.propertiesControls.requiredOnFalse) {
              <lib-slide-toggle
                class="formfield"
                [label]="'False explanation field is required' | translate"
                data-testid="falseExplanationRequired"
                [control]="dynamicFieldForm.propertiesControls.requiredOnFalse"
              >
              </lib-slide-toggle>
            }
            <lib-text-input
              [label]="'False explanation field label' | translate"
              [hint]="'This will appear as a textbox with a 5000 characters limit' | translate"
              [control]="dynamicFieldForm.propertiesControls.labelPromptFalse"
              class="formfield"
            ></lib-text-input>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.displayUrl || dynamicFieldForm.propertiesControls.attachmentOptional) {
          <div class="formfield label large">
            <span class="title mb-1">{{ "URL" | translate }}</span>
            @if (dynamicFieldForm.propertiesControls.displayUrl) {
              <div>
                <lib-slide-toggle
                  class="pb-1"
                  [label]="'Display URL field' | translate"
                  [control]="dynamicFieldForm.propertiesControls.displayUrl"
                  (sliderToggle)="disableDisplay()"
                >
                </lib-slide-toggle>
                @if (
                  dynamicFieldForm.propertiesControls.displayUrl.value &&
                  dynamicFieldForm.propertiesControls.urlRequired
                ) {
                  <div class="large required">
                    <lib-slide-toggle
                      class="pb-1"
                      [label]="'URL is required' | translate"
                      [control]="dynamicFieldForm.propertiesControls.urlRequired"
                    >
                    </lib-slide-toggle>
                  </div>
                }
              </div>
            }
            @if (
              dynamicFieldForm.propertiesControls.displayUrl?.value &&
              dynamicFieldForm.propertiesControls.attachmentOptional
            ) {
              <div>
                <lib-slide-toggle
                  class="pb-1"
                  [label]="'Allow URL input as actual file' | translate"
                  [control]="dynamicFieldForm.propertiesControls.attachmentOptional"
                  (sliderToggle)="handleAllowUrlAsFile()"
                >
                </lib-slide-toggle>
                @if (dynamicFieldForm.propertiesControls.attachmentOptional.value) {
                  <lib-slide-toggle
                    class="pb-1"
                    [label]="'Allow attaching document' | translate"
                    [control]="dynamicFieldForm.propertiesControls.attachmentEnabled"
                    (sliderToggle)="handleAllowAttachingDocument()"
                  >
                  </lib-slide-toggle>
                }
              </div>
            }
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.displayPageNumber) {
          <div class="formfield label large">
            <span class="mb-1">{{ "Page number" | translate }} </span>
            <lib-slide-toggle
              class="pb-1"
              [label]="'Display page number' | translate"
              [control]="dynamicFieldForm.propertiesControls.displayPageNumber"
              (sliderToggle)="disableDisplay()"
            >
            </lib-slide-toggle>
            @if (
              dynamicFieldForm.propertiesControls.displayPageNumber.value &&
              dynamicFieldForm.propertiesControls.pageNumberRequired
            ) {
              <div>
                <lib-slide-toggle
                  class="pb-1"
                  [label]="'Page number is required' | translate"
                  [control]="dynamicFieldForm.propertiesControls.pageNumberRequired"
                >
                </lib-slide-toggle>
              </div>
            }
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.displayFileExplanation) {
          <div class="formfield label large">
            <span class="mb-1">{{ "File explanation" | translate }}</span>
            <lib-slide-toggle
              class="pb-1"
              [label]="'Display explanation field for file attachment' | translate"
              [control]="dynamicFieldForm.propertiesControls.displayFileExplanation"
              (sliderToggle)="disableDisplay()"
            >
            </lib-slide-toggle>
            @if (dynamicFieldForm.propertiesControls.displayFileExplanation.value) {
              <div>
                <lib-slide-toggle
                  class="pb-1"
                  [label]="'File explanation field is required' | translate"
                  [control]="dynamicFieldForm.propertiesControls.explanationFileRequired"
                >
                </lib-slide-toggle>
                <lib-text-input
                  [label]="'File explanation field label' | translate"
                  [hint]="'This will appear as a textbox with a 5000 characters limit' | translate"
                  [control]="dynamicFieldForm.propertiesControls.explanationFileLabel"
                  class="formfield"
                ></lib-text-input>
              </div>
            }
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.unitFamily) {
          <div class="formfield large required">
            <lib-dropdown-input
              [filter]="true"
              [filterBy]="'title'"
              [optionValue]="'id'"
              [optionLabel]="'title'"
              [label]="'Unit family' | translate"
              [control]="dynamicFieldForm.propertiesControls.unitFamily"
              [options]="(unitFamilies$ | async) ?? []"
            ></lib-dropdown-input>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.units) {
          <div class="formfield large required">
            <lib-dropdown-input
              [filter]="true"
              [filterBy]="'title'"
              [optionValue]="'id'"
              [optionLabel]="'title'"
              [label]="'Unit default' | translate"
              [control]="dynamicFieldForm.propertiesControls.units"
              [options]="(unitDefaults$ | async) ?? []"
            ></lib-dropdown-input>
          </div>
        }
        @if (
          dynamicFieldForm.propertiesControls.decimals &&
          (dynamicFieldForm.propertiesControls.type?.value === eValueDefinitionDisplayType.number ||
            (valueDefinition | isValueDefinitionOfType: [eValueDefinitionDisplayType.calculated]))
        ) {
          <div class="formfield medium required">
            <lib-select-input
              [label]="'Decimals' | translate"
              [control]="dynamicFieldForm.propertiesControls.decimals"
              [options]="numericDecimalOptions"
            ></lib-select-input>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.mandatory) {
          <div class="formfield large">
            <lib-slide-toggle
              [control]="dynamicFieldForm.propertiesControls.mandatory"
              [label]="'Calculate when all values are entered' | translate"
              data-testid="mandatoryToggle"
            >
            </lib-slide-toggle>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.minimum) {
          <div class="formfield medium newline">
            <lib-text-input
              [label]="'Minimum value' | translate"
              type="number"
              [control]="dynamicFieldForm.propertiesControls.minimum"
              [messages]="numberMinMaxErrorMsgs"
            ></lib-text-input>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.maximum) {
          <div class="formfield medium">
            <lib-text-input
              [label]="'Maximum value' | translate"
              type="number"
              [control]="dynamicFieldForm.propertiesControls.maximum"
              [messages]="numberMinMaxErrorMsgs"
            ></lib-text-input>
          </div>
        }
        @if (valueDefinition | isValueDefinitionOfType: [eValueDefinitionDisplayType.number]) {
          <div *libFeatureFlag="{ featureFlags: ['frequency_enabled'] }" class="formfield large newline">
            <lib-info-banner
              [type]="'info'"
              [message]="
                'You can update the data collection frequency for this field using the Frequency panel.' | translate
              "
            ></lib-info-banner>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.variables) {
          <div class="formfield large required">
            <lib-metric-structure-calculated-variables
              [variablesFormControl]="dynamicFieldForm.propertiesControls.variables"
              [metric]="metric"
              [valueDefinition]="valueDefinition"
              [units]="(allUnits$ | async) ?? []"
              [sourceConfiguration]="sourceConfiguration"
            ></lib-metric-structure-calculated-variables>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.formula) {
          <div class="formfield large required">
            <lib-text-input
              [label]="'Formula' | translate"
              [control]="dynamicFieldForm.propertiesControls.formula"
              [messages]="numberMinMaxErrorMsgs"
            ></lib-text-input>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.fileSize) {
          <lib-divider class="large mt-4 ml-2 mr-2"></lib-divider>
          <div class="formfield medium newline">
            <lib-text-input
              [label]="'File size limit per attachment' | translate"
              [control]="dynamicFieldForm.propertiesControls.fileSize"
              [hint]="'In megabytes' | translate"
              [suffix]="'MB' | translate"
            ></lib-text-input>
          </div>
        }
        @if (dynamicFieldForm.propertiesControls.document && dynamicFieldForm.propertiesControls.displayName) {
          <lib-metric-structure-document-field-properties
            class="large"
            [linkedDocumentControl]="dynamicFieldForm.propertiesControls.document!"
            [documentDisplayControl]="dynamicFieldForm.propertiesControls.displayName!"
            [valueDefinition]="valueDefinition"
            (updateResource)="updateDocResource($event)"
          ></lib-metric-structure-document-field-properties>
        }
        @if (valueDefinition | isValueDefinitionOfType: [eValueDefinitionDisplayType.choice]) {
          <lib-metric-structure-choice-field-properties
            class="large"
            [metric]="metric"
            [valueDefinition]="valueDefinition"
          >
          </lib-metric-structure-choice-field-properties>
        }
        @if (
          dynamicFieldForm.propertiesControls.type && isConsolidationEnabled(sourceConfiguration) && !isThirdParty()
        ) {
          <lib-divider class="large mt-4 ml-2 mr-2"></lib-divider>
          <div class="formfield large tag color-grey-800">
            {{ "Consolidation - Reference & Custom Metrics" | translate }}
          </div>
          @if ((isRepeatableGroup$ | async) === true) {
            <lib-info-banner
              class="large"
              [type]="'warning'"
              [message]="'Cannot consolidate this number field as it is part of a repeatable group' | translate"
            ></lib-info-banner>
          } @else {
            <div class="formfield large required">
              <lib-select-input
                [label]="'Consolidation rule' | translate"
                [control]="dynamicFieldForm.propertiesControls.consolidationRule"
                [options]="consolidationRuleOptions"
                (selectionChangeEvent)="setConsolidationOptions($event)"
              ></lib-select-input>
            </div>
            @if (dynamicFieldForm.propertiesControls.consolidationRule?.value !== eConsolidationRules.manual) {
              <div class="formfield large required">
                <lib-select-input
                  [label]="'Consolidation trigger' | translate"
                  [control]="dynamicFieldForm.propertiesControls.consolidationTrigger"
                  [options]="getConsolidationTriggerOptions()"
                  (selectionChangeEvent)="checkConsolidationParamsChanged()"
                ></lib-select-input>
              </div>
              @if (isSourceConfigurationInHierarchy(sourceConfiguration) && isBypassConsolidationEnabled()) {
                <div class="formfield large required">
                  <lib-multi-select-dropdown
                    [label]="'Consolidation bypass levels' | translate"
                    [control]="dynamicFieldForm.propertiesControls.bypassConsolidationLevels"
                    [options]="consolidationBypassLevelOptions"
                    (selectChange)="handleBypassConsolidationLevelsSelect($event)"
                  ></lib-multi-select-dropdown>
                  @if (allLevelsSelectedMessage) {
                    <div>
                      {{ allLevelsSelectedMessage }}
                    </div>
                  }
                </div>
              }
            }
          }
        }
        @if (
          valueDefinition
            | isValueDefinitionOfType
              : [
                  eValueDefinitionDisplayType.tip,
                  eValueDefinitionDisplayType.text_area,
                  eValueDefinitionDisplayType.text_simple,
                  eValueDefinitionDisplayType.number,
                  eValueDefinitionDisplayType.choice,
                  eValueDefinitionDisplayType.choice_multiple,
                  eValueDefinitionDisplayType.choice_searchable,
                  eValueDefinitionDisplayType.choice_radio,
                  eValueDefinitionDisplayType.calculated,
                  eValueDefinitionDisplayType.document,
                  eValueDefinitionDisplayType.date,
                ]
        ) {
          @if (
            areConsolidationParamsChanged ||
            (valueDefinition | isValueDefinitionOfType: [eValueDefinitionDisplayType.calculated])
          ) {
            <div class="large mt-2">
              <lib-info-banner [type]="'warning'" [message]="calculationChangedMsg"></lib-info-banner>
            </div>
          }
          <lib-divider class="large mt-4 ml-2 mr-2"></lib-divider>
          <div class="formfield large tag color-grey-800">{{ "Display options" | translate }}</div>
          @if (dynamicFieldForm.propertiesControls.displaySize) {
            <div class="formfield large required">
              <lib-select-input
                [label]="'Display size' | translate"
                [control]="dynamicFieldForm.propertiesControls.displaySize!"
                [options]="valueDefinitionTypeSizes[valueDefinitionDisplayType]"
                [hint]="'This affects the display size of the field' | translate"
                [messages]="displaySizeErrorMsgs"
              >
              </lib-select-input>
            </div>
          }
          @if (dynamicFieldForm.propertiesControls.toggleNewLine) {
            <div class="formfield large">
              <lib-slide-toggle
                [control]="dynamicFieldForm.propertiesControls.toggleNewLine"
                [label]="'Put this field on a new line' | translate"
                data-testid="newlineToggle"
              >
              </lib-slide-toggle>
            </div>
          }
          @if (dynamicFieldForm.propertiesControls.instructionCard) {
            <div class="formfield large">
              @for (item of getTipDisplayOptions(); track item) {
                <a
                  class="card card-condensed mb-1 tip-card"
                  [ngClass]="{
                    active: dynamicFieldForm.propertiesControls.instructionCard.value === item,
                    disabled: dynamicFieldForm.propertiesControls.instructionCard.disabled,
                  }"
                  (click)="selectTipDisplayOption(item)"
                >
                  <div class="tip">
                    @if (item.icon) {
                      <div class="fx-row align-center">
                        <mat-icon [svgIcon]="item.icon || ''" class="tip-icon mr-2" [class]="'color-' + item.image">
                        </mat-icon>
                      </div>
                    }
                    <div class="text mat-body color-grey-900 pl-3 tip-text" [class]="'tip-text-' + item.image">
                      {{ item.title }}
                    </div>
                  </div>
                </a>
              }
            </div>
          }
        }
      </form>
    </div>
    <div>
      <lib-divider class="pb-4"></lib-divider>
      <div class="form-submit">
        @if (valueDefinition.id === eValueDefinitionTemplateType.template) {
          <a
            lib-button
            buttonType="success"
            [disabled]="!!dynamicFieldForm.invalid"
            [isLoading]="!!(updating$ | async)"
            [label]="'Add' | translate"
            (click)="addField()"
          >
          </a>
        } @else {
          <a
            lib-button
            buttonType="success"
            [disabled]="!!(dynamicFieldForm.pristine || dynamicFieldForm.invalid)"
            [isLoading]="!!(updating$ | async)"
            [label]="'Save' | translate"
            (click)="saveField()"
          >
          </a>
        }
        <a lib-button buttonType="link" [label]="'Cancel' | translate" (click)="closeProperties()"></a>
      </div>
    </div>
  </div>
}
