<div class="panel-dummy" [ngClass]="{ loaded: loaded }">
  <header class="pt-4 pl-8 pr-8 w-100">
    <div class="metric-properties">
      <div class="rounded-corners bkg-blink w-10 mb-1">&nbsp;</div>
      <h3 class="mb-2 rounded-corners bkg-blink w-50">&nbsp;</h3>
      <div class="updated rounded-corners bkg-blink w-20 mb-4">&nbsp;</div>
      <div class="bkg-grey-50 pt-2 pb-2 pl-4 pr-4 w-100">&nbsp;</div>
    </div>
  </header>
  <div class="content">
    <form class="form metric-editor pl-6 pr-8 pt-6 pb-4">
      <div class="formfield readonly large">
        <div class="label rounded-corners bkg-blink w-50">&nbsp;</div>
        <mat-form-field>
          <input matInput />
        </mat-form-field>
      </div>
      <div class="formfield readonly large">
        <div class="label rounded-corners bkg-blink w-50">&nbsp;</div>
        <mat-form-field>
          <input matInput />
        </mat-form-field>
      </div>
    </form>
  </div>
</div>

<fieldset [libDeactivableMetricStructureEditor]="!!(isCreatingField$ | async)" data-testid="metric-structure-header">
  <header class="pt-4 pl-8 pr-8 w-100">
    <div class="metric-properties">
      <div class="color-grey-800">{{ metric.code }}</div>
      <h3 class="mb-2">{{ metric.description }}</h3>
      <div class="updated color-grey-800 mb-4">
        @if (updating$ | async) {
          {{ "Updating..." | translate }}
        } @else {
          {{ "Updated {timeAgo}" | translate: { timeAgo: (metric.updated | timeAgo) } }}
        }
      </div>
      <lib-divider class="bkg-grey-600"></lib-divider>
      <div
        class="overlay"
        [ngClass]="{ active: selectedItem === metric }"
        (click)="onSelectedItemChange(metric)"
        data-testid="header-overlay"
      ></div>
    </div>
    <lib-metric-structure-open-requests [metric]="metric"></lib-metric-structure-open-requests>
  </header>

  <div class="content">
    <form class="form-structure h-100" [ngClass]="{ 'with-groups': groupCount > 1 }">
      @if (hasNoGroupWithFields) {
        <div class="pl-4 h-100">
          <div class="form-empty-content h-100 fx-row justify-center">
            <lib-empty-results [displayData]="emptyResults"></lib-empty-results>
          </div>
        </div>
      } @else {
        @if (showCoreAndCustomizableSections) {
          <lib-metric-structure-reference-v2-sections
            [metric]="metric"
            [groupsOrTables]="groupsOrTables"
            [selectedItem]="selectedItem"
          ></lib-metric-structure-reference-v2-sections>
        } @else {
          @for (
            valueDefinitionGroupOrTable of groupsOrTables;
            track trackId(i, valueDefinitionGroupOrTable);
            let i = $index
          ) {
            @if (!valueDefinitionGroupOrTable.table_id) {
              <lib-metric-structure-group-form
                [valueDefinitionGroup]="$any(valueDefinitionGroupOrTable)"
                [index]="i"
                [canMoveDown]="i < groupsOrTables.length - 1"
                [selectedItemId]="selectedItem?.id ?? ''"
                [canMoveUp]="i > canMoveUpLimit"
                [class]="'form-group-wrapper form-group-wrapper-' + $any(valueDefinitionGroupOrTable).indent"
                [metric]="metric"
              ></lib-metric-structure-group-form>
            }
            @if (valueDefinitionGroupOrTable.table_id) {
              <lib-metric-structure-table
                [canMoveDown]="i < groupsOrTables.length - 1"
                [canMoveUp]="i > canMoveUpLimit"
                [index]="i"
                [metricTableGroup]="$any(valueDefinitionGroupOrTable)"
                [metric]="metric"
                [isActive]="selectedItem?.id === valueDefinitionGroupOrTable.table_id"
                class="form-group-wrapper"
              ></lib-metric-structure-table>
            }
          }
        }
      }
    </form>
  </div>
</fieldset>
