<section class="section section-dynamic" [ngClass]="{ loaded: loaded }">
  <div class="section-header">
    <h4 class="mat-subtitle-2 color-grey-900">{{ title }}</h4>
  </div>
  <div class="section-content" [ngClass]="{ loaded: loaded }">
    <div class="collection collection-dummies" data-testid="dummy-container">
      @for (dummy of dummies; let index = $index; track index) {
        <lib-dummy [presentation]="presentation"></lib-dummy>
      }
    </div>
    <div class="collection collection-items">
      @if (!itemCollection || itemCollection.items.length === 0) {
        <lib-card-empty data-testId="empty-card" [presentation]="presentation"></lib-card-empty>
      }
      @for (item of itemCollection?.items; track item.id) {
        <lib-card
          data-testId="template-card"
          [item]="item"
          [presentation]="presentation"
          [path]="item | templateLink"
          [pathPosition]="linkPosition"
          [limitedCardSize]="true"
        >
        </lib-card>
      }
    </div>
  </div>
</section>
