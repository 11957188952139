<lib-divider class="large mt-4 mb-2"></lib-divider>
<div class="formfield large tag color-grey-800">{{ "Variables" | translate }}</div>

<div class="formfield large" data-testid="variables-container">
  <div class="variables-item-dummies bkg-white" [ngClass]="{ loaded: variablesLoaded }">
    @for (i of [1, 2]; track i) {
      <div class="mt-2">
        <div class="card card-variable card-noborders">
          <div class="mat-caption rounded-corners mb-1 bkg-blink w-33">&nbsp;</div>
          <div class="body-2 rounded-corners mb-1 bkg-blink w-100">&nbsp;</div>
          <div class="bkg-blink rounded-corners w-25">&nbsp;</div>
        </div>
      </div>
    }
  </div>

  @if (variables?.length) {
    <div class="fx-col mt-2">
      @for (variable of variables; track variable) {
        <div class="fx-row mb-1">
          <div class="mat-small color-grey-900 fx-row align-center mr-2">
            {{ variable.id }}
          </div>
          <a
            class="card card-variable card-noborders w-100 fx-col"
            [ngClass]="{ disabled: calcVariablesFormControl?.disabled }"
            (click)="launchAddVariableDialog(variable)"
          >
            <div class="fx-row justify-space-between">
              <div class="color-grey-800">{{ variable.subtitle }}</div>
              <a role="button" aria-label="Delete variable" (click)="deleteVariable(variable)">
                <mat-icon svgIcon="trash" class="icon-md"> </mat-icon
              ></a>
            </div>
            <div class="color-primary-900 text-clamp text-clamp-1 mat-body-2 mb-1 pr-2" [matTooltip]="variable.title">
              <span>{{ variable.title }}</span>
              @if (variable?.deactivated) {
                <span class="ml-1">{{
                  "(Inactive from Fiscal Year {fiscalYear})"
                    | translate
                      : {
                          fiscalYear: (inactiveValueDefinitions | filter: ["id", variable.item.value_definition_id])[0]
                            .end_fiscal_year_frequency_code,
                        }
                }}</span>
              }
            </div>
            <div class="color-grey-800">{{ variable?.text }}</div>
          </a>
        </div>
      }
    </div>
  }

  <div class="mt-3">
    <a
      role="button"
      aria-label="Add variables"
      (click)="launchAddVariableDialog()"
      class="ml-4 btn p-0"
      [ngClass]="{ disabled: calcVariablesFormControl?.disabled }"
    >
      <mat-icon svgIcon="add" class="icon-md"></mat-icon>
      <div class="ml-1">{{ "Add variables" | translate }}</div>
    </a>
  </div>
</div>
