<div class="field-position-relative">
  <div
    [class]="'field-focus ' + (valueFormControl.valueRef | valueDefinitionFieldSize)"
    [libIsActiveField]="focusedField$ | async"
    [focusFieldUniqueId]="focusFieldUniqueId$ | async"
    [value]="valueFormControl.valueRef"
    [focusId]="focusId"
    [ngClass]="{
      focused: isFocusable && isFocusEnabled,
      'has-comments':
        this.valueFormControl.valueRef.unresolved_regular_comments_and_replies ||
        !!this.valueFormControl.valueRef.unresolved_rejection_comments_and_replies,
    }"
    (mousedown)="selectFieldForFocus(valueFormControl.valueRef, valueGroup)"
    data-testid="field-focus"
  >
    @switch (valueDefinitionDisplayType) {
      @case (eValueDefinitionDisplayType.subtitle) {
        <lib-metric-editor-subtitle [valueFormControl]="valueFormControl"></lib-metric-editor-subtitle>
      }
      @case (eValueDefinitionDisplayType.tip) {
        <lib-metric-editor-tip [valueFormControl]="valueFormControl"></lib-metric-editor-tip>
      }
      @case (eValueDefinitionDisplayType.text_simple) {
        <lib-metric-editor-text-field
          [valueFormControl]="valueFormControl"
          #valueFieldRef
        ></lib-metric-editor-text-field>
      }
      @case (eValueDefinitionDisplayType.text_area) {
        <lib-metric-editor-text-area-field [valueFormControl]="valueFormControl"></lib-metric-editor-text-area-field>
      }
      @case (eValueDefinitionDisplayType.text_rich) {
        <lib-metric-editor-rich-text
          (editorBlur)="onBlur()"
          [valueFormControl]="valueFormControl"
        ></lib-metric-editor-rich-text>
      }
      @case (eValueDefinitionDisplayType.document) {
        <lib-metric-editor-document-link
          [valueFormControl]="valueFormControl"
          [documentContext]="documentContext"
        ></lib-metric-editor-document-link>
      }
      @case (eValueDefinitionDisplayType.file_v2) {
        <lib-metric-editor-file-attachment-v2
          [valueFormControl]="valueFormControl"
          [documentContext]="documentContext"
        ></lib-metric-editor-file-attachment-v2>
      }
      @case (eValueDefinitionDisplayType.boolean) {
        <lib-metric-editor-boolean [valueFormControl]="valueFormControl"></lib-metric-editor-boolean>
      }
      @case (eValueDefinitionDisplayType.date) {
        <lib-metric-editor-date-field [valueFormControl]="valueFormControl"></lib-metric-editor-date-field>
      }
      @case (eValueDefinitionDisplayType.metric) {
        <lib-metric-editor-metric-link
          [valueFormControl]="valueFormControl"
          (metricLinkEdit)="metricLinkEdit.emit($event)"
        >
        </lib-metric-editor-metric-link>
      }
      @case (
        [
          eValueDefinitionDisplayType.choice,
          eValueDefinitionDisplayType.choice_multiple,
          eValueDefinitionDisplayType.choice_searchable,
          eValueDefinitionDisplayType.choice_radio,
        ] | multiSwitchCase: valueDefinitionDisplayType
      ) {
        <lib-metric-editor-choice-field
          [valueFormControl]="valueFormControl"
          [indicator]="indicator"
          [isPublic]="documentContext.itemType === eItemType.public_data_requests_request"
          [integrationType]="integrationType"
        ></lib-metric-editor-choice-field>
      }
      @case (
        [eValueDefinitionDisplayType.number, eValueDefinitionDisplayType.calculated]
          | multiSwitchCase: valueDefinitionDisplayType
      ) {
        <lib-metric-editor-numeric-field
          [valueFormControl]="valueFormControl"
          [indicatorId]="indicatorId"
          [vgsetId]="vgsetId"
          [groupId]="valueGroup.id"
          [itemType]="documentContext.itemType"
          [sourceConfiguration]="sourceConfiguration"
        ></lib-metric-editor-numeric-field>
      }
    }

    @if (
      valueFormControl.valueRef.unresolved_regular_comments_and_replies ||
      !!valueFormControl.valueRef.unresolved_rejection_comments_and_replies
    ) {
      <div
        lib-field-comment-counter
        class="comments-count"
        [unresolved_regular_comments_and_replies]="valueFormControl.valueRef.unresolved_regular_comments_and_replies"
        [unresolved_rejection_comments_and_replies]="
          valueFormControl.valueRef.unresolved_rejection_comments_and_replies
        "
        (actionSelected)="selectFieldForFocus(valueFormControl.valueRef, valueGroup, $event)"
      ></div>
    }
    @if (displayFieldActions) {
      <div
        lib-field-actions
        data-testid="fieldActions"
        [valueDefinitionType]="valueFormControl.valueRef.type"
        [unresolvedRejectionCommentsAndReplies]="valueFormControl.valueRef.unresolved_rejection_comments_and_replies"
        [valueGroupSetStatus]="valueGroupSetStatus"
        [dataRequestSourceStatus]="dataRequestSourceStatus"
        [collaboratorResponsibility]="collaboratorResponsibility"
        (actionSelected)="selectFieldForFocus(valueFormControl.valueRef, valueGroup, $event)"
      ></div>
    }
  </div>
</div>
