import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import {
  ApiResponse,
  CONTEXT_FIELD_PERMITTED_VALUE_DEFINITION_TYPES,
  EmptyResults,
  Metric,
  MetricCategory,
  ValueDefinition,
  ValueDefinitionGroup,
} from '../../../../../models';
import { TranslateService } from '../../../../../services/common';
import { MetricApiService } from '../../../../../services/types';
import { MetricStructureStateService } from '../../../../services/metric-structure-state.service';
import { ErrorManagerService } from '../../../../../error-manager';
import { FeatureFlagService } from '../../../../../feature-flag';

@Component({
  selector: 'lib-metric-structure-repeatable-group-properties-tab',
  templateUrl: './metric-structure-repeatable-group-properties-tab.component.html',
  styleUrls: ['./metric-structure-repeatable-group-properties-tab.component.scss'],
})
export class MetricStructureRepeatableGroupPropertiesTabComponent implements OnInit, OnChanges {
  @Input({ required: true }) valueDefinitionGroupId!: string;
  @Input({ required: true }) metric!: Metric;

  public enableRefMetricsV2: boolean = false;
  public xbrlExportEnabled: boolean = false;
  valueDefinitionGroup?: ValueDefinitionGroup;
  valueDefinitions: ValueDefinition[] = [];
  singleInput: boolean = false;
  isMetricUpdating$?: Observable<boolean | undefined>;

  public readonly eMetricCategory = MetricCategory;
  readonly contextSwitchDisabledMsg = this.translateService.instant('Cannot define all fields as Context');
  readonly noFieldsInTheGroup: EmptyResults = {
    title: this.translateService.instant(`No fields available`),
    subtitle: this.translateService.instant('There are no fields available to define as Context.'),
    image: 'laptop-sad',
  };

  constructor(
    private featureFlagService: FeatureFlagService,
    private metricsService: MetricApiService,
    private metricStructureService: MetricStructureStateService,
    private errorManagerService: ErrorManagerService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.isMetricUpdating$ = this.metricStructureService.isMetricUpdating$;
    this.enableRefMetricsV2 = this.featureFlagService.areAnyFeatureFlagsEnabled(['enable_ref_metrics_v2']);
    this.xbrlExportEnabled = this.featureFlagService.areAnyFeatureFlagsEnabled(['xbrl_export_enabled']);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.metric.currentValue) {
      this.valueDefinitionGroup = this.metric.value_definition_groups?.find(
        (valueDefinition) => valueDefinition.id == this.valueDefinitionGroupId,
      );
      if (this.valueDefinitionGroup?.value_definitions?.length) {
        this.valueDefinitions = this.valueDefinitionGroup.value_definitions.filter((valueDefinition) =>
          CONTEXT_FIELD_PERMITTED_VALUE_DEFINITION_TYPES.includes(valueDefinition.type),
        );
        this.singleInput = this.valueDefinitions.length <= 1;
      }
    }
  }

  private setUpdating(updating: boolean): void {
    this.metricStructureService.updateIsMetricUpdating(updating);
  }

  updateContextField(checked: boolean, valueDefinition: ValueDefinition) {
    this.setUpdating(true);
    valueDefinition.context = checked;
    const payload = this.metricStructureService.getFieldPayload(valueDefinition, true);
    this.metricsService
      .updateField(this.metric.id, valueDefinition.value_definition_group_id, valueDefinition.id, payload)
      .pipe(finalize(() => this.setUpdating(false)))
      .subscribe({
        next: (res: ApiResponse<Metric>) => {
          this.metricStructureService.updateMetric(res.data);
        },
        error: (errorResponse: unknown) => {
          try {
            this.metricStructureService.handleUpdateValidationErrors(errorResponse as HttpErrorResponse);
          } catch (_) {
            this.errorManagerService.handleError(errorResponse as HttpErrorResponse);
          }
        },
      });
  }
}
