<lib-item-card
  [item]="item"
  [menuItems]="menuItems"
  [disabled]="disabled"
  [extraClasses]="{ checked: selected }"
  (menuAction)="menuAction.emit($event)"
>
  <ng-template #action let-item>
    <p-checkbox
      [(ngModel)]="selected"
      [binary]="true"
      [disabled]="disabled"
      (onChange)="selectChange.emit({ item: item, checked: $event.checked })"
    />
  </ng-template>
  <ng-template #extraContent let-item>
    @if (extraContentTmpl) {
      <ng-container
        [ngTemplateOutlet]="extraContentTmpl"
        [ngTemplateOutletContext]="{ $implicit: item }"
      ></ng-container>
    }
  </ng-template>
</lib-item-card>
