import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() ariaLabel: string = '';
  @Input() diameter: number = 30;
  @Input() strokeWidth?: number;
}
