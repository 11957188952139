import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'lib-tree-list-select',
  templateUrl: './tree-list-select.component.html',
  styleUrl: './tree-list-select.component.scss',
})
export class TreeListSelectComponent {
  @Input() options: TreeNode[] = [];
  @Input() filterOutTopLevelParents: boolean = true;
  @Input() defaultOptions: TreeNode[] = [];
  @Output() handleTreeListSelectionChange = new EventEmitter<TreeNode[]>();

  onNodeSelect(event: TreeNode | TreeNode[] | null) {
    if (!event) {
      return;
    }

    let treeNodeSelectionToEmit: TreeNode[] = event instanceof Array ? [...event] : [{ ...event }];

    if (this.filterOutTopLevelParents) {
      // We assert that top level parents have a key length of 1 e.g. "1", "2", "3" and children of those
      // nodes will have a key length greater than 1, e.g. "1-0", "1-1" etc
      treeNodeSelectionToEmit = treeNodeSelectionToEmit.filter((treeNode) => {
        const treeNodeKey: string = treeNode.key ?? '0';
        return treeNodeKey.length > 1;
      });
    }

    this.handleTreeListSelectionChange.emit(treeNodeSelectionToEmit);
  }
}
