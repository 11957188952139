<lib-dialog-title
  [title]="title"
  (closeEvent)="close()"
  [error]="error"
  [isOfflineDialog]="isOfflineDialog"
></lib-dialog-title>

<div mat-dialog-content class="pb-1">
  <div class="warning-message-container">
    @if (!isOfflineDialog) {
      <div class="warning-icon-container">
        <mat-icon class="warning-icon" svgIcon="warning"></mat-icon>
      </div>
    }
    @if (body) {
      <div class="warning-message" [innerHTML]="body | sanitizeHtml"></div>
    }
  </div>

  @if (error) {
    <div [innerHTML]="error | sanitizeHtml"></div>
  }
</div>

<lib-dialog-info-actions (confirmEvent)="confirm()"></lib-dialog-info-actions>
