@if (tagOptions$ | async; as tagOptions) {
  <lib-multi-select-chip-input
    class="large"
    label="{{ 'Tags' | translate }}"
    [hint]="hint"
    [control]="control"
    [options]="tagOptions"
    bindLabel="title"
    compareWith="id"
    (addValue)="addTag($event)"
    [freeSolo]="true"
    [messages]="messages"
    [chipMaxLength]="tagMaxLength"
  ></lib-multi-select-chip-input>
}
