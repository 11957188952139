<div role="tabpanel" class="side-menu" [ngClass]="isOpened ? 'side-menu--opened' : 'side-menu--closed'">
  <div
    class="panel panel-tools panel-expanded side-menu--panel"
    [ngClass]="{ 'side-menu--panel--on-menu': !selectedMenuItem }"
    aria-label="panel content"
  >
    <div class="panel-container">
      <div class="side-menu--header fx-row justify-space-between align-center w-100 light-bottom-shadow">
        <h4 class="mat-subtitle-2 fx-grow align-center">{{ selectedMenuItem?.title }}</h4>
        <button class="side-menu--close-button" (click)="goBackToMenu()" aria-label="Close menu">
          <mat-icon svgIcon="close"></mat-icon>
        </button>
      </div>

      <div class="hosted-component">
        @if (selectedMenuItem?.componentTemplate) {
          <ng-container *ngTemplateOutlet="selectedMenuItem!.componentTemplate!"></ng-container>
        }
        <ng-template libDynamicHost></ng-template>
      </div>
    </div>
  </div>

  @if (!selectedMenuItem) {
    <div class="default">
      <button
        matRipple
        class="menu-toggle"
        (click)="toggleSideMenu()"
        [attr.aria-label]="'Right side menu toggle' | translate"
      >
        <mat-icon class="icon-md" [svgIcon]="isOpened ? 'arrow-head-right' : 'arrow-head-left'"></mat-icon>
      </button>
      <div
        class="side-menu--container"
        [ngClass]="isOpened ? 'side-menu--container--opened' : 'side-menu--container--closed'"
        role="menu"
      >
        <div class="menu-items">
          <lib-menu-items
            [items]="items"
            (itemClick)="loadItemComponent($event); menuItemClick.emit($event)"
          ></lib-menu-items>
        </div>
      </div>
    </div>
  }
</div>
