import { Injectable } from '@angular/core';
import {
  AuthenticationResponseJSON,
  PublicKeyCredentialCreationOptionsJSON,
  PublicKeyCredentialRequestOptionsJSON,
  RegistrationResponseJSON,
  startAuthentication,
  startRegistration,
} from '@simplewebauthn/browser';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebAuthnService {
  startRegistration(requestOptionsJSON: PublicKeyCredentialCreationOptionsJSON): Observable<RegistrationResponseJSON> {
    return from(startRegistration({ optionsJSON: requestOptionsJSON }));
  }

  startAuthentication(
    requestOptionsJSON: PublicKeyCredentialRequestOptionsJSON,
    useBrowserAutofill?: boolean,
  ): Observable<AuthenticationResponseJSON> {
    return from(
      startAuthentication({
        optionsJSON: requestOptionsJSON,
        useBrowserAutofill,
        verifyBrowserAutofillInput: false,
      }),
    );
  }

  isPasskeyAvailable(): Observable<boolean> {
    return from(PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable());
  }
}
