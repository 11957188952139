<div class="data-table-wrapper" [ngClass]="{ 'table-padding': withPadding && !isChildren }">
  <div class="data-table-side" data-testid="data-table-container" (libScrollToBottom)="scrolledToBottom.emit()">
    @if (tableDataSource.data.length > 0) {
      <table
        mat-table
        [ngClass]="{ 'parent-table': !isChildren, 'child-table': isChildren }"
        [dataSource]="tableDataSource"
        matSort
        (matSortChange)="sortTable($event)"
        class="w-100"
        multiTemplateDataRows
      >
        @if (enableRowSelection) {
          <ng-container matColumnDef="check">
            <th mat-header-cell class="select-column" *matHeaderCellDef>
              @if (selectAllOption) {
                <mat-checkbox
                  data-testid="selectAllCheckbox"
                  class="ml-1"
                  (change)="$event ? masterToggle($event) : null"
                  [checked]="tableDataSource.data | areSelected: selections : 'id'"
                  [indeterminate]="tableDataSource.data | areSelected: selections : 'id' : true"
                >
                </mat-checkbox>
              }
            </th>
            <td [lib-vertical-resizable]="isResizable" class="select-column" mat-cell *matCellDef="let row">
              <mat-checkbox
                class="ml-1"
                (click)="$event.stopPropagation()"
                (change)="$event ? checkToggle(row[selectionDataKey]) : null"
                [checked]="selections[row[selectionDataKey]] | boolean"
                [indeterminate]="
                  !selections[row[selectionDataKey]] &&
                  (selections | objectEntries | some: indeterminateSelection : row[selectionDataKey])
                "
                data-testid="selectCheckbox"
              >
              </mat-checkbox>
            </td>
          </ng-container>
        }
        @if (isExpandable) {
          <ng-container [matColumnDef]="'expandable'">
            <th mat-header-cell class="expandable-column" *matHeaderCellDef>
              @if (expandAllOption) {
                <div class="expandable-cell">
                  <button
                    lib-button
                    (click)="toggleAllRowsExpanded()"
                    [matTooltip]="isAllRowsExpanded ? ('Collapse All' | translate) : ('Expand All' | translate)"
                    class="expand-all-wrapper"
                  >
                    <mat-icon
                      data-testid="expandAllIcon"
                      class="expandable-icon"
                      [svgIcon]="isAllRowsExpanded ? 'arrow-head-down' : 'arrow-head-right'"
                    >
                    </mat-icon>
                  </button>
                </div>
              }
            </th>
            <td [lib-vertical-resizable]="isResizable" mat-cell *matCellDef="let element">
              <div class="expandable-cell">
                <mat-icon
                  data-testid="expandableIcon"
                  [svgIcon]="isRowExpanded(element) ? 'arrow-head-down' : 'arrow-head-right'"
                  class="expandable-icon"
                ></mat-icon>
              </div>
            </td>
          </ng-container>
        }
        @for (header of headerConfig.items; track header) {
          <ng-container [matColumnDef]="headerKey(header.key)">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="header.colspan">{{ header.name }}</th>
          </ng-container>
        }
        @for (tableColumn of tableColumns; track trackId($index, tableColumn); let last = $last) {
          <ng-container [matColumnDef]="columnKey(tableColumn)">
            @if (tableColumn.isSortable) {
              <th
                mat-header-cell
                *matHeaderCellDef
                [lib-sortable-horizontal-resizable]="isResizable && !last"
                [tableColumn]="tableColumn"
                [ngClass]="{ 'with-padding': !isExpandable }"
                [ngStyle]="{ width: tableColumn.width ?? 'unset' }"
                [attr.aria-label]="tableColumn.name"
              >
                <div>{{ tableColumn.name }}</div>
              </th>
            } @else {
              <th
                mat-header-cell
                *matHeaderCellDef
                [lib-horizontal-resizable]="isResizable && !last"
                [class.text-right]="tableColumn.position === 'right'"
                [ngClass]="{ 'with-padding': !isExpandable }"
                [ngStyle]="{ width: tableColumn.width ?? 'unset' }"
                [attr.aria-label]="tableColumn.name"
              >
                <div
                  [ngClass]="{
                    'horizontal-pagination':
                      horizontalPaginationConfig &&
                      horizontalPaginationConfig.pageSize < horizontalPaginationConfig.total &&
                      last,
                  }"
                >
                  @if (!tableColumn.noHeader) {
                    <div>{{ tableColumn.name }}</div>
                  }
                  @if (
                    horizontalPaginationConfig &&
                    horizontalPaginationConfig.pageSize < horizontalPaginationConfig.total &&
                    last
                  ) {
                    <div class="horizontal-pagination-container">
                      <button
                        [disabled]="horizontalPaginationConfig.currentIndex <= 0"
                        lib-button
                        class="pl-1 pr-2"
                        data-testid="prev-horizontal-page"
                        (click)="horizontalPagechange(-1)"
                      >
                        <mat-icon class="icon-md" svgIcon="arrow-full-left"></mat-icon>
                      </button>
                      <button
                        [disabled]="
                          horizontalPaginationConfig.currentIndex + horizontalPaginationConfig.pageSize >=
                          horizontalPaginationConfig.total
                        "
                        lib-button
                        class="p-0 pl-1"
                        data-testid="next-horizontal-page"
                        (click)="horizontalPagechange(1)"
                      >
                        <mat-icon class="icon-md" svgIcon="arrow-full-right"></mat-icon>
                      </button>
                    </div>
                  }
                </div>
              </th>
            }
            <td
              [lib-vertical-resizable]="isResizable"
              mat-cell
              *matCellDef="let element"
              [attr.rowspan]="rowSpans[columnKey(tableColumn)]?.[element[this.selectionDataKey]] || 1"
              [class.text-right]="tableColumn.position === 'right'"
              [ngClass]="{
                'with-padding': !isExpandable,
                'children-cell': isChildren,
                'selected-row': selections[element[this.selectionDataKey]] || element[SELECTED_ROW_PROPERTY],
                hidden: rowSpans[columnKey(tableColumn)]?.[element[this.selectionDataKey]] === 0,
              }"
              [ngStyle]="{ width: tableColumn.width ?? 'unset' }"
              class="cell-content"
            >
              @if (element) {
                <ng-template #defaultCell>
                  {{ (element | get: tableColumn.dataKey) ?? defaultEmptyValue }}
                </ng-template>
                <ng-container
                  *ngTemplateOutlet="
                    tableColumn?.cellTemplate || defaultCell;
                    context: { $implicit: element | get: tableColumn.dataKey, row: element }
                  "
                ></ng-container>
              }
            </td>
          </ng-container>
        }
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            @if (isRowExpanded(element)) {
              <div
                class="example-element-detail"
                [@detailExpand]="isRowExpanded(element) ? 'expanded' : 'collapsed'"
                data-testid="expandedArea"
              >
                <lib-data-table-expanded-row [row]="element" [config]="expandedRowConfig"></lib-data-table-expanded-row>
              </div>
            }
          </td>
        </ng-container>
        @if (headerColumns.length) {
          <tr class="custom-header" mat-header-row *matHeaderRowDef="headerColumns; sticky: !isChildren"></tr>
        }
        @if (withHeader) {
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: !isChildren"></tr>
        }
        <tr
          mat-row
          libExpandedRow
          [isExpandable]="isExpandable"
          [isRowExpanded]="isRowExpanded(row)"
          [rowItem]="row"
          *matRowDef="let row; columns: displayedColumns"
          [ngStyle]="{ cursor: selectRow.observers.length ? 'pointer' : 'default' }"
          class="element-row"
          (click)="onSelectRow(row)"
          data-testid="tableRow"
        ></tr>
        @if (isExpandable) {
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        }
      </table>
      @if (isPaginable) {
        <mat-paginator
          class="table-paginator"
          [pageSizeOptions]="defaultPaginationOptions"
          [pageSize]="paginationConfig.pageSize"
          [pageIndex]="paginationConfig.currentPage"
          [length]="paginationConfig.total"
          (page)="onPageChanged($event)"
          showFirstLastButtons
        >
        </mat-paginator>
      }
    } @else {
      <lib-data-table-empty-results
        [displayData]="emptyResult"
        [isChildren]="isChildren"
      ></lib-data-table-empty-results>
    }

    @if (isShowingLoadingAnimation) {
      <lib-spinner class="mt-2"></lib-spinner>
    }
  </div>
</div>
