@if (displayIndicatorCode) {
  <div class="fx-row align-baseline">
    <div
      [title]="indicatorCodeOverride ?? indicator?.code"
      class="mat-body-1 color-grey-800 pr-1"
      data-testid="indicatorCodeTitle"
    >
      {{ indicatorCodeOverride ?? indicator?.code }}
    </div>
    @if (important) {
      <mat-icon class="icon-md color-error" svgIcon="status" aria-hidden="false" aria-label="important-flag"></mat-icon>
    }
  </div>
}

<div class="justify-start item-title mat-subtitle-2 color-primary-900">
  {{ indicatorDescriptionOverride ?? indicator?.description }}
</div>

@if (prefilledFrequency) {
  <div class="fx-row align-center pt-1">
    <span class="color-grey-800">
      {{ "Data was pre-filled from FY{fiscalYear}" | translate: { fiscalYear: prefilledFrequency } }}
    </span>
  </div>
}

<div class="fx-row align-center pt-1">
  <div class="mt-1 color-grey-800 fx-row align-top">
    @if (updating) {
      <lib-spinner [diameter]="16" ariaLabel="spinner-updating"></lib-spinner>
    } @else {
      @if (!updateFailed) {
        <mat-icon aria-hidden="false" aria-label="updated" svgIcon="save" class="color-success"></mat-icon>
      } @else {
        <mat-icon
          aria-hidden="false"
          aria-label="updateFailed"
          [svgIcon]="'close'"
          class="color-error icon-sm"
        ></mat-icon>
      }
    }
    <ng-template #updated>
      @if (!updateFailed) {
        <mat-icon aria-hidden="false" aria-label="updated" svgIcon="save" class="color-success"></mat-icon>
      } @else {
        <mat-icon
          aria-hidden="false"
          aria-label="updateFailed"
          [svgIcon]="'close'"
          class="color-error icon-sm"
        ></mat-icon>
      }
    </ng-template>
    @if (valueGroupSet?.updated) {
      <div class="color-grey-900 ml-1">
        @if (displayUpdatedBy) {
          <div class="mb-1">
            <strong class="w-50 bold"> {{ "Updated" | translate }}: </strong>
            {{
              "{updatedDate} by {user}"
                | translate
                  : {
                      updatedDate: valueGroupSet?.updated | timeAgo,
                      user:
                        valueGroupSet?.updated_by
                        | updatedByUser: users
                        | updatedByGHGIntegrationSystem: valueGroupSet?.status_context,
                    }
            }}
          </div>
        } @else {
          {{ "Updated {updatedDate}" | translate: { updatedDate: valueGroupSet?.updated | timeAgo } }}
        }
        @if (valueGroupSet?.status && valueGroupSet?.status_context) {
          <div>
            <strong>{{ "Status" | translate }}: </strong>
            <span class="capitalize">{{ vgsStatusToText[valueGroupSet?.status || ""] }}</span> -
            <span>{{ valueGroupSet?.status_context | vgsetStatusContext }}</span>
          </div>
        }
      </div>
    } @else {
      <div class="ml-1">Never updated</div>
    }
  </div>
</div>
