<p-carousel
  [value]="items"
  [numVisible]="numVisible"
  [numScroll]="numScroll"
  [circular]="circular"
  [page]="0"
  (onPage)="onPageChange($event)"
>
  <ng-template let-item pTemplate="item">
    @if (contentTemplate) {
      <ng-container [ngTemplateOutlet]="contentTemplate" [ngTemplateOutletContext]="{ $implicit: item }">
      </ng-container>
    }
  </ng-template>
</p-carousel>
