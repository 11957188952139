<!-- Menus -->

<mat-menu #filterMenu="matMenu">
  @if (itemType) {
    <!-- <ng-template matMenuContent> -->
    @if (
      lockedFilters?.action === "lock"
        ? (searchOptions.filters | json) !== (lockedFilters?.filters | json)
        : (searchOptions.filters | json) !== "{}"
    ) {
      <button mat-menu-item class="color-error" title="{{ 'Reset' | translate }}" (click)="resetFilters()">
        <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
        {{ "Reset" | translate }}
      </button>
      <div class="divider divider-horizontal"></div>
    }
    @if (searchFilterConfig) {
      @if (searchFilterConfig[itemType].year && !(lockedFilters?.filters)!["year"]) {
        <button
          mat-menu-item
          [ngClass]="{ active: searchOptions.filters.year }"
          title="{{ 'Year' | translate }}"
          [matMenuTriggerFor]="yearMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          @if (!searchOptions.filters.year) {
            <span>{{ "Year" | translate }}</span>
          } @else {
            <span>
              {{ "FY{fiscalYear}" | translate: { fiscalYear: searchOptions.filters.year.title } }}
            </span>
          }
        </button>
      }

      @if (searchFilterConfig[itemType].year && lockedFilters?.filters?.["year"] && lockedFilters?.action === "lock") {
        <button mat-menu-item class="active">
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          {{ "FY{fiscalYear}" | translate: { fiscalYear: lockedFilters?.filters?.["year"]?.title } }}
        </button>
      }

      @if (searchFilterConfig[itemType].source && !(lockedFilters?.filters)!["source"]) {
        <button
          mat-menu-item
          [ngClass]="{ active: searchOptions.filters.source }"
          title="{{ 'Source' | translate }}"
          [matMenuTriggerFor]="sourceMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          @if (!searchOptions.filters.source) {
            <span>{{ "Source" | translate }}</span>
          } @else {
            <span>
              {{ searchOptions.filters.source.title }}
            </span>
          }
        </button>
      }

      @if (
        searchFilterConfig[itemType].source && (lockedFilters?.filters)!["source"] && lockedFilters?.action === "lock"
      ) {
        <button mat-menu-item class="active">
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>{{ lockedFilters?.filters?.["source"]?.title }}
        </button>
      }

      @if (searchFilterConfig[itemType].topic) {
        <button
          mat-menu-item
          [ngClass]="{ active: searchOptions.filters.topic }"
          title="{{ 'Topics' | translate }}"
          [matMenuTriggerFor]="topicMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          @if (!searchOptions.filters.topic) {
            <span>{{ "Topic" | translate }}</span>
          } @else {
            <span>
              {{ searchOptions.filters.topic.title }}
            </span>
          }
        </button>
      }

      @if (searchFilterConfig[itemType].category && !(lockedFilters?.filters)!["category"]) {
        <button
          mat-menu-item
          [ngClass]="{ active: searchOptions.filters.category }"
          title="{{ 'Categories' | translate }}"
          [matMenuTriggerFor]="categoryMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          @if (!searchOptions.filters.category) {
            <span>{{ "Category" | translate }}</span>
          } @else {
            <span>
              {{ searchOptions.filters.category.title | titlecase }}
            </span>
          }
        </button>
      }

      @if (searchFilterConfig[itemType].type) {
        <button
          mat-menu-item
          [ngClass]="{ active: searchOptions.filters.type }"
          title="{{ 'Types' | translate }}"
          [matMenuTriggerFor]="typeMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          @if (!searchOptions.filters.type) {
            <span>{{ "Type" | translate }}</span>
          } @else {
            <span>
              {{ searchOptions.filters.type.title }}
            </span>
          }
        </button>
      }

      @if (searchFilterConfig[itemType].framework) {
        <button
          mat-menu-item
          [ngClass]="{ active: searchOptions.filters.framework }"
          title="{{ 'Frameworks' | translate }}"
          [matMenuTriggerFor]="frameworkMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          @if (!searchOptions.filters.framework) {
            <span>{{ "Framework" | translate }}</span>
          } @else {
            <span>
              {{ searchOptions.filters.framework.title }}
            </span>
          }
        </button>
      }

      @if (searchFilterConfig[itemType].standard_codes && !(lockedFilters?.filters)!["standard_codes"]) {
        <button
          *libFeatureFlag="{ featureFlags: ['enable_ref_metrics_v2'], hiddenWhenEnabled: true }"
          mat-menu-item
          [ngClass]="{ active: searchOptions.filters.standard_codes }"
          [title]="'Standard codes' | translate"
          [matMenuTriggerFor]="standardCodeMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          @if (!searchOptions.filters.standard_codes) {
            <span>{{ "Standard codes" | translate }}</span>
          } @else {
            <span>
              {{ searchOptions.filters.standard_codes.title }}
            </span>
          }
        </button>
      }

      @if (searchFilterConfig[itemType].taxonomy) {
        <button
          *libFeatureFlag="{ featureFlags: ['enable_ref_metrics_v2'] }"
          mat-menu-item
          [ngClass]="{ active: searchOptions.filters.taxonomy }"
          [title]="'Framework Taxonomy' | translate"
          [matMenuTriggerFor]="taxonomyMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          @if (!searchOptions.filters.taxonomy) {
            <span>{{ "Framework Taxonomy" | translate }}</span>
          } @else {
            <span>
              {{ searchOptions.filters.taxonomy.title }}
            </span>
          }
        </button>
      }

      @if (searchFilterConfig[itemType].non_favorite_taxonomy) {
        <button
          *libFeatureFlag="{ featureFlags: ['enable_ref_metrics_v2'] }"
          mat-menu-item
          [ngClass]="{ active: searchOptions.filters.non_favorite_taxonomy }"
          [title]="'Framework Taxonomy' | translate"
          [matMenuTriggerFor]="nonFavoriteTaxonomyMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          @if (!searchOptions.filters.non_favorite_taxonomy) {
            <span>{{ "Framework Taxonomy" | translate }}</span>
          } @else {
            <span>
              {{ searchOptions.filters.non_favorite_taxonomy.title }}
            </span>
          }
        </button>
      }

      @if (searchFilterConfig[itemType].industry) {
        <button
          mat-menu-item
          [ngClass]="{ active: searchOptions.filters.industry }"
          title="{{ 'Industries' | translate }}"
          [matMenuTriggerFor]="industryMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          @if (!searchOptions.filters.industry) {
            <span>{{ "Industry" | translate }}</span>
          } @else {
            <span>
              {{ searchOptions.filters.industry.title }}
            </span>
          }
        </button>
      }

      @if (searchFilterConfig[itemType].tag) {
        <button
          mat-menu-item
          [ngClass]="{ active: searchOptions.filters.tag }"
          title="{{ 'Categories' | translate }}"
          [matMenuTriggerFor]="tagMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          @if (!searchOptions.filters.tag) {
            <span>{{ "Tags" | translate }}</span>
          } @else {
            <span>
              {{ searchOptions.filters.tag.title }}
            </span>
          }
        </button>
      }

      @if (searchFilterConfig[itemType].role) {
        <button
          mat-menu-item
          [ngClass]="{ active: searchOptions.filters.role }"
          title="{{ 'Role' | translate }}"
          [matMenuTriggerFor]="roleMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          @if (!searchOptions.filters.role) {
            <span>{{ "Role" | translate }}</span>
          } @else {
            <span>
              {{ searchOptions.filters.role.title }}
            </span>
          }
        </button>
      }

      @if (searchFilterConfig[itemType].status) {
        <button
          mat-menu-item
          [ngClass]="{ active: searchOptions.filters.status }"
          title="{{ 'Status' | translate }}"
          [matMenuTriggerFor]="statusMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          @if (!searchOptions.filters.status) {
            <span>{{ "Status" | translate }}</span>
          } @else {
            <span>
              {{ searchOptions.filters.status.title }}
            </span>
          }
        </button>
      }

      @if (searchFilterConfig[itemType].author) {
        <button
          mat-menu-item
          [ngClass]="{ active: searchOptions.filters.author }"
          title="{{ 'Creator' | translate }}"
          [matMenuTriggerFor]="authorMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          @if (!searchOptions.filters.author) {
            <span>{{ "Creator" | translate }}</span>
          } @else {
            <span>
              {{ searchOptions.filters.author.title }}
            </span>
          }
        </button>
      }
    }

    @if (mode === "drag") {
      <div class="divider divider-horizontal"></div>
      <button mat-menu-item class="active" title="{{ 'Sort' | translate }}" [matMenuTriggerFor]="sortMenu">
        <mat-icon class="icon-sm" svgIcon="sort"></mat-icon>
        {{ searchOptions.sort?.title }}
      </button>
    }
  }
</mat-menu>

<mat-menu #externalFiltersMenu="matMenu">
  <ng-template matMenuContent let-menuData="menuData">
    <button mat-menu-item class="menu-title" disabled>{{ menuData.title }}</button>

    @if (filters[menuData.id].control && filters[menuData.id].items.length > minMenuScrollItems) {
      <div class="menu-search" (click)="$event.stopPropagation()">
        <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
        <input
          matInput
          type="text"
          placeholder="{{ 'Search' | translate }}"
          autocomplete="off"
          (keydown)="$event.stopPropagation()"
          [formControl]="filters[menuData.id].control!"
        />
        @if (filters[menuData.id].control!.value) {
          <button class="clear-search" (click)="filters[menuData.id].control!.setValue('')">
            <mat-icon class="icon-xs" svgIcon="filter"></mat-icon>
          </button>
        }
      </div>
    }

    <hr />
    <button mat-menu-item (click)="setItem(menuData.id)">{{ "All" | translate }}</button>
    <hr class="hr-light" />

    <div [ngClass]="{ 'menu-items-container': filters[menuData.id].items.length > minMenuScrollItems }">
      @for (item of filters[menuData.id].items; track item.id) {
        <button
          mat-menu-item
          [title]="item.title"
          (click)="setItem(menuData.id, item)"
          [ngClass]="{ active: searchOptions.filters[menuData.id]?.id === item.id }"
        >
          <span>{{ item.title }}</span>
        </button>
      }
    </div>
  </ng-template>
</mat-menu>

<mat-menu #yearMenu="matMenu">
  <ng-template matMenuContent>
    <header>
      <button mat-menu-item disabled>{{ "Fiscal year" | translate }}</button>
    </header>
    <hr />
    <button mat-menu-item (click)="setItem('year')">
      @if (itemType === "metrics_indicator") {
        {{ "Current ({currentYear})" | translate: { currentYear: currentYear } }}
      } @else {
        {{ "All" | translate }}
      }
    </button>
    <hr />

    @for (item of filters.year.items; track item.id) {
      <button
        mat-menu-item
        (click)="setItem('year', item)"
        [ngClass]="{
          active:
            searchOptions.filters.year?.id === item.id ||
            (!searchOptions.filters.year && itemType === 'metrics_indicator' && item.id === currentYear),
        }"
      >
        {{ item.title }}
      </button>
    }
  </ng-template>
</mat-menu>

<mat-menu #sourceMenu="matMenu">
  <ng-template matMenuContent>
    @if (filters.source.filteredItems | async; as filteredItems) {
      <lib-source-menu-filter
        [minMenuScrollItems]="minMenuScrollItems"
        [initialSources]="filteredItems"
        [selectedSource]="searchOptions.filters.source"
        [sourceConfiguration]="sourceConfiguration"
        [allSourcesItem]="allSourcesItem"
        (setItem)="setItem('source', $event)"
      >
      </lib-source-menu-filter>
    }
  </ng-template>
</mat-menu>

<mat-menu #topicMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item class="menu-title" disabled>{{ "Topic" | translate }}</button>
    @if (filters.topic.control && filters.topic.items.length > minMenuScrollItems) {
      <div class="menu-search" (click)="$event.stopPropagation()">
        <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
        <input
          matInput
          type="text"
          placeholder="{{ 'Search' | translate }}"
          autocomplete="off"
          (keydown)="$event.stopPropagation()"
          [formControl]="filters.topic.control"
        />
        @if (filters.topic.control.value) {
          <button class="clear-search" (click)="filters.topic.control.setValue('')">
            <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
          </button>
        }
      </div>
    }

    <hr />
    <button mat-menu-item (click)="setItem('topic')">{{ "All" | translate }}</button>
    <hr class="hr-light" />

    <div [ngClass]="{ 'menu-items-container': filters.topic.items.length > minMenuScrollItems }">
      @for (item of filters.topic.filteredItems | async; track item.id) {
        <button
          mat-menu-item
          [title]="item.title"
          (click)="setItem('topic', item)"
          [ngClass]="{ active: searchOptions.filters.topic?.id === item.id }"
        >
          <span [ngClass]="{ 'pl-2': item.action === 'group', 'pl-4': item.action === 'topic' }">{{ item.title }}</span>
        </button>
      }
    </div>
  </ng-template>
</mat-menu>

<mat-menu #categoryMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item class="menu-title" disabled>{{ "Category" | translate }}</button>
    @if (filters.category.control && filters.category.items.length > minMenuScrollItems) {
      <div class="menu-search" (click)="$event.stopPropagation()">
        <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
        <input
          matInput
          type="text"
          placeholder="{{ 'Search' | translate }}"
          autocomplete="off"
          (keydown)="$event.stopPropagation()"
          [formControl]="filters.category.control"
        />
        @if (filters.category.control.value) {
          <button class="clear-search" (click)="filters.category.control.setValue('')">
            <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
          </button>
        }
      </div>
    }

    <hr />
    <button mat-menu-item (click)="setItem('category')">{{ "All" | translate }}</button>
    <hr class="hr-light" />

    <div [ngClass]="{ 'menu-items-container': filters.category.items.length > minMenuScrollItems }">
      @for (item of filters.category.filteredItems | async; track item.id) {
        <button
          [title]="item.title"
          mat-menu-item
          (click)="setItem('category', item)"
          [ngClass]="{ active: searchOptions.filters.category?.id === item.id }"
        >
          <span>{{ item.title }}</span>
        </button>
      }
    </div>
  </ng-template>
</mat-menu>

<mat-menu #typeMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item class="menu-title" disabled>{{ "Type" | translate }}</button>
    @if (filters.type.control && filters.type.items.length > minMenuScrollItems) {
      <div class="menu-search" (click)="$event.stopPropagation()">
        <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
        <input
          matInput
          type="text"
          placeholder="{{ 'Search' | translate }}"
          autocomplete="off"
          (keydown)="$event.stopPropagation()"
          [formControl]="filters.type.control"
        />
        @if (filters.type.control.value) {
          <button class="clear-search" (click)="filters.type.control.setValue('')">
            <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
          </button>
        }
      </div>
    }

    <hr />
    <button mat-menu-item (click)="setItem('type')">{{ "All" | translate }}</button>
    <hr class="hr-light" />

    <div [ngClass]="{ 'menu-items-container': filters.type.items.length > minMenuScrollItems }">
      @for (item of filters.type.filteredItems | async; track item.id) {
        <button
          mat-menu-item
          [title]="item.title"
          (click)="setItem('type', item)"
          [ngClass]="{ active: searchOptions.filters.type?.id === item.id }"
        >
          <span>{{ item.title }}</span>
        </button>
      }
    </div>
  </ng-template>
</mat-menu>

<mat-menu #frameworkMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item class="menu-title" disabled>{{ "Framework" | translate }}</button>
    <hr />
    <button mat-menu-item (click)="setItem('framework')">{{ "All" | translate }}</button>
    <hr class="hr-light" />
    @for (item of filters.framework.items; track item.id) {
      <button
        mat-menu-item
        [ngClass]="{ active: searchOptions.filters.framework === item }"
        (click)="setItem('framework', item)"
      >
        {{ item.title }}
      </button>
    }
  </ng-template>
</mat-menu>

<mat-menu #questionnaireFrameworkMenu="matMenu">
  <ng-template matMenuContent>
    <lib-search-bar-menu-item
      [menuTitle]="'Questionnaire Framework' | translate"
      [resourceType]="eSearchBarFilterResourceType.questionnaire_framework"
      [filterItems]="filters.questionnaire_framework.items"
      [selectedFilterItemId]="searchOptions.filters.questionnaire_framework?.id ?? ''"
      (setItem)="setItem(eSearchBarFilterResourceType.questionnaire_framework, $event)"
    ></lib-search-bar-menu-item>
  </ng-template>
</mat-menu>

<mat-menu #industryMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item class="menu-title" disabled>{{ "Industry" | translate }}</button>
    @if (filters.industry.control && filters.industry.items.length > minMenuScrollItems) {
      <div class="menu-search" (click)="$event.stopPropagation()">
        <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
        <input
          matInput
          type="text"
          placeholder="{{ 'Search' | translate }}"
          autocomplete="off"
          (keydown)="$event.stopPropagation()"
          [formControl]="filters.industry.control"
        />
        @if (filters.industry.control.value) {
          <button class="clear-search" (click)="filters.industry.control.setValue('')">
            <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
          </button>
        }
      </div>
    }

    <hr />
    <button mat-menu-item (click)="setItem('industry')">{{ "All" | translate }}</button>
    <hr class="hr-light" />

    <div [ngClass]="{ 'menu-items-container': filters.industry.items.length > minMenuScrollItems }">
      @for (item of filters.industry.filteredItems | async; track item.id) {
        <button
          mat-menu-item
          [title]="item.title"
          (click)="setItem('industry', item)"
          [ngClass]="{ active: searchOptions.filters.industry?.id === item.id }"
        >
          <span>{{ item.title | titlecase }}</span>
        </button>
      }
    </div>
  </ng-template>
</mat-menu>

<mat-menu #tagMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item class="menu-title" disabled>{{ "Tag" | translate }}</button>
    @if (filters.tag.control && filters.tag.items.length > minMenuScrollItems) {
      <div class="menu-search" (click)="$event.stopPropagation()">
        <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
        <input
          matInput
          type="text"
          placeholder="{{ 'Search' | translate }}"
          autocomplete="off"
          (keydown)="$event.stopPropagation()"
          [formControl]="filters.tag.control"
        />
        @if (filters.tag.control.value) {
          <button class="clear-search" (click)="filters.tag.control.setValue('')">
            <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
          </button>
        }
      </div>
    }

    <hr />
    <button mat-menu-item (click)="setItem('tag')">{{ "All" | translate }}</button>
    <hr class="hr-light" />

    <div [ngClass]="{ 'menu-items-container': filters.tag.items.length > minMenuScrollItems }">
      @for (item of filters.tag.filteredItems | async; track item.id) {
        <button
          mat-menu-item
          [title]="item.title"
          (click)="setItem('tag', item)"
          [ngClass]="{ active: searchOptions.filters.tag?.id === item.id }"
        >
          <span>{{ item.title | titlecase }}</span>
        </button>
      }
    </div>
  </ng-template>
</mat-menu>

<mat-menu #statusMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item class="menu-title" disabled>{{ "Status" | translate }}</button>
    <hr />
    <button mat-menu-item (click)="setItem('status')">{{ "All" | translate }}</button>
    <hr class="hr-light" />
    @for (item of filters.status.items; track item.id) {
      <button
        mat-menu-item
        [ngClass]="{ active: searchOptions.filters.status?.id === item.id }"
        (click)="setItem('status', item)"
      >
        {{ item.title }}
      </button>
    }
  </ng-template>
</mat-menu>

<mat-menu #authorMenu="matMenu">
  <ng-template matMenuContent>
    <header>
      <button mat-menu-item disabled>{{ "Author" | translate }}</button>
      <a class="mr-3" (click)="setItem('author')">
        <mat-icon
          class="icon-sm"
          svgIcon="close"
          [ngClass]="{ 'color-white': !searchOptions.filters.author }"
        ></mat-icon>
      </a>
    </header>
    <hr />
    <button mat-menu-item (click)="setItem('author', { id: '', title: 'All' })">{{ "All" | translate }}</button>
    <hr />
    @for (item of filters.author.items; track item.id) {
      <button
        mat-menu-item
        [ngClass]="{ active: searchOptions.filters.author === item }"
        (click)="setItem('author', item)"
      >
        {{ item.title }}
      </button>
    }
  </ng-template>
</mat-menu>

<mat-menu #roleMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item class="menu-title" disabled>{{ "Role" | translate }}</button>
    <hr />
    <button mat-menu-item (click)="setItem('role')">{{ "All" | translate }}</button>
    <hr class="hr-light" />
    @for (item of filters.role.items; track item.id) {
      <button
        mat-menu-item
        [ngClass]="{ active: searchOptions.filters.role?.id === item.id }"
        (click)="setItem('role', item)"
      >
        {{ item.title }}
      </button>
    }
  </ng-template>
</mat-menu>

<mat-menu #sortMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item class="menu-title" disabled>{{ "Sort" | translate }}</button>
    <hr />
    @for (item of filters.sort.items; track item.id) {
      <button
        mat-menu-item
        [ngClass]="{
          active: searchOptions.sort?.id === item.id,
          hide: item.id === 'score' && !searchOptions.query.keywords,
        }"
        (click)="applySort(item)"
      >
        {{ item.title }}
      </button>
    }
  </ng-template>
</mat-menu>

<mat-menu #standardCodeMenu="matMenu">
  <ng-template matMenuContent>
    <lib-search-bar-standard-code-menu
      [control]="filters.standard_codes.control"
      [minMenuScrollItems]="minMenuScrollItems"
      [items]="filters.standard_codes.items"
      [filteredItems]="filters.standard_codes.filteredItems"
      [selectedItemId]="searchOptions.filters.standard_codes?.id"
      (setItem)="setItem('standard_codes', $event)"
    ></lib-search-bar-standard-code-menu>
  </ng-template>
</mat-menu>

<mat-menu #taxonomyMenu="matMenu">
  <ng-template matMenuContent>
    <lib-search-bar-taxonomy-menu
      [control]="filters.taxonomy.control"
      [minMenuScrollItems]="minMenuScrollItems"
      [items]="filters.taxonomy.items"
      [filteredItems]="filters.taxonomy.filteredItems"
      [selectedItemId]="searchOptions.filters.taxonomy?.id"
      (setItem)="setItem('taxonomy', $event)"
    ></lib-search-bar-taxonomy-menu>
  </ng-template>
</mat-menu>

<mat-menu #nonFavoriteTaxonomyMenu="matMenu">
  <ng-template matMenuContent>
    <lib-search-bar-taxonomy-menu
      [control]="filters.non_favorite_taxonomy.control"
      [minMenuScrollItems]="minMenuScrollItems"
      [items]="filters.non_favorite_taxonomy.items"
      [filteredItems]="filters.non_favorite_taxonomy.filteredItems"
      [selectedItemId]="searchOptions.filters.non_favorite_taxonomy?.id"
      (setItem)="setItem('non_favorite_taxonomy', $event)"
    ></lib-search-bar-taxonomy-menu>
  </ng-template>
</mat-menu>

<!-- Layout -->

<div class="search-wrapper">
  <div class="search-keywords" [ngClass]="{ active: searchOptions.query.keywords }">
    <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
    <input
      type="text"
      [placeholder]="searchPlaceholder"
      [ngModel]="searchOptions.query.keywords"
      (ngModelChange)="onSearchQueryChanged($event)"
    />
    @if (searchOptions.query.keywords) {
      <a class="clear-search" (click)="clearSearch()">
        <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
      </a>
    }
  </div>
  <div class="search-options" data-testid="searchOptions">
    @if (mode !== "nav") {
      <div class="divider divider-vertical"></div>
      <a
        class="btn btn-filter mr-1"
        [title]="'Filter' | translate"
        [ngClass]="{ active: filterCount }"
        [matMenuTriggerFor]="filterMenu"
      >
        <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
        <span>
          {{ "Filter" | translate }}
          @if (filterCount) {
            ({{ filterCount }})
          }
        </span>
      </a>
    } @else {
      <div class="search-filters">
        @if (searchOptions.filters && itemType) {
          @if ((searchOptions.filters | json) !== "{}") {
            <a class="btn btn-warn" title="{{ 'Reset' | translate }}" (click)="resetFilters()">
              <mat-icon class="icon-sm" svgIcon="frequency"></mat-icon>
              <span>{{ "Reset" | translate }}</span>
            </a>
            <div class="divider divider-vertical"></div>
          }
          @for (filter of externalFilters; track filter.id) {
            <a
              [ngClass]="{ active: searchOptions.filters[filter.id] }"
              class="btn btn-filter"
              [title]="filter.title"
              [matMenuTriggerFor]="externalFiltersMenu"
              [matMenuTriggerData]="{ menuData: filter }"
            >
              <mat-icon class="icon-sm" [svgIcon]="'filter'"></mat-icon>
              @if (!searchOptions.filters[filter.id]) {
                <span>{{ filter.title }}</span>
              } @else {
                <span>
                  {{ searchOptions.filters[filter.id]!.title }}
                </span>
              }
            </a>
          }
          @if (searchFilterConfig) {
            @if (searchFilterConfig[itemType].start) {
              <!--To hide the input while still position the calendar popup correctly, this styling was mandatory-->
              <mat-form-field style="width: 1px; visibility: hidden">
                <input
                  matInput
                  [matDatepicker]="startDatePicker"
                  (dateChange)="setItem('start', { id: 'start', title: 'Start Date', item: $event.value })"
                />
                <mat-datepicker #startDatePicker></mat-datepicker>
              </mat-form-field>
              <a
                (click)="startDatePicker.open()"
                class="btn btn-filter active"
                [ngClass]="{ active: searchOptions.filters.start }"
                [title]="'Start Date' | translate"
              >
                <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
                @if (!searchOptions.filters.start) {
                  <span>
                    <ng-container> {{ "Start Date" | translate }}</ng-container>
                  </span>
                } @else {
                  <span>
                    {{ searchOptions.filters.start.item | date }}
                  </span>
                }
              </a>
            }
          }
          @if (searchFilterConfig) {
            @if (searchFilterConfig[itemType].year) {
              <a
                class="btn btn-filter active"
                [ngClass]="{ active: searchOptions.filters.year }"
                [title]="'Year' | translate"
                [matMenuTriggerFor]="yearMenu"
              >
                <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
                @if (!searchOptions.filters.year) {
                  <span>
                    @if (itemType === "metrics_indicator") {
                      {{ "FY{fiscalYear}" | translate: { fiscalYear: this.currentFiscalYear } }}
                    } @else {
                      {{ "Fiscal year" | translate }}
                    }
                  </span>
                } @else {
                  <span> {{ "FY{fiscalYear}" | translate: { fiscalYear: searchOptions.filters.year.title } }}</span>
                }
              </a>
            }
            @if (searchFilterConfig[itemType].source) {
              <a
                [ngClass]="{ active: searchOptions.filters.source }"
                class="btn btn-filter"
                [title]="'Source' | translate"
                [matMenuTriggerFor]="sourceMenu"
              >
                <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
                @if (!searchOptions.filters.source) {
                  <span>{{ "Source" | translate }}</span>
                } @else {
                  <span>
                    {{ searchOptions.filters.source.title }}
                  </span>
                }
              </a>
            }
            @if (searchFilterConfig[itemType].topic) {
              <a
                [ngClass]="{ active: searchOptions.filters.topic }"
                class="btn btn-filter"
                [title]="'Topics' | translate"
                [matMenuTriggerFor]="topicMenu"
              >
                <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
                @if (!searchOptions.filters.topic) {
                  <span>{{ "Topic" | translate }}</span>
                } @else {
                  <span>
                    {{ searchOptions.filters.topic.title }}
                  </span>
                }
              </a>
            }
            @if (searchFilterConfig[itemType].category) {
              <a
                [ngClass]="{ active: searchOptions.filters.category }"
                class="btn btn-filter"
                [title]="'Categories' | translate"
                [matMenuTriggerFor]="categoryMenu"
              >
                <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
                @if (!searchOptions.filters.category) {
                  <span>{{ "Category" | translate }}</span>
                } @else {
                  <span>
                    <span>{{ searchOptions.filters.category.title }}</span>
                  </span>
                }
              </a>
            }
            @if (searchFilterConfig[itemType].type) {
              <a
                [ngClass]="{ active: searchOptions.filters.type }"
                class="btn btn-filter"
                [title]="'Types' | translate"
                [matMenuTriggerFor]="typeMenu"
              >
                <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
                @if (!searchOptions.filters.type) {
                  <span>{{ "Type" | translate }}</span>
                } @else {
                  <span>
                    {{ searchOptions.filters.type.title }}
                  </span>
                }
              </a>
            }
            @if (searchFilterConfig[itemType].standard_codes) {
              <a
                class="btn btn-filter active"
                [ngClass]="{ active: searchOptions.filters.standard_codes }"
                [title]="'Standard codes' | translate"
                [matMenuTriggerFor]="standardCodeMenu"
              >
                <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
                @if (!searchOptions.filters.standard_codes) {
                  <span> {{ "Standard codes" | translate }} </span>
                } @else {
                  <span>
                    {{ searchOptions.filters.standard_codes.title }}
                  </span>
                }
              </a>
            }
            @if (searchFilterConfig[itemType].framework) {
              <a
                [ngClass]="{ active: searchOptions.filters.framework }"
                class="btn btn-filter"
                [title]="'Frameworks' | translate"
                [matMenuTriggerFor]="frameworkMenu"
              >
                <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
                @if (!searchOptions.filters.framework) {
                  <span>{{ "Framework" | translate }}</span>
                } @else {
                  <span>
                    {{ searchOptions.filters.framework.title }}
                  </span>
                }
              </a>
            }
            @if (searchFilterConfig[itemType].questionnaire_framework) {
              <a
                [ngClass]="{ active: searchOptions.filters.questionnaire_framework }"
                class="btn btn-filter"
                [title]="'Questionnaire Framework' | translate"
                [matMenuTriggerFor]="questionnaireFrameworkMenu"
              >
                <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
                @if (!searchOptions.filters.questionnaire_framework) {
                  <span>{{ "Questionnaire Framework" | translate }}</span>
                } @else {
                  <span>
                    {{ searchOptions.filters.questionnaire_framework.title }}
                  </span>
                }
              </a>
            }
            @if (searchFilterConfig[itemType].industry) {
              <a
                [ngClass]="{ active: searchOptions.filters.industry }"
                class="btn btn-filter"
                [title]="'Industries' | translate"
                [matMenuTriggerFor]="industryMenu"
              >
                <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
                @if (!searchOptions.filters.industry) {
                  <span>{{ "Industry" | translate }}</span>
                } @else {
                  <span>
                    {{ searchOptions.filters.industry.title }}
                  </span>
                }
              </a>
            }
            @if (searchFilterConfig[itemType].tag) {
              <a
                [ngClass]="{ active: searchOptions.filters.tag }"
                class="btn btn-filter"
                [title]="'Tags' | translate"
                [matMenuTriggerFor]="tagMenu"
              >
                <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
                @if (!searchOptions.filters.tag) {
                  <span>{{ "Tags" | translate }}</span>
                } @else {
                  <span>
                    {{ searchOptions.filters.tag.title }}
                  </span>
                }
              </a>
            }
            @if (searchFilterConfig[itemType].role) {
              <a
                class="btn btn-filter active"
                [ngClass]="{ active: searchOptions.filters.role }"
                [title]="'Role' | translate"
                [matMenuTriggerFor]="roleMenu"
              >
                <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
                @if (!searchOptions.filters.role) {
                  <span> {{ "Role" | translate }}</span>
                } @else {
                  <span>
                    {{ searchOptions.filters.role.title }}
                  </span>
                }
              </a>
            }
            @if (searchFilterConfig[itemType].status) {
              <a
                [ngClass]="{ active: searchOptions.filters.status }"
                class="btn btn-filter"
                [title]="'Status' | translate"
                [matMenuTriggerFor]="statusMenu"
                role="button"
              >
                <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
                @if (!searchOptions.filters.status) {
                  <span>{{ "Status" | translate }}</span>
                } @else {
                  <span>
                    {{ searchOptions.filters.status.title }}
                  </span>
                }
              </a>
            }
            @if (searchFilterConfig[itemType].author) {
              <a
                [ngClass]="{ active: searchOptions.filters.author }"
                class="btn btn-filter"
                [title]="'Creator' | translate"
                [matMenuTriggerFor]="authorMenu"
              >
                <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
                @if (!searchOptions.filters.author) {
                  <span>{{ "Author" | translate }}</span>
                } @else {
                  <span>
                    {{ searchOptions.filters.author.title }}
                  </span>
                }
              </a>
            }
          }
          <div class="divider divider-vertical"></div>
        }
        <a class="btn btn-filter active" title="{{ 'Sort' | translate }}" [matMenuTriggerFor]="sortMenu">
          <mat-icon class="icon-sm" svgIcon="sort"></mat-icon>
          {{ searchOptions.sort?.title }}
        </a>
      </div>
    }
  </div>
</div>
