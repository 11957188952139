<div class="formats">
  <form [formGroup]="formGroup">
    <!-- text area without validation -->
    @if (data.dataFormatType === valueDefinitionType.text_area) {
      <div>
        <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
        <mat-form-field class="w-100 superscript-hint" floatLabel="auto">
          <textarea
            rows="5"
            matInput
            formControlName="textAreaControl"
            class="color-grey-900"
            [cdkTextareaAutosize]="true"
            id="{{ data.id }}"
            [placeholder]="data.data.type_details.placeholder ?? ''"
            libStripNonPrintableCharacters
            >{{ data.data.value }}</textarea
          >
          <mat-hint class="w-100 fx-row">
            <div>{{ data.data.hint }}</div>
          </mat-hint>
          @if (formGroup.controls["textAreaControl"].hasError("required")) {
            <mat-error>
              {{ requiredErrorMsg }}
            </mat-error>
          }
          @if (formGroup.controls["textAreaControl"].hasError("maxlength")) {
            <mat-error>
              {{
                "maximum length can be {count}"
                  | translate: { count: formGroup.controls["textAreaControl"].errors?.maxlength?.requiredLength }
              }}
            </mat-error>
          }
          @if (formGroup.controls["textAreaControl"].hasError("minlength")) {
            <mat-error>
              {{
                "minimum length should be {count}"
                  | translate: { count: formGroup.controls["textAreaControl"].errors?.minlength?.requiredLength }
              }}
            </mat-error>
          }
        </mat-form-field>
      </div>
    } @else {
      @if (data.dataFormatType === valueDefinitionType.text_area_validation) {
        <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
        <mat-form-field class="w-100 superscript-hint" floatLabel="auto">
          <textarea
            rows="5"
            matInput
            #textArea
            [cdkTextareaAutosize]="true"
            [maxlength]="maxCharacterCount"
            formControlName="textAreaWithValidation"
            class="color-grey-900"
            [placeholder]="data.data.type_details.placeholder ?? ''"
            libStripNonPrintableCharacters
            id="{{ data.id }}"
            >{{ data.data.value?.substring(0, maxCharacterCount) }}</textarea
          >
          <mat-hint class="data-formats-header w-100 fx-row justify-space-between">
            <div>{{ data.data.hint }}</div>
            <div>{{ textArea.value.length }}/{{ maxCharacterCount }}</div>
          </mat-hint>
          @if (formGroup.controls["textAreaWithValidation"].hasError("required")) {
            <mat-error>
              {{ requiredErrorMsg }}
            </mat-error>
          }
          @if (formGroup.controls["textAreaWithValidation"].hasError("maxlength")) {
            <mat-error>
              {{
                "maximum length can be {count}"
                  | translate: { count: formGroup.controls["textAreaWithValidation"].errors?.maxlength?.requiredLength }
              }}
            </mat-error>
          }
          @if (formGroup.controls["textAreaWithValidation"].hasError("minlength")) {
            <mat-error>
              {{
                "minimum length should be {count}"
                  | translate: { count: formGroup.controls["textAreaWithValidation"].errors?.minlength?.requiredLength }
              }}
            </mat-error>
          }
        </mat-form-field>
      } @else {
        @if (data.dataFormatType === valueDefinitionType.text_rich) {
          <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
          <div class="rich-text-formfield">
            <div id="{{ data.id }}">
              <lib-rich-text-input
                [control]="editorControl"
                class="{{ data.id }}"
                [ngClass]="{
                  error: editorControl.touched && editorControl.invalid,
                }"
                [placeholder]="data.data.type_details.placeholder"
              ></lib-rich-text-input>
            </div>
            @if (editorControl.valid) {
              <mat-hint>{{ data.data.hint }}</mat-hint>
            }
            @if (editorControl.touched && editorControl.hasError("required")) {
              <mat-error>
                {{ requiredErrorMsg }}
              </mat-error>
            }
            @if (editorControl.touched && editorControl.hasError("maxlength")) {
              <mat-error>
                {{
                  "maximum length can be {count}"
                    | translate: { count: formGroup.controls["editorControl"].errors?.maxlength?.requiredLength }
                }}
              </mat-error>
            }
            @if (editorControl.touched && editorControl.hasError("minlength")) {
              <mat-error>
                {{
                  "minimum length should be {count}"
                    | translate: { count: formGroup.controls["editorControl"].errors?.minlength?.requiredLength }
                }}
              </mat-error>
            }
          </div>
        } @else {
          @if (data.dataFormatType === valueDefinitionType.text_simple) {
            <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
            <mat-form-field class="w-100" floatLabel="auto">
              <input
                matInput
                formControlName="textInputFormat"
                class="color-grey-900"
                id="{{ data.id }}"
                [placeholder]="data.data.type_details.placeholder ?? ''"
              />
              <mat-hint>{{ data.data.hint }}</mat-hint>
              @if (formGroup.controls["textInputFormat"].hasError("required")) {
                <mat-error>
                  {{ requiredErrorMsg }}
                </mat-error>
              }
              @if (formGroup.controls["textInputFormat"].hasError("maxlength")) {
                <mat-error>
                  {{
                    "maximum length can be {count}"
                      | translate: { count: formGroup.controls["textInputFormat"].errors?.maxlength?.requiredLength }
                  }}
                </mat-error>
              }
              @if (formGroup.controls["textInputFormat"].hasError("minlength")) {
                <mat-error>
                  {{
                    "minimum length should be {count}"
                      | translate: { count: formGroup.controls["textInputFormat"].errors?.minlength?.requiredLength }
                  }}
                </mat-error>
              }
            </mat-form-field>
          } @else {
            @if (
              data.dataFormatType === valueDefinitionType.number ||
              data.dataFormatType === valueDefinitionType.calculated
            ) {
              <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
              <mat-form-field class="w-100 m-0" floatLabel="auto">
                <input
                  matInput
                  formControlName="numberControl"
                  value="{{ data.data.value }}"
                  class="color-grey-900"
                  id="{{ data.id }}"
                  (input)="roundOff($event)"
                  [placeholder]="data.data.type_details.placeholder ?? ''"
                />
                <div matSuffix class="color-grey-900">{{ symbol }}</div>
                <mat-hint>{{ data.data.hint }}</mat-hint>
                @if (
                  !formGroup.controls["numberControl"].hasError("required") &&
                  (formGroup.controls["numberControl"].hasError("pattern") ||
                    formGroup.controls["numberControl"].hasError("notNumeric"))
                ) {
                  <mat-error>
                    {{ numberValidationErrorMsg }}
                    {{ "decimal" | translate }}
                  </mat-error>
                }
                @if (formGroup.controls["numberControl"].hasError("required")) {
                  <mat-error>
                    {{ requiredErrorMsg }}
                  </mat-error>
                }
                @if (formGroup.controls["numberControl"].hasError("maxlength")) {
                  <mat-error>
                    {{
                      "maximum length can be {count}"
                        | translate: { count: formGroup.controls["numberControl"].errors?.maxlength?.requiredLength }
                    }}
                  </mat-error>
                }
                @if (formGroup.controls["numberControl"].hasError("minlength")) {
                  <mat-error>
                    {{
                      "minimum length should be {count}"
                        | translate: { count: formGroup.controls["numberControl"].errors?.minlength?.requiredLength }
                    }}
                  </mat-error>
                }
                @if (formGroup.controls["numberControl"].hasError("max")) {
                  <mat-error>
                    {{
                      "maximum value limit is {count}"
                        | translate: { count: formGroup.controls["numberControl"].errors?.max?.max }
                    }}
                  </mat-error>
                }
                @if (formGroup.controls["numberControl"].hasError("min")) {
                  <mat-error>
                    {{
                      "minimum value expected is {count}"
                        | translate: { count: formGroup.controls["numberControl"].errors?.min?.min }
                    }}
                  </mat-error>
                }
              </mat-form-field>
            } @else {
              @if (isChoiceField()) {
                <lib-data-formats-choice-field
                  [data]="data"
                  controlName="selectControl"
                  (changed)="returnValues()"
                  (delete)="deleteValue()"
                ></lib-data-formats-choice-field>
              } @else {
                @if (data.dataFormatType === valueDefinitionType.boolean) {
                  <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
                  <mat-form-field class="w-100" floatLabel="auto">
                    <mat-label>{{ data.data.type_details.placeholder }}</mat-label>
                    <mat-select
                      formControlName="booleanControl"
                      id="{{ data.id }}"
                      (selectionChange)="selectionChange($event)"
                    >
                      <div>
                        <mat-option [value]="true">{{ "Yes" | translate }}</mat-option>
                        <mat-option [value]="false">{{ "No" | translate }}</mat-option>
                      </div>
                    </mat-select>
                    <mat-hint>{{ data.data.hint }}</mat-hint>
                    @if (formGroup.controls["booleanControl"].hasError("required")) {
                      <mat-error>
                        {{ requiredErrorMsg }}
                      </mat-error>
                    }
                  </mat-form-field>
                  @if (formGroup.controls.conditionalInputControl.enabled) {
                    <ng-container *ngTemplateOutlet="conditionalFields"> </ng-container>
                  }
                } @else {
                  @if (data.dataFormatType === valueDefinitionType.date) {
                    <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
                    <mat-form-field class="w-100" floatLabel="auto">
                      <input
                        matInput
                        [matDatepicker]="picker"
                        formControlName="dateControl"
                        class="color-grey-900"
                        id="{{ data.id }}"
                        [placeholder]="data.data.type_details.placeholder ?? ''"
                      />
                      <mat-datepicker-toggle matSuffix [for]="picker">
                        <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                      <mat-hint>{{ data.data.hint }}</mat-hint>
                      @if (formGroup.controls["dateControl"].hasError("invalidDate")) {
                        <mat-error>{{ "invalid date" | translate }}</mat-error>
                      }
                      @if (formGroup.controls["dateControl"].hasError("required")) {
                        <mat-error>{{ "missing date" | translate }}</mat-error>
                      }
                    </mat-form-field>
                  } @else {
                    @if (data.dataFormatType === valueDefinitionType.tip) {
                      <lib-data-formats-tip [value]="data.data"></lib-data-formats-tip>
                    } @else {
                      @if (data.dataFormatType === valueDefinitionType.subtitle) {
                        <h4 class="mat-subtitle-2">
                          {{ data.data.type_details.value }}
                        </h4>
                      } @else {
                        @if (data.dataFormatType === valueDefinitionType.document) {
                          <lib-data-formats-document
                            [value]="data.data"
                            [itemType]="itemType"
                          ></lib-data-formats-document>
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    <!-- text area with validation -->
    <ng-template #textAreaWithValidation>
      @if (data.dataFormatType === valueDefinitionType.text_area_validation) {
        <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
        <mat-form-field class="w-100 superscript-hint" floatLabel="auto">
          <textarea
            rows="5"
            matInput
            #textArea
            [cdkTextareaAutosize]="true"
            [maxlength]="maxCharacterCount"
            formControlName="textAreaWithValidation"
            class="color-grey-900"
            [placeholder]="data.data.type_details.placeholder ?? ''"
            libStripNonPrintableCharacters
            id="{{ data.id }}"
            >{{ data.data.value?.substring(0, maxCharacterCount) }}</textarea
          >
          <mat-hint class="data-formats-header w-100 fx-row justify-space-between">
            <div>{{ data.data.hint }}</div>
            <div>{{ textArea.value.length }}/{{ maxCharacterCount }}</div>
          </mat-hint>
          @if (formGroup.controls["textAreaWithValidation"].hasError("required")) {
            <mat-error>
              {{ requiredErrorMsg }}
            </mat-error>
          }
          @if (formGroup.controls["textAreaWithValidation"].hasError("maxlength")) {
            <mat-error>
              {{
                "maximum length can be {count}"
                  | translate: { count: formGroup.controls["textAreaWithValidation"].errors?.maxlength?.requiredLength }
              }}
            </mat-error>
          }
          @if (formGroup.controls["textAreaWithValidation"].hasError("minlength")) {
            <mat-error>
              {{
                "minimum length should be {count}"
                  | translate: { count: formGroup.controls["textAreaWithValidation"].errors?.minlength?.requiredLength }
              }}
            </mat-error>
          }
        </mat-form-field>
      } @else {
        @if (data.dataFormatType === valueDefinitionType.text_rich) {
          <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
          <div class="rich-text-formfield">
            <div id="{{ data.id }}">
              <lib-rich-text-input
                [control]="editorControl"
                class="{{ data.id }}"
                [ngClass]="{
                  error: editorControl.touched && editorControl.invalid,
                }"
                [placeholder]="data.data.type_details.placeholder"
              ></lib-rich-text-input>
            </div>
            @if (editorControl.valid) {
              <mat-hint>{{ data.data.hint }}</mat-hint>
            }
            @if (editorControl.touched && editorControl.hasError("required")) {
              <mat-error>
                {{ requiredErrorMsg }}
              </mat-error>
            }
            @if (editorControl.touched && editorControl.hasError("maxlength")) {
              <mat-error>
                {{
                  "maximum length can be {count}"
                    | translate: { count: formGroup.controls["editorControl"].errors?.maxlength?.requiredLength }
                }}
              </mat-error>
            }
            @if (editorControl.touched && editorControl.hasError("minlength")) {
              <mat-error>
                {{
                  "minimum length should be {count}"
                    | translate: { count: formGroup.controls["editorControl"].errors?.minlength?.requiredLength }
                }}
              </mat-error>
            }
          </div>
        } @else {
          @if (data.dataFormatType === valueDefinitionType.text_simple) {
            <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
            <mat-form-field class="w-100" floatLabel="auto">
              <input
                matInput
                formControlName="textInputFormat"
                class="color-grey-900"
                id="{{ data.id }}"
                [placeholder]="data.data.type_details.placeholder ?? ''"
              />
              <mat-hint>{{ data.data.hint }}</mat-hint>
              @if (formGroup.controls["textInputFormat"].hasError("required")) {
                <mat-error>
                  {{ requiredErrorMsg }}
                </mat-error>
              }
              @if (formGroup.controls["textInputFormat"].hasError("maxlength")) {
                <mat-error>
                  {{
                    "maximum length can be {count}"
                      | translate: { count: formGroup.controls["textInputFormat"].errors?.maxlength?.requiredLength }
                  }}
                </mat-error>
              }
              @if (formGroup.controls["textInputFormat"].hasError("minlength")) {
                <mat-error>
                  {{
                    "minimum length should be {count}"
                      | translate: { count: formGroup.controls["textInputFormat"].errors?.minlength?.requiredLength }
                  }}
                </mat-error>
              }
            </mat-form-field>
          } @else {
            @if (
              data.dataFormatType === valueDefinitionType.number ||
              data.dataFormatType === valueDefinitionType.calculated
            ) {
              <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
              <mat-form-field class="w-100 m-0" floatLabel="auto">
                <input
                  matInput
                  formControlName="numberControl"
                  value="{{ data.data.value }}"
                  class="color-grey-900"
                  id="{{ data.id }}"
                  (input)="roundOff($event)"
                  [placeholder]="data.data.type_details.placeholder ?? ''"
                />
                <div matSuffix class="color-grey-900">{{ symbol }}</div>
                <mat-hint>{{ data.data.hint }}</mat-hint>
                @if (
                  !formGroup.controls["numberControl"].hasError("required") &&
                  (formGroup.controls["numberControl"].hasError("pattern") ||
                    formGroup.controls["numberControl"].hasError("notNumeric"))
                ) {
                  <mat-error>
                    {{ numberValidationErrorMsg }}
                    {{ "decimal" | translate }}
                  </mat-error>
                }
                @if (formGroup.controls["numberControl"].hasError("required")) {
                  <mat-error>
                    {{ requiredErrorMsg }}
                  </mat-error>
                }
                @if (formGroup.controls["numberControl"].hasError("maxlength")) {
                  <mat-error>
                    {{
                      "maximum length can be {count}"
                        | translate: { count: formGroup.controls["numberControl"].errors?.maxlength?.requiredLength }
                    }}
                  </mat-error>
                }
                @if (formGroup.controls["numberControl"].hasError("minlength")) {
                  <mat-error>
                    {{
                      "minimum length should be {count}"
                        | translate: { count: formGroup.controls["numberControl"].errors?.minlength?.requiredLength }
                    }}
                  </mat-error>
                }
                @if (formGroup.controls["numberControl"].hasError("max")) {
                  <mat-error>
                    {{
                      "maximum value limit is {count}"
                        | translate: { count: formGroup.controls["numberControl"].errors?.max?.max }
                    }}
                  </mat-error>
                }
                @if (formGroup.controls["numberControl"].hasError("min")) {
                  <mat-error>
                    {{
                      "minimum value expected is {count}"
                        | translate: { count: formGroup.controls["numberControl"].errors?.min?.min }
                    }}
                  </mat-error>
                }
              </mat-form-field>
            } @else {
              @if (isChoiceField()) {
                <lib-data-formats-choice-field
                  [data]="data"
                  controlName="selectControl"
                  (changed)="returnValues()"
                  (delete)="deleteValue()"
                ></lib-data-formats-choice-field>
              } @else {
                @if (data.dataFormatType === valueDefinitionType.boolean) {
                  <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
                  <mat-form-field class="w-100" floatLabel="auto">
                    <mat-label>{{ data.data.type_details.placeholder }}</mat-label>
                    <mat-select
                      formControlName="booleanControl"
                      id="{{ data.id }}"
                      (selectionChange)="selectionChange($event)"
                    >
                      <div>
                        <mat-option [value]="true">{{ "Yes" | translate }}</mat-option>
                        <mat-option [value]="false">{{ "No" | translate }}</mat-option>
                      </div>
                    </mat-select>
                    <mat-hint>{{ data.data.hint }}</mat-hint>
                    @if (formGroup.controls["booleanControl"].hasError("required")) {
                      <mat-error>
                        {{ requiredErrorMsg }}
                      </mat-error>
                    }
                  </mat-form-field>
                  @if (formGroup.controls.conditionalInputControl.enabled) {
                    <ng-container *ngTemplateOutlet="conditionalFields"> </ng-container>
                  }
                } @else {
                  @if (data.dataFormatType === valueDefinitionType.date) {
                    <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
                    <mat-form-field class="w-100" floatLabel="auto">
                      <input
                        matInput
                        [matDatepicker]="picker"
                        formControlName="dateControl"
                        class="color-grey-900"
                        id="{{ data.id }}"
                        [placeholder]="data.data.type_details.placeholder ?? ''"
                      />
                      <mat-datepicker-toggle matSuffix [for]="picker">
                        <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                      <mat-hint>{{ data.data.hint }}</mat-hint>
                      @if (formGroup.controls["dateControl"].hasError("invalidDate")) {
                        <mat-error>{{ "invalid date" | translate }}</mat-error>
                      }
                      @if (formGroup.controls["dateControl"].hasError("required")) {
                        <mat-error>{{ "missing date" | translate }}</mat-error>
                      }
                    </mat-form-field>
                  } @else {
                    @if (data.dataFormatType === valueDefinitionType.tip) {
                      <lib-data-formats-tip [value]="data.data"></lib-data-formats-tip>
                    } @else {
                      @if (data.dataFormatType === valueDefinitionType.subtitle) {
                        <h4 class="mat-subtitle-2">
                          {{ data.data.type_details.value }}
                        </h4>
                      } @else {
                        @if (data.dataFormatType === valueDefinitionType.document) {
                          <lib-data-formats-document
                            [value]="data.data"
                            [itemType]="itemType"
                          ></lib-data-formats-document>
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    </ng-template>

    <!-- rich text format -->

    <!-- text input -->
    <ng-template #textInputFormat>
      @if (data.dataFormatType === valueDefinitionType.text_simple) {
        <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
        <mat-form-field class="w-100" floatLabel="auto">
          <input
            matInput
            formControlName="textInputFormat"
            class="color-grey-900"
            id="{{ data.id }}"
            [placeholder]="data.data.type_details.placeholder ?? ''"
          />
          <mat-hint>{{ data.data.hint }}</mat-hint>
          @if (formGroup.controls["textInputFormat"].hasError("required")) {
            <mat-error>
              {{ requiredErrorMsg }}
            </mat-error>
          }
          @if (formGroup.controls["textInputFormat"].hasError("maxlength")) {
            <mat-error>
              {{
                "maximum length can be {count}"
                  | translate: { count: formGroup.controls["textInputFormat"].errors?.maxlength?.requiredLength }
              }}
            </mat-error>
          }
          @if (formGroup.controls["textInputFormat"].hasError("minlength")) {
            <mat-error>
              {{
                "minimum length should be {count}"
                  | translate: { count: formGroup.controls["textInputFormat"].errors?.minlength?.requiredLength }
              }}
            </mat-error>
          }
        </mat-form-field>
      } @else {
        @if (
          data.dataFormatType === valueDefinitionType.number || data.dataFormatType === valueDefinitionType.calculated
        ) {
          <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
          <mat-form-field class="w-100 m-0" floatLabel="auto">
            <input
              matInput
              formControlName="numberControl"
              value="{{ data.data.value }}"
              class="color-grey-900"
              id="{{ data.id }}"
              (input)="roundOff($event)"
              [placeholder]="data.data.type_details.placeholder ?? ''"
            />
            <div matSuffix class="color-grey-900">{{ symbol }}</div>
            <mat-hint>{{ data.data.hint }}</mat-hint>
            @if (
              !formGroup.controls["numberControl"].hasError("required") &&
              (formGroup.controls["numberControl"].hasError("pattern") ||
                formGroup.controls["numberControl"].hasError("notNumeric"))
            ) {
              <mat-error>
                {{ numberValidationErrorMsg }}
                {{ "decimal" | translate }}
              </mat-error>
            }
            @if (formGroup.controls["numberControl"].hasError("required")) {
              <mat-error>
                {{ requiredErrorMsg }}
              </mat-error>
            }
            @if (formGroup.controls["numberControl"].hasError("maxlength")) {
              <mat-error>
                {{
                  "maximum length can be {count}"
                    | translate: { count: formGroup.controls["numberControl"].errors?.maxlength?.requiredLength }
                }}
              </mat-error>
            }
            @if (formGroup.controls["numberControl"].hasError("minlength")) {
              <mat-error>
                {{
                  "minimum length should be {count}"
                    | translate: { count: formGroup.controls["numberControl"].errors?.minlength?.requiredLength }
                }}
              </mat-error>
            }
            @if (formGroup.controls["numberControl"].hasError("max")) {
              <mat-error>
                {{
                  "maximum value limit is {count}"
                    | translate: { count: formGroup.controls["numberControl"].errors?.max?.max }
                }}
              </mat-error>
            }
            @if (formGroup.controls["numberControl"].hasError("min")) {
              <mat-error>
                {{
                  "minimum value expected is {count}"
                    | translate: { count: formGroup.controls["numberControl"].errors?.min?.min }
                }}
              </mat-error>
            }
          </mat-form-field>
        } @else {
          @if (isChoiceField()) {
            <lib-data-formats-choice-field
              [data]="data"
              controlName="selectControl"
              (changed)="returnValues()"
              (delete)="deleteValue()"
            ></lib-data-formats-choice-field>
          } @else {
            @if (data.dataFormatType === valueDefinitionType.boolean) {
              <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
              <mat-form-field class="w-100" floatLabel="auto">
                <mat-label>{{ data.data.type_details.placeholder }}</mat-label>
                <mat-select
                  formControlName="booleanControl"
                  id="{{ data.id }}"
                  (selectionChange)="selectionChange($event)"
                >
                  <div>
                    <mat-option [value]="true">{{ "Yes" | translate }}</mat-option>
                    <mat-option [value]="false">{{ "No" | translate }}</mat-option>
                  </div>
                </mat-select>
                <mat-hint>{{ data.data.hint }}</mat-hint>
                @if (formGroup.controls["booleanControl"].hasError("required")) {
                  <mat-error>
                    {{ requiredErrorMsg }}
                  </mat-error>
                }
              </mat-form-field>
              @if (formGroup.controls.conditionalInputControl.enabled) {
                <ng-container *ngTemplateOutlet="conditionalFields"> </ng-container>
              }
            } @else {
              @if (data.dataFormatType === valueDefinitionType.date) {
                <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
                <mat-form-field class="w-100" floatLabel="auto">
                  <input
                    matInput
                    [matDatepicker]="picker"
                    formControlName="dateControl"
                    class="color-grey-900"
                    id="{{ data.id }}"
                    [placeholder]="data.data.type_details.placeholder ?? ''"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker">
                    <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-hint>{{ data.data.hint }}</mat-hint>
                  @if (formGroup.controls["dateControl"].hasError("invalidDate")) {
                    <mat-error>{{ "invalid date" | translate }}</mat-error>
                  }
                  @if (formGroup.controls["dateControl"].hasError("required")) {
                    <mat-error>{{ "missing date" | translate }}</mat-error>
                  }
                </mat-form-field>
              } @else {
                @if (data.dataFormatType === valueDefinitionType.tip) {
                  <lib-data-formats-tip [value]="data.data"></lib-data-formats-tip>
                } @else {
                  @if (data.dataFormatType === valueDefinitionType.subtitle) {
                    <h4 class="mat-subtitle-2">
                      {{ data.data.type_details.value }}
                    </h4>
                  } @else {
                    @if (data.dataFormatType === valueDefinitionType.document) {
                      <lib-data-formats-document [value]="data.data" [itemType]="itemType"></lib-data-formats-document>
                    }
                  }
                }
              }
            }
          }
        }
      }
    </ng-template>

    <!-- number Format -->

    <!-- choice field -->

    <!-- boolean format -->
    <ng-template #boolean>
      @if (data.dataFormatType === valueDefinitionType.boolean) {
        <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
        <mat-form-field class="w-100" floatLabel="auto">
          <mat-label>{{ data.data.type_details.placeholder }}</mat-label>
          <mat-select formControlName="booleanControl" id="{{ data.id }}" (selectionChange)="selectionChange($event)">
            <div>
              <mat-option [value]="true">{{ "Yes" | translate }}</mat-option>
              <mat-option [value]="false">{{ "No" | translate }}</mat-option>
            </div>
          </mat-select>
          <mat-hint>{{ data.data.hint }}</mat-hint>
          @if (formGroup.controls["booleanControl"].hasError("required")) {
            <mat-error>
              {{ requiredErrorMsg }}
            </mat-error>
          }
        </mat-form-field>
        @if (formGroup.controls.conditionalInputControl.enabled) {
          <ng-container *ngTemplateOutlet="conditionalFields"> </ng-container>
        }
      } @else {
        @if (data.dataFormatType === valueDefinitionType.date) {
          <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
          <mat-form-field class="w-100" floatLabel="auto">
            <input
              matInput
              [matDatepicker]="picker"
              formControlName="dateControl"
              class="color-grey-900"
              id="{{ data.id }}"
              [placeholder]="data.data.type_details.placeholder ?? ''"
            />
            <mat-datepicker-toggle matSuffix [for]="picker">
              <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-hint>{{ data.data.hint }}</mat-hint>
            @if (formGroup.controls["dateControl"].hasError("invalidDate")) {
              <mat-error>{{ "invalid date" | translate }}</mat-error>
            }
            @if (formGroup.controls["dateControl"].hasError("required")) {
              <mat-error>{{ "missing date" | translate }}</mat-error>
            }
          </mat-form-field>
        } @else {
          @if (data.dataFormatType === valueDefinitionType.tip) {
            <lib-data-formats-tip [value]="data.data"></lib-data-formats-tip>
          } @else {
            @if (data.dataFormatType === valueDefinitionType.subtitle) {
              <h4 class="mat-subtitle-2">
                {{ data.data.type_details.value }}
              </h4>
            } @else {
              @if (data.dataFormatType === valueDefinitionType.document) {
                <lib-data-formats-document [value]="data.data" [itemType]="itemType"></lib-data-formats-document>
              }
            }
          }
        }
      }
    </ng-template>

    <!--date picker format-->

    <!--tip-->
    <ng-template #tip>
      @if (data.dataFormatType === valueDefinitionType.tip) {
        <lib-data-formats-tip [value]="data.data"></lib-data-formats-tip>
      } @else {
        @if (data.dataFormatType === valueDefinitionType.subtitle) {
          <h4 class="mat-subtitle-2">
            {{ data.data.type_details.value }}
          </h4>
        } @else {
          @if (data.dataFormatType === valueDefinitionType.document) {
            <lib-data-formats-document [value]="data.data" [itemType]="itemType"></lib-data-formats-document>
          }
        }
      }
    </ng-template>

    <!--subtitle-->
    <ng-template #label>
      @if (data.dataFormatType === valueDefinitionType.subtitle) {
        <h4 class="mat-subtitle-2">
          {{ data.data.type_details.value }}
        </h4>
      } @else {
        @if (data.dataFormatType === valueDefinitionType.document) {
          <lib-data-formats-document [value]="data.data" [itemType]="itemType"></lib-data-formats-document>
        }
      }
    </ng-template>

    <!--Document type-->
    <ng-template #document>
      @if (data.dataFormatType === valueDefinitionType.document) {
        <lib-data-formats-document [value]="data.data" [itemType]="itemType"></lib-data-formats-document>
      }
    </ng-template>

    <!-- Conditional fields -->
    <ng-template #conditionalFields>
      <div class="formfield required ml-4">
        <div
          class="label"
          [ngClass]="{
            'color-error':
              formGroup.controls['conditionalInputControl'].touched &&
              formGroup.controls['conditionalInputControl'].invalid,
          }"
        >
          {{ "Please specify" | translate }}
        </div>
        <mat-form-field>
          <input matInput formControlName="conditionalInputControl" />
          @if (formGroup.controls["conditionalInputControl"].hasError("required")) {
            <mat-error>{{ "Required" | translate }}</mat-error>
          }
        </mat-form-field>
      </div>
    </ng-template>
  </form>
</div>
