import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-number-badge',
  templateUrl: './number-badge.component.html',
  styleUrls: ['./number-badge.component.scss'],
})
export class NumberBadgeComponent {
  @Input({ required: true }) value!: string | number;
  @Input() size: 'large' | 'xlarge' | undefined;
  @Input() disabled: boolean = false;
  @Input() severity:
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'help'
    | 'primary'
    | 'secondary'
    | 'contrast'
    | null
    | undefined;
}
