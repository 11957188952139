@if (label && control) {
  <lib-form-field-label [label]="label" [control]="control" [for]="_inputId"></lib-form-field-label>
}
@if (control) {
  <mat-radio-group
    [formControl]="control"
    [name]="_inputId"
    [attr.aria-labelledby]="_inputId"
    [id]="_inputId"
    color="primary"
    [ngClass]="{ 'fx-col': column }"
    data-testid="radio-group-test"
  >
    @for (choice of choices; track choice) {
      <mat-radio-button
        [value]="choice.value"
        class="radio-button"
        [ngClass]="{ 'mr-3': !column }"
        (change)="choiceSelection.emit($event.value)"
      >
        {{ choice.label }}
      </mat-radio-button>
    }
  </mat-radio-group>
}
