<mat-toolbar class="page-context-bar" [ngClass]="{ 'pt-2': anchorName }">
  @if (anchorName) {
    <nav>
      <a (click)="anchorClick.emit()" class="anchor" role="tab">
        <mat-icon svgIcon="arrow-head-left"></mat-icon>
        {{ anchorName }}
      </a>
    </nav>
  }

  <div class="page-context-bar-content" [ngClass]="{ 'pt-0 pb-2': !title }">
    @if (title) {
      <div class="title-container" [ngClass]="{ separator: withSeparator }">
        <h9 [matTooltip]="title" class="page-name">
          {{ title }}
        </h9>
        @if (withSignOut) {
          <a (click)="authService.logout()" class="anchor">
            <mat-icon class="mat-icon notranslate icon-sm mr-1" [svgIcon]="'arrow-full-right'"></mat-icon>
            {{ "Sign Out" | translate }}
          </a>
        }
      </div>
    }

    <div class="subtitle-container">
      @if (subtitle) {
        <div>
          {{ subtitle }}
        </div>
      }

      @if (ctaText) {
        <div>
          <a (click)="ctaClick.emit()">
            @if (ctaIcon) {
              <mat-icon class="mat-icon notranslate icon-sm mr-1" [svgIcon]="ctaIcon"></mat-icon>
            }
            {{ ctaText }}
          </a>
        </div>
      }
    </div>

    @if (withSettingsContext) {
      @if (data$ | async; as data) {
        <div class="settings-container">
          <lib-filter
            [buttonProps]="{
              icon: 'calendar',
              title: 'FY{fiscalYear}' | translate: { fiscalYear: data.settings.fiscalYear.title },
            }"
            [optionProps]="{
              options: data.fiscalYears,
              activeOption: data.settings.fiscalYear,
              defaultValue: { id: currentYear, title: currentYear },
              defaultValueTitle: 'Current ({currentYear})' | translate: { currentYear },
              title: 'Fiscal year' | translate,
            }"
            (optionSelected)="onFiscalYearChange(data.settings, $event)"
          ></lib-filter>
          <lib-filter
            [buttonProps]="{ icon: 'location', title: data.settings.source.title }"
            [optionProps]="{
              options: data.sources,
              activeOption: data.settings.source,
              title: 'Sources' | translate,
            }"
            (optionSelected)="onSourceChange(data.settings, $event)"
          ></lib-filter>
        </div>
      }
    }
  </div>
</mat-toolbar>
