@if (control) {
  <div libFormField [required]="required" [control]="control" [labelPosition]="labelPosition">
    <lib-form-field-label [label]="label" [control]="control" [id]="_inputId"></lib-form-field-label>
    @if (control) {
      <mat-form-field class="w-100" floatLabel="auto">
        @if (placeholder) {
          <mat-label>{{ placeholder }}</mat-label>
        }
        <mat-select
          [id]="_inputId"
          [aria-labelledby]="_inputId"
          [attr.name]="_inputId"
          [compareWith]="$any(compareFn)"
          [formControl]="control"
          panelClass="form-select-panel"
          (opened)="setFocusOnSearch()"
          (openedChange)="openedChange.emit($event)"
          #focusElement
          libFocusState
        >
          @if (searchable && (inputControl.value || (options.length || 0) >= minMenuScrollItems)) {
            <div class="select-search fx-row align-center" (click)="$event.stopPropagation()">
              <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
              <input
                #matSelectPanelInput
                tabIndex="1"
                autocomplete="off"
                [placeholder]="'Search' | translate"
                [formControl]="inputControl"
              />
              @if (inputControl.value) {
                <button class="search–clear-button" (click)="inputControl.setValue('')">
                  <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
                </button>
              }
            </div>
          }
          <div class="options-container" (libScrollToBottom)="loadMoreOptions()">
            @for (option of options; track option) {
              <mat-option
                class="multiline-mat-option"
                [value]="option"
                [ngClass]="{ active: control.value === option, category: $any(option)?.isLabel ?? false }"
                [disabled]="$any(option)?.disabled ?? $any(option)?.isLabel ?? false"
              >
                {{ option | executeFunctionOnValue: bindOptionLabelFn }}
              </mat-option>
              @for (child of $any(option).children; track child) {
                <mat-option
                  class="multiline-mat-option pl-5"
                  [value]="child"
                  [ngClass]="{ active: control.value === child }"
                >
                  {{ child | executeFunctionOnValue: bindOptionLabelFn }}
                </mat-option>
                @for (subChild of child["children"]; track subChild) {
                  <mat-option
                    class="multiline-mat-option pl-10"
                    [value]="subChild"
                    [ngClass]="{ active: control.value === subChild }"
                  >
                    {{ subChild | executeFunctionOnValue: bindOptionLabelFn }}
                  </mat-option>
                }
              }
            }
            @if (!options.length && !isLoadingOptions) {
              <mat-option>{{ "No results found" | translate }}</mat-option>
            }
            @if (isLoadingOptions) {
              <mat-option data-testid="is-loading-options-spinner">
                <lib-spinner></lib-spinner>
              </mat-option>
            }
          </div>
        </mat-select>
        @if (hint) {
          <mat-hint>{{ hint }}</mat-hint>
        }
        @for (errorKey of parentControl?.errors ?? null | errorKeys; track errorKey) {
          <mat-hint class="mat-error">{{ errorMessages[errorKey] }}</mat-hint>
        }
        @for (error of control.errors | errorKeys; track error) {
          <mat-error> {{ errorMessages[error] }}</mat-error>
        }
      </mat-form-field>
    }
  </div>
}
