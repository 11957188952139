<div
  class="choice-radio-field"
  tabindex="0"
  data-testid="radioFormField"
  [ngClass]="{
    disabled: control.disabled,
    error: control.touched && control.invalid,
  }"
  #focusElement
>
  <div class="choice-radio-field-wrapper">
    @if ((control.valid || control.untouched) && hint) {
      <mat-hint>{{ hint }}</mat-hint>
    }
    @if (control.touched && control.invalid) {
      @for (error of control.errors | errorKeys; track error) {
        <mat-error> {{ errorMessages[error] }}</mat-error>
      }
    }
    <lib-radio-buttons
      data-testid="choiceRadio"
      class="mat-body-2"
      [control]="control"
      [choices]="radioChoicesList"
      [column]="true"
    ></lib-radio-buttons>
  </div>
</div>
