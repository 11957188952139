@if (label !== "") {
  <label class="form-field-label label" [id]="id" [for]="for" [ngClass]="combinedClasses" data-testid="formFieldLabel">
    <span>{{ label }}</span>
    <div class="icon-container">
      @if (fieldInfo) {
        <mat-icon
          data-testid="labelInfoIcon"
          svgIcon="info"
          class="icon-md color-primary-900 ml-1"
          libTooltip
          [tooltip]="tooltip"
        ></mat-icon>
      }
      @if (displayDeleteIcon()) {
        <a (click)="deleteValue()" data-testid="deleteIcon" class="pointer-event">
          <mat-icon class="field-clear icon-md ml-1" svgIcon="frequency"></mat-icon>
        </a>
      }
    </div>
  </label>
}

<ng-template #tooltip>
  <div class="tooltip">
    <div class="h-13 w-13">
      <mat-icon svgIcon="info" class="tip-icon color-primary-900 ml-2"></mat-icon>
    </div>
    <div class="tooltip-text">{{ fieldInfo }}</div>
  </div>
</ng-template>
