<lib-dialog-title (closeEvent)="close()" [title]="title"></lib-dialog-title>
<div mat-dialog-content class="pt-4 pb-1">
  @if (warningMsg) {
    <lib-dialog-warning>{{ warningMsg }}</lib-dialog-warning>
  }
  <lib-tag-associations
    [tagAssociations]="tag.associations"
    [containerClass]="['label', 'associations-list']"
  ></lib-tag-associations>
</div>
<lib-dialog-info-actions (confirmEvent)="confirm()"></lib-dialog-info-actions>
