<div mat-dialog-title [ngClass]="{ 'error-header': error, 'm-0': removeMargin }">
  <div class="fx-row justify-space-between w-100">
    <header class="title-header">
      @if (hasGoBackButton) {
        <a (click)="onGoBack()" class="go-back-button" data-testid="go-back-button">
          <mat-icon svgIcon="arrow-full-left" class="go-back-icon"></mat-icon>
        </a>
      }
      <div>
        <div role="heading" class="header-text mat-h3 mat-primary" [style]="{ maxWidth: maxWidth }">{{ title }}</div>
        @if (subtitle) {
          <div class="subtitle mat-body-1">{{ subtitle }}</div>
        }
      </div>
    </header>

    @if (!modal && !isOfflineDialog) {
      <button lib-button buttonType="cancel" (click)="onClose()" data-testid="close-button">
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    }
  </div>

  <div class="tabs mat-body-1">
    @for (tab of tabs; track tab) {
      <a [attr.data-testid]="'tab-' + tab.id" (click)="switchTab(tab)" [ngClass]="{ active: selectedTab === tab }">{{
        tab.title
      }}</a>
    }
  </div>
</div>
