<lib-dialog-title
  [title]="'Add Novisto framework taxonomy' | translate"
  (closeEvent)="closeDialog()"
></lib-dialog-title>

<div mat-dialog-content>
  @if (data$ | async; as data) {
    @if (data.tableFilters.length) {
      <lib-filter-bar
        [secondaryFilters]="data.tableFilters"
        [withSearchBar]="true"
        [searchBarPlaceholder]="'Search framework taxonomies' | translate"
        (onSearchChanged)="onSearchChange($event)"
        (onFilterChanged)="onFilterChange($event)"
      ></lib-filter-bar>
    }
    <div class="full-content">
      @if (!data.isLoading) {
        <lib-data-table-remote-data
          [tableColumns]="tableColumns"
          [tableData]="data.taxonomies"
          [paginationConfig]="data.paginationConfig"
          [isPaginable]="false"
          [isShowingLoadingAnimation]="data.isLoadingNextPage"
          [enableRowSelection]="true"
          [selectAllOption]="false"
          [selections]="selections"
          (checkChanged)="onCheckChanged($event)"
          (scrolledToBottom)="onLoadNextPage(data.isLoadingNextPage, data.paginationConfig.total, data.count)"
        >
        </lib-data-table-remote-data>
      } @else {
        <lib-dummy class="w-100" [presentation]="ePresentation.matTable" data-testid="dummy-matTable"></lib-dummy>
      }
    </div>
  }
</div>

<div mat-dialog-actions>
  <button lib-button buttonType="link" (click)="closeDialog()">{{ "Cancel" | translate }}</button>
  <button
    lib-button
    buttonType="primary"
    class="ml-2"
    (click)="save()"
    [disabled]="!form.controls.taxonomies.value.length"
  >
    {{ "Add codes" | translate }}
  </button>
</div>
