@if (content.data.length || content.loading) {
  <div
    class="data-list-wrapper mb-2 mt-2"
    data-testid="data-list-remote-scrollable-div"
    (libScrollToBottom)="loadMoreOptions()"
  >
    @for (item of content.data; track item) {
      <div
        class="card-container mb-1"
        data-testid="data-list-remote-row"
        (click)="onSelectRow(item)"
        [ngClass]="{ active: content.selected | includes: item }"
      >
        <a class="card presentation-card">
          <div class="card-definition">
            <div class="card-definition-content">
              @if (dataSubtitleKey) {
                <div class="mat-small color-grey-800">{{ item | get: dataSubtitleKey }}</div>
              }
              @if (dataTitleKey) {
                <div class="mat-body-1 color-primary-900">{{ item | get: dataTitleKey }}</div>
              }
              <ng-container *ngTemplateOutlet="dataFooterTemplate; context: { element: item }"></ng-container>
            </div>
          </div>
        </a>
      </div>
    }
    @if (content.loading) {
      <div class="spinner" data-testid="data-list-remote-spinner">
        <lib-spinner [diameter]="20"></lib-spinner>
      </div>
    }
  </div>
} @else {
  <lib-empty-results [displayData]="emptyResult"></lib-empty-results>
}
