@if (control) {
  <div
    libFormField
    [size]="size"
    [required]="required"
    [control]="control"
    [readonly]="readonly"
    [labelPosition]="labelPosition"
    [dataTestId]="'multiSelectChipInputFormField'"
  >
    <lib-form-field-label [label]="label" [control]="control" [for]="_inputId"></lib-form-field-label>
    <mat-form-field>
      <mat-chip-grid #chipList aria-label="chipList">
        @for (item of selectedOptions; track item) {
          <mat-chip-row
            [ngClass]="{ 'existing-option': options | includes: item }"
            [removable]="!readonly"
            [disabled]="control.disabled"
            (removed)="removeValue(getOptionValue(item))"
          >
            <div class="choice-content" [title]="getOptionLabel(item)">{{ getOptionLabel(item) }}</div>
            @if (!readonly) {
              <mat-icon matChipRemove svgIcon="close" data-testid="chipRemoveIcon"></mat-icon>
            }
          </mat-chip-row>
        }
        <input
          [readonly]="readonly"
          #input
          [id]="_inputId"
          [formControl]="inputControl"
          [matAutocomplete]="autocomplete"
          [matChipInputAddOnBlur]="false"
          [matChipInputFor]="chipList"
          (matChipInputTokenEnd)="_addValue($event)"
          libFocusState
          [focusStateControl]="control"
        />
      </mat-chip-grid>
      <mat-autocomplete
        #autocomplete="matAutocomplete"
        (optionSelected)="selectOption($event)"
        aria-label="autocomplete"
      >
        @for (item of filteredOptions$ | async; track item) {
          <mat-option [value]="getOptionValue(item)" [attr.aria-label]="getOptionLabel(item)">
            {{ getOptionLabel(item) }}
          </mat-option>
        }
      </mat-autocomplete>
      <mat-hint>
        @if ((control.untouched || control.valid) && hint && inputControl.valid) {
          {{ hint }}
        }
        @if (inputControl.invalid) {
          @for (error of inputControl.errors | errorKeys; track error) {
            <span class="color-error"> {{ errorMessages[error] }}</span>
          }
        }
        @if (control.touched && control.invalid) {
          @for (error of control.errors | errorKeys; track error) {
            <span class="color-error"> {{ errorMessages[error] }}</span>
          }
        }
      </mat-hint>
    </mat-form-field>
  </div>
}
