<div class="header-wrapper">
  <div
    class="header-content arrow-color"
    [mat-sort-header]="tableColumn.name"
    [arrowPosition]="tableColumn.position === 'right' ? 'before' : 'after'"
  >
    <ng-content></ng-content>
  </div>
  @if (isResizable) {
    <div class="resizable-bar" (libHorizontalResizable)="onResize($event)"></div>
  }
</div>
