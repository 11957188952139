@if (isHtmlText) {
  <div class="expandable-text-container">
    <div
      #textContent
      [ngClass]="{ 'text-break': !readMore }"
      [ngStyle]="{
        height: !readMore ? lineMaxHeight : '100%',
        'line-height': lineHeight + 'px',
      }"
      class="color-grey-900 text"
    >
      <div [innerHTML]="text | sanitizeHtml"></div>
    </div>
    @if (showExpandable || readMore) {
      <a class="read-more-link" (click)="readMore = !readMore; $event.stopPropagation()">
        {{ (!readMore ? "Show more" : "Show less") | translate }}
      </a>
    }
  </div>
} @else {
  <div class="break-word">
    @if (text.length > charactersToShow) {
      <div #expandableText>
        {{ expandableText.classList.contains("expanded") ? text : text.slice(0, charactersToShow) + "..." }}
        <button lib-button (click)="expandableText.classList.toggle('expanded')" buttonType="link" class="nowrap">
          {{ (expandableText.classList.contains("expanded") ? "Show less" : "Show more") | translate }}
        </button>
      </div>
    } @else {
      {{ text }}
    }
  </div>
}
