<lib-dialog-title [title]="dialogTitle" (closeEvent)="closeDialog()"></lib-dialog-title>

<div mat-dialog-content class="panel-dialog full-width title-margin">
  <form [formGroup]="tableColumnDefinitionForm" class="form">
    <div class="large fx-row pl-6">
      <lib-text-input
        class="formfield medium required newline w-40"
        [label]="'Column Header' | translate"
        [control]="tableColumnDefinitionForm.controls.label"
        [hint]="'Required' | translate"
        [placeholder]="'Column Header' | translate"
        [messages]="{ isUnique: 'Column label must be unique.' | translate }"
        [trimWhitespaceMethod]="eTrimMethod.full"
      ></lib-text-input>
    </div>

    <ng-container class="pl-1">
      <div class="large pl-6">
        <div class="mt-5 large fx-row">
          <div class="w-25">
            <lib-dropdown-input
              [filter]="true"
              [filterBy]="'title'"
              [optionValue]="'id'"
              [optionLabel]="'title'"
              [label]="'Unit Family' | translate"
              [control]="tableColumnDefinitionForm.controls.family"
              [options]="unitFamilies"
              [hint]="'Select one' | translate"
            ></lib-dropdown-input>
          </div>

          <div class="pl-5 w-60">
            <lib-dropdown-input
              [filter]="true"
              [filterBy]="'title'"
              [optionValue]="'id'"
              [optionLabel]="'title'"
              [label]="'Unit default' | translate"
              [control]="tableColumnDefinitionForm.controls.unitDefault"
              [options]="unitDefaults"
              [hint]="'Select one' | translate"
            ></lib-dropdown-input>
          </div>
        </div>

        <div class="mt-5 large fx-row">
          <lib-select-input
            [ngClass]="{
              'pr-5 decimal-select-width': tableColumnDefinitionForm.controls.decimalValue,
            }"
            [label]="'Decimals' | translate"
            [control]="tableColumnDefinitionForm.controls.decimalValue"
            [options]="numericDecimalOptions"
            [hint]="'Select one' | translate"
          ></lib-select-input>
          <lib-text-input
            class="formfield medium newline w-25"
            [label]="'Minimum value' | translate"
            [control]="tableColumnDefinitionForm.controls.minimum"
            [hint]="'Optional' | translate"
            type="number"
            [messages]="numberMinMaxErrorMsgs"
          ></lib-text-input>
          <lib-text-input
            class="formfield medium newline input-width pl-5"
            [label]="'Maximum value' | translate"
            [control]="tableColumnDefinitionForm.controls.maximum"
            [hint]="'Optional' | translate"
            type="number"
            [messages]="numberMinMaxErrorMsgs"
          ></lib-text-input>
        </div>
        @if (isConsolidationEnabled(sourceConfiguration) && !isThirdParty()) {
          <div class="mt-2 large">
            <div class="w-75">
              <lib-select-input
                [label]="'Consolidation rule' | translate"
                [control]="tableColumnDefinitionForm.controls.consolidationRule"
                [options]="consolidationRuleOptions"
                (selectionChangeEvent)="setConsolidationOptions($event)"
                class="formfield medium required"
              ></lib-select-input>
            </div>
            @if (tableColumnDefinitionForm.controls.consolidationRule.value !== eConsolidationRules.manual) {
              <div class="w-75">
                <lib-select-input
                  [label]="'Consolidation trigger' | translate"
                  [control]="tableColumnDefinitionForm.controls.consolidationTrigger"
                  [options]="consolidationTriggerOptions"
                  class="formfield medium required"
                  (selectionChangeEvent)="setConsolidationParametersChanged()"
                ></lib-select-input>
              </div>
              @if (isSourceConfigurationInHierarchy(sourceConfiguration) && isBypassConsolidationEnabled()) {
                <div class="w-75">
                  <lib-multi-select-dropdown
                    [label]="'Consolidation bypass levels' | translate"
                    [control]="tableColumnDefinitionForm.controls.bypassConsolidationLevels"
                    [options]="consolidationBypassLevelOptions"
                    (selectChange)="handleBypassConsolidationLevelsSelect($event)"
                  ></lib-multi-select-dropdown>
                  @if (allLevelsSelectedMessage) {
                    <div>
                      {{ allLevelsSelectedMessage }}
                    </div>
                  }
                </div>
              }
              @if (areConsolidationParamsChanged && metricTableHasValues) {
                <div class="mt-2">
                  <lib-info-banner [type]="'warning'" [message]="calculationChangedMsg"></lib-info-banner>
                </div>
              }
            }
          </div>
        }
      </div>
    </ng-container>
  </form>
</div>

<div mat-dialog-actions>
  <a
    lib-button
    buttonType="success"
    (click)="save()"
    [disabled]="tableColumnDefinitionForm.pristine || tableColumnDefinitionForm.invalid"
    [isLoading]="!!(isMetricUpdating$ | async)"
    [label]="'Save' | translate"
  >
  </a>
  <button lib-button buttonType="link" class="ml-4" (click)="closeDialog()">{{ "Cancel" | translate }}</button>
</div>
