@if (control) {
  <div class="formfield">
    <lib-form-field-label [label]="label" [control]="control" [id]="_labelId"></lib-form-field-label>
    <mat-selection-list
      #matSelectionList
      class="listbox-wrapper"
      [formControl]="control"
      [attr.aria-labelledby]="_labelId"
      [ngClass]="{ 'color-error': control.touched && control.invalid, required: required, disabled: control.disabled }"
    >
      @if (withSelectAllOption) {
        <mat-list-option
          #selectAllOption
          class="all-option"
          color="primary"
          togglePosition="before"
          [value]="selectAllItem.id"
          (click)="onSelectAll(matSelectionList)"
        >
          {{ selectAllItem.title }}
        </mat-list-option>
      }
      <div class="scrollable-list">
        @for (option of options; track option) {
          <mat-list-option
            color="primary"
            togglePosition="before"
            [value]="option.id"
            [disabled]="option.disabled"
            (selectedChange)="onOptionSelected(matSelectionList)"
          >
            {{ option.title }}
          </mat-list-option>
        }
      </div>
    </mat-selection-list>
    @if (control.hasError("required")) {
      <mat-error class="error-message">{{ "Required" | translate }}</mat-error>
    }
  </div>
}
