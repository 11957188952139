export const HOME_ICON = 'logo';
export const REGISTER_ICONS = [
  'add',
  'attach',
  'clear',
  'close',
  'copy',
  'default',
  'download',
  'edit',
  'export',
  'import',
  'feedback',
  'link',
  'more',
  'preview',
  'search',
  'send',
  'share',
  'trash',
  'write',
  'show',
  'hide',
  'set-default',
  'rename',
  'restore',
  'lock',
  'factory',
  'factory-black-outline',
  'globe',
  'globe-black-outline',
] as const;
export const ARROWS_ICONS = [
  'arrow-corner',
  'arrow-filled-down',
  'arrow-full-down',
  'arrow-full-left',
  'arrow-full-right',
  'arrow-full-up',
  'arrow-head-down',
  'arrow-head-left',
  'arrow-head-right',
  'arrow-head-up',
  'collapse',
  'expand',
  'external',
  'external-link',
] as const;
export const EDITOR_ICONS = [
  'break',
  'choice',
  'condition',
  'condition-trigger',
  'data-table',
  'group',
  'number',
  'position',
  'rich-text',
  'section',
  'select',
  'text',
  'textarea',
  'width',
  'comments',
  'rejection-comments',
] as const;
export const FILTER_ICONS = [
  'activity',
  'baseline',
  'calendar',
  'filter',
  'frequency',
  'location',
  'sort',
  'sort-down',
  'sort-up',
  'tag',
  'unit',
] as const;
export const NAV_MENU_ICONS = ['menu', 'menu-close'] as const;
export const NAV_PRIMARY_ICONS = [
  'analyze',
  'build',
  'collect',
  'explore',
  'home',
  'learn',
  'monitor',
  'publish',
  'watch',
  'library',
  'reports',
  'templates',
  'library',
  'frameworks',
  'workflow',
] as const;
export const NAV_SECONDARY_ICONS = [
  'alerts',
  'help',
  'legal',
  'profile',
  'settings',
  'client',
  'people',
  'repeat',
  'calendar',
  'association',
] as const;
export const PARTNER_LOGIN_ICONS = ['green-project'] as const;
export const OTHER_ICONS = [
  'default',
  'context',
  'laptop-neutral',
  'laptop-dead',
  'laptop-happy',
  'laptop-sad',
  'laptop-stun',
  'loader',
  'vector',
] as const;
export const STATUS_ICONS = [
  'activate',
  'award',
  'check',
  'cloud',
  'deactivate',
  'draft',
  'empty',
  'error-filled',
  'error',
  'favorite-filled',
  'favorite',
  'info-filled',
  'info',
  'locked',
  'modified',
  'novisto',
  'pass',
  'pending',
  'reject',
  'save',
  'saved',
  'saved-filled',
  'status',
  'trend-down',
  'trend-up',
  'trending',
  'warning-filled',
  'warning',
] as const;
export const TABS_ICONS = [
  'benchmark',
  'collaborate',
  'guidance',
  'history',
  'note',
  'recommended',
  'related-fields',
  'target',
  'validation',
  'field-info',
  'group-1',
] as const;
export const TYPES_ICONS = [
  'api',
  'archive',
  'category',
  'custom',
  'doc',
  'event',
  'industry',
  'list',
  'metric',
  'organization',
  'pdf',
  'ppt',
  'report',
  'rfp',
  'scope',
  'standard',
  'survey',
  'table',
  'target',
  'template',
  'web',
  'xls',
] as const;
export const SSO_ICONS = ['microsoft', 'google', 'auth0', 'okta', 'generic', 'success'] as const;
export const LOGOS_ICONS = [
  'cdp',
  'dowjones',
  'enablon',
  'gri',
  'sasb',
  'tcfd',
  'workday',
  'passkey-white',
  'novisto-navy-logo',
  'novisto-circle-logo',
  'powered-by-logo',
  'powered-by-big',
] as const;
export const WIDGETS = ['single-data-point', 'stack-bar-chart', 'group-data-points'] as const;

const ALL_ICONS = [
  HOME_ICON,
  ...REGISTER_ICONS,
  ...ARROWS_ICONS,
  ...EDITOR_ICONS,
  ...FILTER_ICONS,
  ...NAV_MENU_ICONS,
  ...NAV_PRIMARY_ICONS,
  ...NAV_SECONDARY_ICONS,
  ...PARTNER_LOGIN_ICONS,
  ...OTHER_ICONS,
  ...STATUS_ICONS,
  ...TABS_ICONS,
  ...TYPES_ICONS,
  ...SSO_ICONS,
  ...LOGOS_ICONS,
  ...WIDGETS,
];
export default ALL_ICONS;
export type Icon = (typeof ALL_ICONS)[number];
