@if (control) {
  <div
    libFormField
    [size]="size"
    [required]="required"
    [control]="control"
    [labelPosition]="labelPosition"
    [dataTestId]="'cascade-select-input'"
    [class]="'p-field'"
  >
    <lib-form-field-label [label]="label" [control]="control" [id]="_labelId"></lib-form-field-label>
    <!-- There is a bug in this PrimeNG component, options is typed wrongly as string[] when it should receive an array of objects -->
    <p-cascadeSelect
      [ariaLabelledBy]="_labelId"
      [options]="$any(options)"
      [optionLabel]="optionLabel"
      [optionGroupLabel]="groupLabel"
      [optionGroupChildren]="optionGroupChildren"
      [formControl]="control"
      [id]="_labelId"
      [showClear]="showClear"
      [required]="required"
      [appendTo]="'body'"
      [ngClass]="{ invalid: control.invalid && (control.dirty || control.touched), disabled: control.disabled }"
      (onHide)="setToTouched()"
    >
      <ng-template pTemplate="value" let-value>
        @if (value) {
          <span class="input-text">{{ prefix ?? "" }} {{ value[optionLabel] }}</span>
        }
      </ng-template>
    </p-cascadeSelect>
    @if (!isFilter) {
      <div class="subscript-wrapper" data-testid="subscript-wrapper">
        @if ((hint && control.valid) || control.untouched) {
          <p class="message">
            {{ hint }}
          </p>
        }
        @if (control.dirty || control.touched) {
          @for (error of control.errors | errorKeys; track error) {
            <p id="error-message" class="p-error message">
              {{ errorMessages[error] }}
            </p>
          }
        }
      </div>
    }
  </div>
}
