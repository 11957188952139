<lib-item-card
  [item]="item"
  [menuItems]="menuItems"
  [disabled]="disabled"
  [menuDisabled]="menuDisabled"
  (menuAction)="menuAction.emit($event)"
>
  <ng-template #action>
    <div class="drag-handle"></div>
    <mat-icon svgIcon="vector" class="pi item-card-drag-icon" data-testid="itemCardDragHandle"></mat-icon>
  </ng-template>
  <ng-template #afterTitle>
    @if (afterTitleTmpl) {
      <ng-container [ngTemplateOutlet]="afterTitleTmpl" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
    }
  </ng-template>
  <ng-template #extraContent>
    @if (extraContentTmpl) {
      <ng-container
        [ngTemplateOutlet]="extraContentTmpl"
        [ngTemplateOutletContext]="{ $implicit: item }"
      ></ng-container>
    }
  </ng-template>
</lib-item-card>
