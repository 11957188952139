@if (templateReportSectionsLabelById$ | async; as templateReportSectionsLabelById) {
  <lib-panel-details-items
    [panelActions]="panelActions"
    [(activeTab)]="activeTab"
    [navigateBackOnPanelClose]="false"
    [loaded]="!!selectedItem || (activeTab === +eTemplateReportPanels.SECTION && !isEditing)"
    (isOpened)="closePanel.emit()"
  >
    @switch (activeTab) {
      @case (+eTemplateReportPanels.SETTINGS) {
        <lib-template-report-panel-settings
          [managePermissions]="managePermissions"
          [templateReport]="$any(selectedItem)"
          [templateReportId]="templateReportId"
          (cancelAction)="closePanel.emit()"
          (updateVersion)="updateVersion.emit($event)"
        ></lib-template-report-panel-settings>
      }
      @case (+eTemplateReportPanels.SECTION) {
        <lib-template-report-panel-section-form
          [managePermissions]="managePermissions"
          [templateReportId]="(templateReportId$ | async) ?? undefined"
          [templateReportVersionId]="(templateReportVersionId$ | async) ?? undefined"
          [templateReportSection]="$any(selectedItem)"
          [editingSection]="isEditing"
          [sectionsLabelById]="templateReportSectionsLabelById"
          (closePanel)="closePanel.emit()"
        ></lib-template-report-panel-section-form>
      }
      @case (+eTemplateReportPanels.SECTION_METRIC) {
        <lib-template-report-panel-section-metric-form
          [managePermissions]="managePermissions"
          [templateReportId]="(templateReportId$ | async) ?? undefined"
          [templateReportVersionId]="(templateReportVersionId$ | async) ?? undefined"
          [templateReportSectionMetric]="$any(selectedItem)"
          [editingMetric]="isEditing"
          [sectionsLabelById]="templateReportSectionsLabelById"
          [sourceConfiguration]="sourceConfiguration"
          (closePanel)="closePanel.emit()"
        ></lib-template-report-panel-section-metric-form>
      }
    }
  </lib-panel-details-items>
}
