<div
  class="card mb-1 selectable"
  [ngClass]="{ selected: selected, deactivated: deactivated }"
  (click)="cardSelect.emit()"
  data-testid="metricStatusCardContainer"
>
  <div class="fx-row justify-space-between pb-1">
    @if (displayIndicatorCode) {
      <div class="fx-row">
        <div [title]="indicatorCodeOverride ?? indicator?.code" class="color-grey-800 pr-1">
          {{ indicatorCodeOverride ?? indicator?.code }}
        </div>
        @if (important) {
          <mat-icon class="icon-md color-error" svgIcon="status" data-testid="importantFlag"></mat-icon>
        }
      </div>
    }
    @if (!displayIndicatorCode) {
      <div class="fx-row item-title mat-body-1 color-primary-900">
        {{ indicatorDescriptionOverride ?? indicator?.description }}
      </div>
    }

    @if (deactivated) {
      <div class="fx-row align-center color-grey-800">
        <span>{{ "deactivated" | translate }}</span>
      </div>
    } @else {
      @if (vgsetStatusValue) {
        <div class="fx-row align-center color-grey-800" [ngClass]="[vgsetStatusValue]">
          <mat-icon
            class="icon-md mr-1"
            [svgIcon]="vgsetStatusIcon"
            [title]="vgsetStatusIcon"
            data-testid="statusIcon"
          ></mat-icon>
          <span>{{ vgsStatusToText[vgsetStatusValue] }}</span>
        </div>
      }
    }

    <ng-template #status>
      @if (vgsetStatusValue) {
        <div class="fx-row align-center color-grey-800" [ngClass]="[vgsetStatusValue]">
          <mat-icon
            class="icon-md mr-1"
            [svgIcon]="vgsetStatusIcon"
            [title]="vgsetStatusIcon"
            data-testid="statusIcon"
          ></mat-icon>
          <span>{{ vgsStatusToText[vgsetStatusValue] }}</span>
        </div>
      }
    </ng-template>
  </div>
  <div class="fx-row justify-space-between align-center">
    @if (displayIndicatorCode) {
      <div class="fx-row justify-start item-title mat-body-2 color-primary-900">
        {{ indicatorDescriptionOverride ?? indicator?.description }}
      </div>
    }
    <div class="fx-row justify-end locked-icon" [class.w-100]="!displayIndicatorCode">
      @if (isLocked) {
        <mat-icon
          data-testid="lockedIcon"
          svgIcon="locked"
          title="{{ 'This metric is locked' | translate }}"
          aria-label="locked"
        ></mat-icon>
      }
    </div>
  </div>
</div>
