import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { finalize, take } from 'rxjs/operators';

import {
  ActionItem,
  ApiResponse,
  ConsolidationRules,
  Metric,
  MetricCategory,
  ValueDefinition,
  ValueDefinitionGroup,
  ValueDefinitionType,
} from '../../../../models';

import { Observable } from 'rxjs';
import { MetricStructureStateService } from '../../../services/metric-structure-state.service';
import { TranslateService } from '../../../../services/common';
import { MetricStructureGroupPropertiesForm } from './metric-structure-group-properties-form';
import { HttpErrorResponse } from '@angular/common/http';
import { MetricApiService } from '../../../../services/types';
import { ErrorManagerService } from '../../../../error-manager';
import { MetricStructureUtils } from '../../../classes/MetricStructureUtils/metric-structure-utils';
import { FeatureFlagService } from '../../../../feature-flag';

const DISABLED_FORM_PROPERTIES_FOR_REF_V2_VDG_FROM_CORE = [
  'label',
  'toggleSubtitle',
  'toggleRepeatable',
  'repeatLimit',
];

@Component({
  selector: 'lib-metric-structure-group-properties',
  templateUrl: './metric-structure-group-properties.component.html',
  styleUrls: ['./metric-structure-group-properties.component.scss'],
})
export class MetricStructureGroupPropertiesComponent implements OnInit, OnChanges, OnDestroy {
  @Input({ required: true }) valueDefinitionGroup!: ValueDefinitionGroup;
  @Input() formDisabled: boolean = false;

  @Output() closeGroup: EventEmitter<void> = new EventEmitter<void>();

  groupLabelMaxCharLimit: number = 500;
  metric?: Metric;
  groupForm?: MetricStructureGroupPropertiesForm;
  updating$: Observable<boolean> = this.metricStructureService.isMetricUpdating$;
  isGroupDeletionEnabled: boolean = false;
  isNewGroup: boolean = false;
  readonly indentOptions: ActionItem[] = ['0', '1', '2', '3', '4', '5'].map((option) => ({
    title: option,
    id: option,
  }));
  readonly repeatLimitErrorMsgs: ValidationErrors = {
    required: this.translateService.instant('Limit is required'),
    notNumeric: this.translateService.instant('Please enter a positive number'),
    isNot0OrGreaterThan1: this.translateService.instant(`The value can't be 1`),
  };

  readonly eMetricCategory: typeof MetricCategory = MetricCategory;

  constructor(
    private metricsService: MetricApiService,
    private metricStructureService: MetricStructureStateService,
    private translateService: TranslateService,
    private errorManagerService: ErrorManagerService,
    private featureFlagService: FeatureFlagService,
  ) {}

  ngOnInit(): void {
    this.metricStructureService.metric$.pipe(take(1)).subscribe((metric) => {
      this.metric = metric;
      this.loadGroupForm();
    });
    this.updateIsRepeatableGroup();
  }

  ngOnChanges(): void {
    this.loadGroupForm();
    this.isNewGroup = !this.valueDefinitionGroup.id.length;
    this.disableFormIfMetricGroupIsDeactivated();
    this.canDeleteGroup();
    this.updateIsRepeatableGroup();
  }

  private loadGroupForm(): void {
    this.groupForm = new MetricStructureGroupPropertiesForm(
      this.translateService,
      this.valueDefinitionGroup,
      this.groupLabelMaxCharLimit,
      this.getDisabledFormProperties(),
    );
  }

  private getDisabledFormProperties(): string[] {
    if (MetricStructureUtils.isEntityFromCoreRefV2Metric(this.valueDefinitionGroup, this.metric)) {
      return DISABLED_FORM_PROPERTIES_FOR_REF_V2_VDG_FROM_CORE;
    } else if (this.valueDefinitionGroup.value_definitions?.some((vd) => this.filterCalculatedType(vd))) {
      return ['toggleRepeatable'];
    }
    return [];
  }

  ngOnDestroy(): void {
    this.cleanTemporaryGroup();
    this.groupForm?.destroy();
  }

  private updateIsRepeatableGroup() {
    this.metricStructureService.updateIsRepeatableGroup(this.valueDefinitionGroup.repeatable);
  }

  public displayRepeatableGroupConsolidationWarningMessage(): boolean {
    return !!this.valueDefinitionGroup.value_definitions?.some(
      (vd) => vd.consolidation_rule !== ConsolidationRules.manual,
    );
  }

  public canDeleteGroup(): void {
    this.isGroupDeletionEnabled = !this.valueDefinitionGroup.value_definitions?.length && !this.isNewGroup;
  }

  public filterCalculatedType(valueDefinition: ValueDefinition): boolean {
    return valueDefinition.type === ValueDefinitionType.calculated;
  }

  public deleteGroup(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.valueDefinitionGroup.value_definitions?.length) {
      this.setUpdating(true);
      this.metricsService
        .deleteGroup(this.valueDefinitionGroup.metric_id, this.valueDefinitionGroup.id)
        .pipe(finalize(() => this.setUpdating(false)))
        .subscribe((response) => {
          this.metricStructureService.updateMetric(response.data);
          this.metricStructureService.updateSelectedItem(undefined);
          this.closeProperties();
        });
    }
  }

  public addGroupForm(): void {
    if (!this.metric || !this.groupForm) {
      return;
    }

    const valueDefGroupPayload: Partial<ValueDefinitionGroup> = this.groupForm.toModel();

    this.setUpdating(true);
    this.metricsService
      .createGroup(this.metric.id, { ...valueDefGroupPayload, position: this.valueDefinitionGroup.position })
      .pipe(finalize(() => this.setUpdating(false)))
      .subscribe((response) => {
        const selectedGroup =
          response.data.value_definition_groups?.find((x) => x.position === this.valueDefinitionGroup.position) ||
          response.data.value_definition_groups?.[response.data.value_definition_groups.length - 1];
        this.metricStructureService.updateMetric(response.data);
        this.metricStructureService.updateSelectedItem(selectedGroup);
        this.metricStructureService.setIsCreatingField(false);
      });
  }

  public saveGroupForm(): void {
    if (!this.groupForm) {
      return;
    }

    const valueDefGroupPayload: Partial<ValueDefinitionGroup> = this.groupForm.toModel();

    this.setUpdating(true);
    this.metricsService
      .updateGroup(this.valueDefinitionGroup.metric_id, this.valueDefinitionGroup.id, valueDefGroupPayload)
      .pipe(finalize(() => this.setUpdating(false)))
      .subscribe({
        next: (response: ApiResponse<Metric>) => {
          this.metricStructureService.updateMetric(response.data);
          this.metricStructureService.updateIsRepeatableGroup(valueDefGroupPayload.repeatable ?? false);
          this.groupForm?.markAsPristine();
        },
        error: (errorResponse: unknown) => {
          try {
            this.metricStructureService.handleUpdateValidationErrors(errorResponse as HttpErrorResponse);
          } catch (_) {
            this.errorManagerService.handleError(errorResponse as HttpErrorResponse);
          }
        },
      });
  }

  public closeProperties(): void {
    this.closeGroup.emit();
  }

  private setUpdating(updating: boolean): void {
    this.metricStructureService.updateIsMetricUpdating(updating);
  }

  private cleanTemporaryGroup(): void {
    if (this.valueDefinitionGroup.id === '') {
      setTimeout(() => {
        this.metricStructureService.removeTemporaryGroup(
          this.valueDefinitionGroup.position ? this.valueDefinitionGroup.position - 1 : 0,
        );

        this.metricStructureService.setIsCreatingField(false);
      });
    }
  }

  private disableFormIfMetricGroupIsDeactivated() {
    if (
      this.featureFlagService.areAnyFeatureFlagsEnabled(['metric_structure_deactivation_enabled']) &&
      !this.valueDefinitionGroup.active
    ) {
      this.formDisabled = true;
    } else {
      this.formDisabled = false;
    }
  }
}
