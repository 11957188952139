import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import {
  DialogResult,
  Metric,
  MetricTableCalculationDefinition,
  MetricTableDefinition,
  MoveDirection,
  Status,
} from '../../../../../models';
import { ConfirmationDialogComponent, DialogsService } from '../../../../../dialogs';
import { TranslateService } from '../../../../../services/common';
import { MetricStructureStateService } from '../../../../services/metric-structure-state.service';
import { FeatureFlagService } from '../../../../../feature-flag';

@Component({
  selector: 'lib-metric-table-total-card',
  templateUrl: './metric-table-total-card.component.html',
  styleUrls: ['./metric-table-total-card.component.scss'],
})
export class MetricTableTotalCardComponent implements OnChanges {
  @Input() public tableTotals: MetricTableCalculationDefinition[] = [];
  @Input() public metricTableDefinition?: MetricTableDefinition;
  @Input({ required: true }) public metric!: Metric;

  @Output() public deactivateTotal: EventEmitter<MetricTableCalculationDefinition> =
    new EventEmitter<MetricTableCalculationDefinition>();
  @Output() public activateTotal: EventEmitter<MetricTableCalculationDefinition> =
    new EventEmitter<MetricTableCalculationDefinition>();
  @Output() public deleteTotal: EventEmitter<MetricTableCalculationDefinition> =
    new EventEmitter<MetricTableCalculationDefinition>();
  @Output() public tableTotalClick: EventEmitter<MetricTableCalculationDefinition> =
    new EventEmitter<MetricTableCalculationDefinition>();
  @Output() public moveTableTotalCard: EventEmitter<{
    tableTotal: MetricTableCalculationDefinition;
    direction: MoveDirection;
  }> = new EventEmitter<{ tableTotal: MetricTableCalculationDefinition; direction: MoveDirection }>();

  menuPosition = { x: '0', y: '0' };
  readonly eMoveDirection = MoveDirection;
  isAdmin: boolean = false;
  metricTableDeactivationEnabled: boolean = false;

  constructor(
    private readonly dialogsService: DialogsService,
    private readonly translateService: TranslateService,
    private readonly metricStructureStateService: MetricStructureStateService,
    private readonly featureFlagService: FeatureFlagService,
  ) {
    this.isAdmin = this.metricStructureStateService.isAdmin;
    this.metricTableDeactivationEnabled = this.featureFlagService.areAnyFeatureFlagsEnabled([
      'metric_structure_metric_table_deactivation_enabled',
    ]);
  }

  ngOnChanges(): void {
    this.tableTotals = this.tableTotals.sort((a, b) => a.position - b.position);
  }

  public deleteTableTotal(tableTotalToDelete: MetricTableCalculationDefinition): void {
    this.dialogsService
      .open(ConfirmationDialogComponent, {
        data: {
          title: this.translateService.instant('Delete Total'),
          warningMsg: this.translateService.instant('Are you sure you want to delete this table total?'),
        },
      })
      .afterClosed()
      .subscribe((result?: DialogResult) => {
        if (result?.status === Status.CONFIRMED) {
          this.deleteTotal.emit(tableTotalToDelete);
        }
      });
  }
}
