<div
  class="filter-bar-container"
  [ngClass]="{ 'filter-bar-container-with-search': withSearchBar, 'p-0': !withPadding }"
>
  <div class="left-section">
    @if (withSearchBar) {
      <lib-filter-bar-search
        [initialSearch]="initialSearch"
        (searchChanged)="onSearchChanged.emit($event)"
        (searchTriggered)="searchTriggered.emit($event)"
        [searchBarPlaceholder]="searchBarPlaceholder"
      ></lib-filter-bar-search>
    }

    @if (primaryOptions.length) {
      <div
        class="primary-options"
        data-testid="primary-filter-container"
        [ngClass]="{ multiple: primaryFilteringOptions.length > 1 }"
      >
        @for (primaryFilteringConfig of primaryFilteringOptions; track primaryFilteringConfig) {
          <div>
            <!-- Multi-Select Filter -->
            @if (primaryFilteringConfig.multiSelect) {
              <lib-multi-select-filter
                class="filtering-option"
                [optionProps]="primaryFilteringConfig.filterOption"
                [buttonProps]="primaryFilteringConfig.buttonOption"
                (optionsSelected)="optionsSelected($event, primaryFilteringConfig)"
              ></lib-multi-select-filter>
            } @else {
              @if (primaryFilteringConfig.cascadeSelect) {
                @if (primaryFilteringConfig.filterOption.cascadeProps) {
                  <lib-cascade-select
                    class="filtering-option"
                    [control]="primaryFilteringConfig.filterOption.cascadeProps.formControl"
                    [options]="primaryFilteringConfig.filterOption.cascadeProps.options"
                    [groupLabel]="primaryFilteringConfig.filterOption.cascadeProps.groupLabel"
                    [optionLabel]="primaryFilteringConfig.filterOption.cascadeProps.optionLabel"
                    [prefix]="primaryFilteringConfig.prefix"
                    [showClear]="false"
                  ></lib-cascade-select>
                }
              } @else {
                @if (primaryFilteringConfig.optionType === FilterType.list) {
                  <lib-primary-filter
                    class="filtering-option"
                    [optionProps]="primaryFilteringConfig.filterOption"
                    [buttonProps]="primaryFilteringConfig.buttonOption"
                    (optionSelected)="optionSelected($event, primaryFilteringConfig)"
                  ></lib-primary-filter>
                }
              }
            }
            <!-- Cascade Select Filter -->
            <!-- Default Filter (list type) -->
          </div>
        }
      </div>
    }

    <ng-content></ng-content>
  </div>

  @if (secondaryFilteringOptions.length) {
    <div class="filter-bar-options" data-testid="secondary-filter-container">
      @if (hasSelectedFilters || hasFormControlChanged) {
        <a class="btn btn-warn" [title]="'Reset' | translate" (click)="resetFilters()">
          <mat-icon class="icon-sm" svgIcon="frequency"></mat-icon>
          <span>{{ "Reset" | translate }}</span>
        </a>
      }
      @if (hasSelectedFilters) {
        <div class="divider divider-vertical"></div>
      }
      @for (filteringConfig of secondaryFilteringOptions; track filteringConfig) {
        <div>
          @if (filteringConfig.optionType === FilterType.searchList) {
            <lib-filter-search
              class="filtering-option"
              [optionProps]="filteringConfig.filterOption"
              [buttonProps]="filteringConfig.buttonOption"
              [sourceConfiguration]="sourceConfiguration"
              (optionSelected)="optionSelected($event, filteringConfig)"
            ></lib-filter-search>
          }
          @if (filteringConfig.optionType === FilterType.list) {
            <lib-filter
              class="filtering-option"
              [optionProps]="filteringConfig.filterOption"
              [buttonProps]="filteringConfig.buttonOption"
              [sourceConfiguration]="sourceConfiguration"
              (optionSelected)="optionSelected($event, filteringConfig)"
            ></lib-filter>
          }
          @if (filteringConfig.optionType === FilterType.list_v2) {
            <lib-filter-v2
              class="filtering-option"
              [activeActionItem]="filteringConfig.filterOption.activeOption"
              [items]="filteringConfig.filterOption.options ?? []"
              [buttonProps]="filteringConfig.buttonOption"
              [defaultValue]="filteringConfig.filterOption.defaultValue"
              (optionSelected)="optionSelected($event, filteringConfig)"
            ></lib-filter-v2>
          }
        </div>
      }
    </div>
  }
</div>
