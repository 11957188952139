<lib-dialog-title
  (closeEvent)="closeDialog()"
  [removeMargin]="data.removeTitleMargin | boolean"
  [title]="title"
></lib-dialog-title>
<div mat-dialog-content class="pt-4 pb-1">
  @if (warningMsg) {
    <lib-dialog-warning>{{ warningMsg }}</lib-dialog-warning>
  }
  @if (data.body) {
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="mt-4">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        @if (node.level) {
          <button mat-icon-button disabled></button>
        }
        <mat-checkbox
          color="primary"
          class="checklist-leaf-node"
          [checked]="checklistSelection.isSelected(node)"
          (change)="todoLeafItemSelectionToggle(node)"
        >
          {{ node.item }}
        </mat-checkbox>
      </mat-tree-node>
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild">
        <mat-checkbox
          color="primary"
          [checked]="descendantsAllSelected(node)"
          [indeterminate]="descendantsPartiallySelected(node)"
          (change)="todoItemSelectionToggle(node)"
        >
          {{ node.item }}
        </mat-checkbox>
      </mat-tree-node>
    </mat-tree>
  }
</div>
<div mat-dialog-actions class="warning">
  <div class="fx-row justify-start align-center">
    <a
      class="btn {{ buttonStatusClass }}"
      role="button"
      aria-label="confirmBtn"
      (click)="confirm()"
      [ngClass]="{ 'disabled btn-disabled': (data.body && !this.checklistSelection.selected.length) || buttonDisabled }"
      >{{ primaryBtn }}</a
    >
    <button lib-button buttonType="link" class="ml-4" role="button" aria-label="closeBtn" (click)="closeDialog()">
      {{ secondaryBtn }}
    </button>
  </div>
</div>
