<ng-template #template let-item="item">
  <ng-container
    *libFeatureFlag="{ featureFlags: item.featureFlag, hiddenWhenEnabled: item.featureFlagHiddenWhenEnabled }"
  >
    <ng-container *libPermission="item.permissions">
      @if (!item.showCondition || (rowData | executeFunctionOnValue: item.showCondition)) {
        @if (!item.isSeparator) {
          <p-button
            styleClass="text-left w-100"
            [text]="true"
            [icon]="'pi pi-' + item.icon"
            [label]="item.label"
            [disabled]="item.disabled"
            severity="secondary"
            (click)="item.onClick(rowData)"
          >
          </p-button>
        } @else {
          <lib-divider></lib-divider>
        }
      }
    </ng-container>
  </ng-container>
</ng-template>

@if (rowData | doesActionMenuHasItems: actionMenuItems) {
  <lib-popup-menu [template]="template" [menuItems]="actionMenuItems"> </lib-popup-menu>
}
