<div class="fx-col">
  @for (option of options; track option) {
    <mat-checkbox
      color="primary"
      [checked]="control.value?.includes(option)"
      [value]="option"
      (change)="onCheckboxToggle($event, option)"
      [disabled]="control.disabled"
      class="mat-body-2"
      (click)="$event.stopPropagation()"
    >
      {{ option }}
    </mat-checkbox>
  }
</div>
