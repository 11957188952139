import { CreatedUpdatedEntity } from './entities';
import { Metric, ValueDefinition } from './metrics';

export enum XBRLValidationTypeV1 {
  missingValue = 'missing_value',
  unsupportedUnit = 'unsupported_unit',
  wrongFieldType = 'wrong_field_type',
  wrongUnitOrFamily = 'wrong_unit_or_family',
}

export enum XBRLTypeV1 {
  area = 'area',
  boolean = 'boolean',
  date = 'date',
  decimal = 'decimal',
  domain = 'domain',
  energy = 'energy',
  energyPerMonetary = 'energyPerMonetary',
  enumeration = 'enumeration',
  enumerationSet = 'enumerationSet',
  ghgEmissions = 'ghgEmissions',
  ghgEmissionsPerMonetary = 'ghgEmissionsPerMonetary',
  gYear = 'gYear',
  integer = 'integer',
  mass = 'mass',
  monetary = 'monetary',
  percent = 'percent',
  string = 'string',
  textBlock = 'textBlock',
  volume = 'volume',
  volumePerMonetary = 'volumePerMonetary',
}

export enum XBRLTagPeriodTypeV1 {
  duration = 'duration',
  instant = 'instant',
}

export interface XBRLTag extends CreatedUpdatedEntity {
  id: string;
  metric_id: string;
  taxonomy_id: string;
  parent_taxonomy_id: string;
  value_definition_id: string;
  metric_table_column_definition_id: string;
  xbrl_label: string;
  xbrl_concept: string;
  xbrl_type: XBRLTypeV1;
  period_type: XBRLTagPeriodTypeV1;
}

export interface ListXBRLTagsRequest {
  parent_taxonomy_id?: string;
  value_definition_ids?: string[];
}

export interface ExportXBRLTagsRequest {
  file_name: string;
  taxonomy_id: string;
  business_unit_id: string;
  fiscal_year: string;
  start: string;
  end: string;
}

export interface ValidateXBRLTagsExportItem {
  message: string;
  type: XBRLValidationTypeV1;
  metric: Metric;
  value_definition: ValueDefinition;
}

export interface ValidateXBRLTagsExportResponse {
  errors: ValidateXBRLTagsExportItem[];
  warnings: ValidateXBRLTagsExportItem[];
}
