@if (control) {
  <div aria-label="due-date-reminder-wrapper">
    @if (label !== "" && control.length) {
      <label class="due-date-reminder-label">
        <b>{{ label }}</b>
        @if (subtitle) {
          <span>{{ subtitle }}</span>
        }
      </label>
    }
    @for (row of control.controls; track row; let first = $first; let i = $index) {
      <form
        [formGroup]="row"
        class="due-date-reminder-form"
        [class.due-date-reminder-form-small]="isSmallScreen"
        [class.row-separator]="!first"
        aria-label="due-date-reminder-form"
      >
        <lib-select-input
          class="status"
          [ariaLabel]="'Status'"
          [control]="row.controls.source_status"
          [options]="sourceStatusItems"
        >
        </lib-select-input>
        <lib-select-input
          class="participants"
          [ariaLabel]="'Participant'"
          [control]="row.controls.participants"
          [options]="participantItems"
        >
        </lib-select-input>
        <lib-select-input
          class="schedule"
          [ariaLabel]="'Schedule'"
          [control]="row.controls.schedule"
          [options]="scheduleItems"
        >
        </lib-select-input>
        <button
          data-testid="removeBtn"
          [attr.type]="'button'"
          (click)="removeRow(i)"
          lib-button
          class="trash remove-row-btn"
        >
          <mat-icon class="icon-lg color-error" svgIcon="trash"></mat-icon>
        </button>
        @if (row.controls.participants.value === "custom") {
          <ng-container [ngTemplateOutlet]="customParticipants" [ngTemplateOutletContext]="{ row: row }">
          </ng-container>
        }
        @if (row.controls.schedule.value === eDueDateNotificationScheduleType.CUSTOM) {
          <ng-container [ngTemplateOutlet]="customSchedule" [ngTemplateOutletContext]="{ row: row }"> </ng-container>
        }
      </form>
    }
    @if (control.length < maxItems) {
      <button class="mb-2" [attr.type]="'button'" (click)="addRow()" lib-button buttonType="link">
        {{ "+ Add notification" | translate }}
      </button>
    } @else {
      <lib-tip [iconConfig]="tipIcons.warning">
        {{ "You cannot send more than {maxItems} notifications per request " | translate: { maxItems } }}
      </lib-tip>
    }
    @if (control.length) {
      <lib-tip [iconConfig]="tipIcons.info">
        {{ "Reminders are not sent retroactively" | translate }}
      </lib-tip>
    }
  </div>
}

<ng-template #customParticipants let-row="row">
  @if (!isSmallScreen) {
    <div class="custom-participants-label custom-input-label">
      {{ "Custom participants" | translate }}
    </div>
  }
  <lib-multi-select-dropdown
    class="custom-participants"
    [placeholder]="'Select participants' | translate"
    [control]="row.controls.customParticipants"
    [options]="customParticipantItems"
  ></lib-multi-select-dropdown>
</ng-template>

<ng-template #customSchedule let-row="row">
  @if (!isSmallScreen) {
    <div class="custom-schedule-label custom-input-label">
      {{ "Custom schedule" | translate }}
    </div>
  }
  <div class="custom-schedule">
    <lib-numeric-input
      class="schedule-frequency"
      [control]="row.controls.scheduleFrequency"
      [messages]="errorMessages"
    ></lib-numeric-input>
    <lib-select-input [control]="row.controls.scheduleTimeFrame" [options]="scheduleTimeframeItems"> </lib-select-input>
    <lib-select-input [control]="row.controls.scheduleRelativeTo" [options]="scheduleRelativeToItems">
    </lib-select-input>
  </div>
</ng-template>
