<div class="panel-search layout-list presentation-card" [ngClass]="{ loaded: loaded, 'item-selected': selectedItemID }">
  <div class="panel-collection">
    <div class="collection collection-dummies">
      @for (dummy of dummies; track dummy) {
        <lib-dummy [presentation]="ePresentation.card"></lib-dummy>
      }
    </div>
    <div class="h-100" [ngClass]="{ 'collection collection-items': itemCollection?.items?.length }">
      @for (item of itemCollection?.items; track item) {
        <div
          [attr.title]="item.item.imported ? ('This metric is already created' | translate) : null"
          class="card-container"
          [ngClass]="{ active: activeItem === item || selectedItem === item, selected: item.item.imported }"
          (mouseover)="onMouseOver(item)"
          (mouseout)="onMouseOut()"
        >
          <lib-card
            [item]="item"
            mode="select"
            [presentation]="ePresentation.card"
            (action)="selectItem($event)"
          ></lib-card>
        </div>
      }
      @if (itemCollection?.items?.length === 0) {
        <div class="h-100">
          @if (noData) {
            <div class="h-100">
              <ng-content></ng-content>
            </div>
          }
          @if (!noData) {
            <div class="h-100 collection-empty">
              <lib-empty-results [displayData]="emptyResults"></lib-empty-results>
            </div>
          }
        </div>
      }
      <div #spinner class="spinner">
        <lib-spinner [diameter]="20"></lib-spinner>
      </div>
      <div class="grid-end"></div>
    </div>
  </div>
</div>
