<div class="bkg-grey-50" #choiceContent>
  <div class="choice-row">
    <div class="choice-number">
      <div class="mat-body-1 color-grey-900">{{ "#" | translate }}</div>
    </div>
    <div class="choice-content">
      <div class="mat-body-1 color-grey-900" data-testid="contextOption">{{ "Context Options" | translate }}</div>
    </div>
  </div>
  @for (option of options; track option; let i = $index) {
    <a
      data-testid="optionRow"
      class="choice-row choice-row-hoverable p-0 bkg-white"
      [ngClass]="{ active: option.label === activeOption }"
    >
      <div class="choice-number">
        <div class="mat-body-1 color-grey-900">
          {{ i + 1 }}
        </div>
      </div>
      <div class="choice-content">
        @if (editingOptionIndex !== i) {
          <div class="mat-body-2 color-grey-900">
            {{ option.label }}
          </div>
        } @else {
          <lib-text-input
            [autofocus]="true"
            [control]="editableOptionControl"
            [removePadding]="true"
            (keyup.enter)="editOption()"
            (handleBlur)="editOption()"
            subscriptSizing="dynamic"
          ></lib-text-input>
        }
      </div>
      @if (editingOptionIndex < 0) {
        <div class="choice-actions">
          @if (!isFirstOption(option.label)) {
            <a
              class="btn btn-option"
              (click)="moveOption(option.label, 'up')"
              data-testid="moveUpIcon"
              [ngClass]="{ disabled: isMetricUpdating }"
            >
              <mat-icon svgIcon="arrow-full-up" class="icon-md"></mat-icon>
            </a>
          }
          @if (!isLastOption(option.label)) {
            <a
              class="btn btn-option"
              (click)="moveOption(option.label, 'down')"
              data-testid="moveDownIcon"
              [ngClass]="{ disabled: isMetricUpdating }"
            >
              <mat-icon svgIcon="arrow-full-down" class="icon-md"></mat-icon>
            </a>
          }
          @if (editable) {
            <a
              class="btn btn-option"
              (click)="enableEditOption(i)"
              data-testid="editIcon"
              [ngClass]="{ disabled: isMetricUpdating }"
            >
              <mat-icon svgIcon="edit" class="icon-md"></mat-icon>
            </a>
          }
          @if (!option.id || !nonDeletableColumnIds.includes(option.id)) {
            <a
              class="btn btn-option btn-delete-choice"
              [ngClass]="{ disabled: isMetricUpdating }"
              (click)="removeOption(option.label)"
              data-testid="deleteIcon"
            >
              <mat-icon svgIcon="trash" class="icon-md"></mat-icon>
            </a>
          }
        </div>
      }
    </a>
  }
  <div class="choice-row bkg-white">
    <div class="choice-number"></div>
    <div class="choice-content">
      <input
        data-testid="newOptionInput"
        matInput
        [placeholder]="'Type here and press Enter to add a new option' | translate"
        (keydown.enter)="addOption($event)"
      />
    </div>
  </div>
</div>
