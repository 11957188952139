@if (widget) {
  <div class="single-data-point-widget-card">
    <div class="single-data-point-widget-card-content">
      <div class="top-content">
        <div class="single-data-point-widget-card-title">
          @if (metricDetailsUrl) {
            <lib-link [url]="metricDetailsUrl" [text]="widget.metric.code!" [openInNewTab]="true" role="nav"></lib-link>
          } @else {
            <span>{{ widget.metric.code }}</span>
          }
          @if (showEditMenu) {
            <button
              lib-button
              class="action-button"
              [matMenuTriggerFor]="menu"
              [matMenuTriggerData]="{ item: widget }"
              data-testid="widgetContextMenu"
            >
              <mat-icon svgIcon="more"></mat-icon>
            </button>
          }
        </div>
        <div libEllipsifyMe [maxCharCount]="35" class="single-data-point-widget-card-subtitle">
          <span>{{ widget.label }}</span>
        </div>
      </div>
      <div class="value-grid">
        @if (dataPoint?.value?.value) {
          <div class="value-wrapper">
            <div>
              <span
                class="single-data-point-widget-card-value"
                [matTooltip]="
                  dataPoint?.value?.value
                    | numberMask
                      : dataPoint?.value_definition?.type_details?.max_decimals
                      : undefined
                      : true
                      : false
                      : false
                      : false
                "
              >
                {{
                  dataPoint?.value?.value
                    | numberMask
                      : dataPoint?.value_definition?.type_details?.max_decimals
                      : undefined
                      : true
                      : false
                      : false
                      : true
                }}
              </span>
              @if (dataPoint?.value_definition?.type_details?.units !== "default") {
                <span class="single-data-point-widget-card-unit">{{
                  dataPoint?.value_definition?.type_details?.units | unitSymbol
                }}</span>
              }
            </div>
            @if (variation !== 0) {
              <div class="previous-value-indicator">
                <mat-icon data-testid="variationIcon" [svgIcon]="variation > 0 ? 'trend-up' : 'trend-down'"></mat-icon>
                <span>{{ variation }} %</span>
              </div>
            }
          </div>
        } @else {
          <span class="single-data-point-widget-card-value">{{ "No data" | translate }}</span>
        }
        @if (dataPoint?.show_previous_year) {
          <div class="single-data-point-widget-card-prev-year">
            <span>{{ "Prev. year: " | translate }}</span>
            @if (dataPoint?.previous_year_value?.value) {
              <span
                [matTooltip]="
                  dataPoint?.previous_year_value?.value
                    | numberMask
                      : dataPoint?.value_definition?.type_details?.max_decimals
                      : undefined
                      : true
                      : false
                      : false
                      : false
                "
              >
                {{
                  dataPoint?.previous_year_value?.value
                    | numberMask
                      : dataPoint?.value_definition?.type_details?.max_decimals
                      : undefined
                      : true
                      : false
                      : false
                      : true
                }}
              </span>
            } @else {
              <span>{{ "No data" | translate }}</span>
            }
          </div>
        }
        <div class="footer-information">
          <span>{{ "Source:" | translate }} {{ sourceName }}</span>
          <span>{{ "FY:" | translate }} {{ year }}</span>
        </div>
      </div>
    </div>
  </div>
}

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="removeItem(item)">
      <mat-icon svgIcon="trash"></mat-icon>
      <span>{{ "Remove" | translate }}</span>
    </button>
    <button mat-menu-item (click)="editItem(item)">
      <mat-icon svgIcon="edit"></mat-icon>
      <span>{{ "Edit" | translate }}</span>
    </button>
  </ng-template>
</mat-menu>
