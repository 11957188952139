<div
  class="form-group-header"
  [ngClass]="{
    'color-error': !valueDefinitionGroup.value_definitions?.length || isElementTriggered(valueDefinitionGroup),
  }"
>
  <div class="fx-row">
    @if (isElementTriggered(valueDefinitionGroup)) {
      <mat-icon class="icon-md mr-0" svgIcon="condition-trigger"></mat-icon>
    }
    <mat-icon class="icon-md" svgIcon="group"></mat-icon>
  </div>

  @if (valueDefinitionGroup | isDeactivated) {
    <span class="mr-1 status-chip">{{ "Inactive" | translate }}</span>
  }
  <span class="mat-small" [ngClass]="{ inactive: valueDefinitionGroup | isDeactivated }">
    {{ valueDefinitionGroup.label || "Group {iteration}" | translate: { iteration: index + 1 } }}
  </span>
</div>

@if (!valueDefinitionGroup.value_definitions?.length) {
  <div class="form-group-empty-content"></div>
}

<div class="form-group-content form-group">
  @if (isCoreValueDefinitionGroupOfReferenceV2Metric) {
    @for (valueDefinition of valueDefinitionGroup.value_definitions; track valueDefinition; let i = $index) {
      <lib-metric-structure-field-form
        [class]="'formfield ' + getValueDefinitionFormat(valueDefinition).size"
        [ngClass]="{
          newline: valueDefinition.newline,
          required: valueDefinition.required,
          readonly: getValueDefinitionFormat(valueDefinition).readonly,
        }"
        [valueDefinition]="valueDefinition"
        [valueDefinitionGroup]="valueDefinitionGroup"
        [vdIndex]="i"
        [isActive]="selectedItemId === valueDefinition.id"
        [attr.data-testid]="'vd-' + valueDefinition.id"
      ></lib-metric-structure-field-form>
    }
  } @else {
    @for (valueDefinition of valueDefinitionGroup.value_definitions; track valueDefinition; let i = $index) {
      <lib-metric-structure-field-form
        cdkDrag
        [cdkDragData]="valueDefinition"
        [cdkDragDisabled]="valueDefinition | isValueDefinitionDragDisabled: metric : isAdmin"
        [class]="'formfield ' + getValueDefinitionFormat(valueDefinition).size"
        [ngClass]="{
          newline: valueDefinition.newline,
          required: valueDefinition.required,
          readonly: getValueDefinitionFormat(valueDefinition).readonly,
        }"
        [valueDefinition]="valueDefinition"
        [valueDefinitionGroup]="valueDefinitionGroup"
        [vdIndex]="i"
        [isActive]="selectedItemId === valueDefinition.id"
        [attr.data-testid]="'vd-' + valueDefinition.id"
      ></lib-metric-structure-field-form>
    }
  }
</div>
<div
  class="overlay"
  [ngClass]="{
    active: selectedItemId === valueDefinitionGroup.id,
  }"
  (click)="setSelectedItem(valueDefinitionGroup)"
  [attr.data-testid]="'overlay-' + valueDefinitionGroup.id"
>
  <div class="actions">
    @if (!isCoreValueDefinitionGroupOfReferenceV2Metric) {
      @if (canMoveDown) {
        <button
          class="btn btn-sm"
          (click)="moveGroupDown($event)"
          [attr.aria-label]="'move down-' + valueDefinitionGroup.id"
        >
          {{ "Move Down" | translate }}
        </button>
      }
      @if (canMoveUp) {
        <button
          class="btn btn-sm"
          (click)="moveGroupUp($event)"
          [attr.aria-label]="'move up-' + valueDefinitionGroup.id"
        >
          {{ "Move Up" | translate }}
        </button>
      }
    }
    @if (
      (!this.valueDefinitionGroup.core_value_definition_group_id || isAdmin) &&
      !this.valueDefinitionGroup.value_definitions?.length
    ) {
      <button class="btn btn-sm" (click)="deleteGroup($event)" aria-label="groupDelete">
        {{ "Delete" | translate }}
      </button>
    }
  </div>
</div>
