<mat-menu #menu="matMenu" (close)="resetInput()">
  <ng-template matMenuContent>
    @if (buttonProps?.title === "Source") {
      @if (sourceConfiguration) {
        <lib-source-menu-filter
          [initialSources]="optionProps?.options ?? []"
          [selectedSource]="optionProps?.activeOption"
          [sourceConfiguration]="sourceConfiguration"
          (setItem)="optionSelected.emit($event)"
        >
        </lib-source-menu-filter>
      }
    } @else {
      <a mat-menu-item class="menu-title" disabled>{{ optionProps?.title }}</a>

      @if (isSearchable()) {
        <div class="menu-search" (click)="$event.stopPropagation()">
          <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
          <input
            matInput
            type="text"
            placeholder="{{ 'Search' | translate }}"
            autocomplete="off"
            (keydown)="$event.stopPropagation()"
            [formControl]="searchInputControl"
          />

          @if (searchInputControl.value) {
            <a class="clear-search" (click)="resetInput()" data-testid="clearButton">
              <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
            </a>
          }
        </div>
      }

      @if (optionProps && optionProps.defaultValue) {
        <hr />
        <a mat-menu-item (click)="optionSelected.emit(optionProps.defaultValue)">
          {{ getDefaultValueTitle() }}
        </a>
      }

      @for (header of optionProps?.secondaryHeaders; track header) {
        <button
          mat-menu-item
          (click)="optionSelected.emit(header)"
          [ngClass]="{ active: optionProps?.activeOption?.id === header.id }"
        >
          {{ header.title }}
        </button>
      }

      <div [ngClass]="{ 'menu-items-container': filteredOptions.length > minMenuScrollItems }">
        <ng-content></ng-content>

        @switch (optionProps?.id) {
          @case (standardCodeResourceType) {
            <lib-filter-with-category
              [filterCategoryTranslations]="standardCodeCategoryTranslations"
              [filteredOptions]="filteredOptions"
              [filterFunction]="filterStandardCodes"
              [activeOptionId]="optionProps?.activeOption?.id"
              (optionSelected)="optionSelected.emit($event)"
            ></lib-filter-with-category>
          }
          @case (frameworkTaxonomyResourceType) {
            <lib-filter-with-category
              [filterCategoryTranslations]="frameworkTaxonomyCategoryTranslations"
              [filteredOptions]="filteredOptions"
              [filterFunction]="filterFrameworkTaxonomyCodes"
              [activeOptionId]="optionProps?.activeOption?.id"
              (optionSelected)="optionSelected.emit($event)"
            ></lib-filter-with-category>
          }
          @default {
            @for (option of filteredOptions; track option) {
              <a
                mat-menu-item
                [title]="option.title"
                (click)="optionSelected.emit(option)"
                [ngClass]="{ active: optionProps?.activeOption?.id === option.id }"
                data-testid="filteredOption"
              >
                @if (optionProps?.id !== topicResourceType) {
                  <span>{{ option.title }}</span>
                }
                @if (optionProps?.id === topicResourceType) {
                  <span [ngClass]="{ 'pl-2': option.action === 'group', 'pl-4': option.action === 'topic' }">
                    {{ option.title }}
                  </span>
                }
              </a>
            }
          }
        }
      </div>
    }
  </ng-template>
</mat-menu>

<lib-filter-button [buttonProps]="buttonProps" [optionProps]="optionProps" [matMenuTriggerFor]="menu">
</lib-filter-button>
