import { Injectable } from '@angular/core';
import { ActionItem, FilterBarOption, FilterBarSelection, FilterType } from '../../../models';
import { ButtonProps, OptionProps, SearchableOptionProps } from '../../../search/models';
import { TranslateService } from '../translate/translate.service';

const MIN_FOR_SEARCH_TO_LOAD = -1;

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  readonly filterListDefaultValue: ActionItem<never> = {
    title: this.translateService.instant('All'),
    id: '-1',
  };

  constructor(private readonly translateService: TranslateService) {}

  makeFilterOption(option: FilterBarOption, selectedOption: ActionItem): OptionProps {
    switch (option.optionType) {
      case FilterType.list:
        return this.makeListFilterOption(option, selectedOption);
      case FilterType.searchList:
        return this.makeSearchListFilterOption(option, selectedOption);
      case FilterType.list_v2:
        return this.makeListFilterOption(option, selectedOption);
    }
  }

  makeButtonOption(option: FilterBarOption, selectedOption: ActionItem): ButtonProps {
    switch (option.optionType) {
      case FilterType.list:
      case FilterType.list_v2:
      case FilterType.searchList:
        return this.makeListButtonOptions(option, selectedOption);
    }
  }

  sanitizedFilterOptions(
    filterBarSelections: FilterBarSelection[],
    singleValue: boolean = false,
  ): Record<string, string | string[]> {
    return filterBarSelections
      .filter((option) => option.selection.some((selection) => selection.id !== this.filterListDefaultValue.id))
      .reduce(
        (accOptions, option) => ({
          ...accOptions,
          [option.id]: singleValue ? option.selection[0].id : option.selection.map((selection) => selection.id),
        }),
        {},
      );
  }

  // FILTER OPTIONS
  private makeListFilterOption(option: FilterBarOption, selectedOption?: ActionItem): OptionProps {
    return {
      id: option.id,
      title: option.title,
      options: option.options as ActionItem<never>[],
      multiSelectDefaultValues: option.multiSelectDefaultValues ?? undefined,
      activeOption: selectedOption,
      prefix: option.prefix,
      ...(option.displayAll && { defaultValue: this.filterListDefaultValue }),
    };
  }

  private makeSearchListFilterOption(option: FilterBarOption, selectedOption: ActionItem): SearchableOptionProps {
    return {
      ...this.makeListFilterOption(option, selectedOption),
      minMenuScrollItems: MIN_FOR_SEARCH_TO_LOAD,
      secondaryHeaders: option.secondaryHeaders,
    };
  }

  // BUTTON OPTIONS
  private makeListButtonOptions(option: FilterBarOption, selectedOption: ActionItem): ButtonProps {
    const defaultOptionSelected = selectedOption === this.filterListDefaultValue;
    return {
      icon: option.icon,
      active: !defaultOptionSelected,
      ...(defaultOptionSelected && { title: option.title }),
    };
  }
}
