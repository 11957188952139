@for (filterCategory of filterCategories; track filterCategory) {
  <div>
    @if (filteredOptions | filter: filterFunction : filterCategory; as optionsByCategory) {
      @if (optionsByCategory.length > 0) {
        <hr class="hr-light" />
        <button mat-menu-item class="menu-title" disabled>
          {{ filterCategoryTranslations[filterCategory] | translate }}
        </button>
        @for (option of optionsByCategory; track option; let i = $index) {
          <button
            mat-menu-item
            (click)="optionSelected.emit(option)"
            [ngClass]="{ active: activeOptionId === option.id }"
            [title]="option.text"
          >
            <span>{{ option.title | uppercase }}</span>
          </button>
        }
      }
    }
  </div>
}
