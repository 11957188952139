<lib-dialog-title
  [tabs]="tabs"
  title="{{ 'Attach a file' | translate }}"
  [defaultSelectedTab]="defaultTab"
  (closeEvent)="close()"
  (tabSwitchEvent)="switchTab($event)"
></lib-dialog-title>

<div mat-dialog-content [ngClass]="{ 'fit-lib-search': selectedtab === tabs[0] }">
  @switch (selectedtab) {
    @case (tabs[0]) {
      @if (dialogData.dataRequestId) {
        <lib-data-requests-doc-search
          data-testid="data-requests-doc-search"
          [properties]="searchProperties"
          [dataRequestId]="dialogData.dataRequestId"
          [canShowStatusFilter]="dialogData.canShowStatusFilter"
          (action)="handleSearchAction($event)"
        >
          <lib-empty-results [displayData]="emptyResults"></lib-empty-results>
        </lib-data-requests-doc-search>
      } @else {
        <lib-metrics-library-doc-search [properties]="searchProperties" (action)="handleSearchAction($event)">
          <lib-empty-results [displayData]="emptyResults"></lib-empty-results>
        </lib-metrics-library-doc-search>
      }
    }
    @case (tabs[1]) {
      @if (!fileDropped) {
        <div (drop)="dropFile($event)" data-testid="drop-area" (dragover)="handleDragDropEvent($event)">
          <div class="card card-dashed">
            <div class="mat-subtitle-2 fx-col align-center justify-center mb-8">
              <mat-icon class="icon-lg mb-2" svgIcon="import"></mat-icon>
              <div>
                {{ "Drag files to upload, or" | translate }}
                <button lib-button buttonType="browse">
                  {{ "browse" | translate }}
                  <input class="hidden" type="file" (change)="selectFileFromBrowse($event)" />
                </button>
              </div>
            </div>
            <div class="color-grey-800 fx-row justify-center">
              <mat-icon class="icon-md mr-1" svgIcon="guidance"></mat-icon>
              <div class="mat-caption">{{ "You can upload spreadsheets, text files and PDFs" | translate }}</div>
            </div>
          </div>
        </div>
      }
      @if (fileDropped) {
        <div>
          <div class="card p-2 mb-6">
            <div class="fx-row justify-space-between">
              <div class="fx-row">
                <div data-testid="fileIcon" class="file-icon file-{{ fileIcon }} file-icon-large mr-2">
                  <mat-icon [svgIcon]="fileIcon || 'web'"></mat-icon>
                </div>
                <div class="fx-col justify-center">
                  <div class="mb-1 mat-body-2">{{ file?.name }}</div>
                  <div class="color-grey-800 mat-caption">
                    {{ fileIconKey | translate }}
                  </div>
                </div>
              </div>
              <div class="fx-col justify-center m-0">
                <a role="button" aria-label="deleteFile" class="mr-1" (click)="clearAll()">
                  <mat-icon svgIcon="trash"></mat-icon>
                </a>
              </div>
            </div>
          </div>
        </div>
      }
    }
  }
</div>

<div mat-dialog-actions>
  <div class="action-container w-100">
    @if (dialogData.dataRequestId) {
      <lib-info-banner
        [message]="'Document will be visible to all requests participants' | translate"
        [type]="'warning'"
      ></lib-info-banner>
    }
    <a
      role="button"
      aria-label="attachFile"
      class="btn btn-success attach-button"
      [ngClass]="{ 'disabled btn-disabled': !(file || selectedDoc) }"
      (click)="attachFile()"
    >
      {{ "Attach a file" | translate }}
    </a>
  </div>
</div>
