@if (legendTitle) {
  <p class="title">{{ legendTitle }}</p>
}
<div class="legend">
  @for (item of data; track item) {
    <div class="legend-item">
      <div
        [ngStyle]="{ 'background-color': item.color }"
        class="legend-icon mr-1"
        [attr.data-testid]="'legend-icon-' + item.title + item.color"
      ></div>
      <p class="m-0">{{ item.title }}</p>
    </div>
  }
</div>
