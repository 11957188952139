<div mat-dialog-title class="mb-0 fx-col">
  <div class="fx-row justify-space-between">
    <h3 class="mat-h3 mat-primary mb-2">{{ "Add variables" | translate }}</h3>
    <a (click)="closeDialog()">
      <mat-icon svgIcon="close"></mat-icon>
    </a>
  </div>
  @if (tabIndex) {
    <div class="tabs mat-body-1">
      <a (click)="switchTab(1)" [ngClass]="{ active: tabIndex === 1 }">{{ "From this metric" | translate }}</a>
      <a (click)="switchTab(2)" [ngClass]="{ active: tabIndex === 2 }">{{ "From another metric" | translate }}</a>
    </div>
  }
</div>

<div mat-dialog-content class="panel-dialog" [ngClass]="{ loaded: loaded }">
  <div class="fx-col" style="height: 65vh">
    <div class="form no-gap mt-3">
      @if (tabIndex === 2) {
        <div class="formfield large required">
          <lib-auto-complete-input
            [label]="'Select a metric' | translate"
            [hint]="'Start typing to see metrics' | translate"
            [control]="metricFormControl"
            [canAddNewOption]="false"
            [options]="(indicators$ | async) ?? []"
            [bindLabel]="bindMetricLabel"
            [minimalNumberOfCharToDisplayAutocomplete]="0"
            [comparisonMethod]="eAutoCompleteInputComparisonMethod.INCLUDES"
            [isLoadingOptions]="isLoadingInterMetrics"
            (selectValue)="selected($event)"
          ></lib-auto-complete-input>
        </div>
      }

      <div class="tip large mv-4">
        <div class="fx-row align-center">
          <mat-icon svgIcon="info" class="tip-icon color-grey-800 mr-2"></mat-icon>
        </div>
        <div class="text tip-text tip-text-dark mat-body color-grey-900 pl-3">
          {{
            "Only number fields and table totals in this metric can be used as variables for a number calculation field."
              | translate
          }}
        </div>
      </div>
      <lib-divider class="mb-1 formfield large"></lib-divider>
    </div>
    <div class="tag color-grey-800 formfield large mb-4">{{ "Choose values" | translate }}</div>
    @if (metricVisibleFields$ | async; as metricVisibleFields) {
      <div class="formfield large item-dummies-container fx-grow">
        <div class="h-100 item-dummies bkg-white">
          @for (i of [1, 2, 3, 4]; track i) {
            <div class="mb-2">
              <div class="mat-body-1 rounded-corners bkg-blink w-50">&nbsp;</div>
            </div>
          }
        </div>
        @for (
          group of selectedMetric?.value_definition_groups
            | filterUncalculableGroups
              : metricVisibleFields.metric_table_definition_ids
              : isAdmin
              : data.metric.id === selectedMetric?.id;
          track group;
          let i = $index
        ) {
          <div class="formfield large mb-2">
            <div class="fx-row mb-2">
              <mat-icon svgIcon="group" class="color-grey-800 icon-md"></mat-icon>
              <div class="ml-2 tag color-grey-800">
                {{ group.label ?? "Group {iteration}" | translate: { iteration: i + 1 } }}
              </div>
            </div>
            <div class="fx-col">
              @for (
                valueDefinition of group.value_definitions
                  | filterUncalculableVds
                    : metricVisibleFields.value_definition_ids
                    : group.table_id
                    : isAdmin
                    : data.metric.id === selectedMetric?.id;
                track valueDefinition
              ) {
                @if (
                  (valueDefinition.type | isSupportedTypeAsVariable) && valueDefinition.id !== targetValueDefinitionId
                ) {
                  <mat-checkbox
                    [value]="valueDefinition.id"
                    [checked]="isValueDefAdded(valueDefinition.id)"
                    (change)="checkboxChanged($event, group.id, valueDefinition.id)"
                    color="primary"
                    class="ml-4 mat-body-2"
                    [attr.data-testid]="'vd-option-' + valueDefinition.id"
                  >
                    <span>{{ valueDefinition.label }}</span>
                    @if (valueDefinition | isDeactivated) {
                      <span>
                        {{
                          "(Inactive from Fiscal Year {fiscalYear})"
                            | translate: { fiscalYear: valueDefinition.end_fiscal_year_frequency_code }
                        }}
                      </span>
                    }
                  </mat-checkbox>
                }
              }
            </div>
          </div>
        }
        @if (!selectedMetric) {
          <lib-empty-results [displayData]="emptyResults" class="h-100"></lib-empty-results>
        }
      </div>
    } @else {
      <lib-dummy [presentation]="ePresentation.simpleRow" [numberOfDummies]="5"></lib-dummy>
    }
  </div>
</div>

<div mat-dialog-actions>
  @if (isValueDefinitionsCheckedOverLimit) {
    <div class="mv-4">
      <lib-info-banner
        class="small"
        type="error"
        [message]="
          'A formula only supports up to {maxVariables} variables. Please remove the extra.'
            | translate: { maxVariables: this.valueDefinitionsCheckedLimit }
        "
      ></lib-info-banner>
    </div>
  }
  <button type="submit" class="btn btn-success" (click)="addVariables()" [disabled]="!isValueDefinitionsCheckedValid">
    <div class="btn-text">
      {{
        "Add {count, plural, =0{variable} other{{count} variables} }"
          | translate: { count: valueDefinitionsChecked.length }
      }}
    </div>
  </button>
</div>
