@if (control) {
  <div class="card card-required p-0" [ngClass]="{ active: selected }">
    <div class="pv-2 ph-4 fx-row justify-space-between">
      <h4 class="pv-2 mat-subtitle-2">{{ label }}</h4>
    </div>
    <lib-divider></lib-divider>
    @if (!selected) {
      <div class="ph-4 pt-2 pb-3 fx-row justify-space-between" (click)="select()">
        @if (!control.value) {
          <div class="color-grey-800">{{ placeholder }}</div>
        }
        @if (control.value && !selected) {
          <div class="text" [innerHTML]="control.value | sanitizeHtml"></div>
        }
        <a (click)="select()">
          <mat-icon svgIcon="edit"></mat-icon>
        </a>
      </div>
    }
    @if (selected) {
      <div>
        <lib-rich-text-input
          class="formfield large no-highlight"
          [control]="control"
          [autofocus]="true"
          (blur)="blur()"
        ></lib-rich-text-input>
      </div>
    }
  </div>
}
