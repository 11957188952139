<section class="section section-secondary">
  <div class="section-header">
    <h4 class="mat-subtitle-2 mb-4 color-grey-900">{{ title }}</h4>
    <lib-link class="mat-body-1" [text]="linkText" [routerLink]="link"></lib-link>
  </div>
  <div class="grid grid-6">
    @for (item of initialItems; track item) {
      <div class="card card-dummy"></div>
    }
  </div>
  <div class="grid grid-6">
    @for (item of items; track item) {
      <lib-card
        *libFeatureFlag="{ featureFlags: item.featureFlag }"
        [item]="item"
        [presentation]="ePresentation.card"
      ></lib-card>
    }
  </div>
</section>
