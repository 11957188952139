<div class="list-container" [ngClass]="containerClass">
  @if (tagAssociations) {
    @if (tagAssociations.report.count) {
      <div class="list-content">
        {{ "Reports: {count}" | translate: { count: tagAssociations.report.count } }}
      </div>
    }
    @if (tagAssociations.metric.count) {
      <div class="list-content">
        {{ "Metrics: {count}" | translate: { count: tagAssociations.metric.count } }}
      </div>
    }
    @if (tagAssociations.document.count) {
      <div class="list-content">
        {{ "Documents: {count}" | translate: { count: tagAssociations.document.count } }}
      </div>
    }
    @if (tagAssociations.benchmark.count) {
      <div class="list-content">
        {{ "Benchmarks: {count}" | translate: { count: tagAssociations.benchmark.count } }}
      </div>
    }
  }
  @if (hasNoAssociations()) {
    <div class="list-content">{{ "Not used" | translate }}</div>
  }
</div>
