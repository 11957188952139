<div class="item-dummies bkg-white h-100">
  @if (header) {
    <div class="mb-8">
      <div class="caption2 mb-1 rounded-corners bkg-blink w-25">&nbsp;</div>
      <h3 class="mat-body-1 rounded-corners bkg-blink w-100 mb-2">&nbsp;</h3>
      <div class="caption2 mb-3 rounded-corners bkg-blink w-50">&nbsp;</div>
      <div class="mat-body-1 rounded-corners bkg-blink w-100 mb-5">&nbsp;</div>
    </div>
  }
  @if (body) {
    <div class="repeat bkg-white">
      @for (i of [1, 2, 3, 4, 5, 6, 7]; track i) {
        <div class="mb-2">
          <div class="caption2 mb-1 rounded-corners bkg-blink w-25">&nbsp;</div>
          <div class="mat-body-2 rounded-corners bkg-blink w-50">&nbsp;</div>
        </div>
      }
    </div>
  }
</div>
