<div class="text-with-tooltip-menu">
  <span>{{ textItem }}</span>
  @if (additionalTextItems.length > 0) {
    <button (click)="$event.stopPropagation()" lib-button [matMenuTriggerFor]="menu" class="menu-trigger">
      +{{ additionalTextItems.length }}<mat-icon [svgIcon]="'arrow-head-down'"> </mat-icon>
    </button>
  }
  <mat-menu #menu="matMenu">
    @for (item of additionalTextItems; track item) {
      <button mat-menu-item>{{ item }}</button>
    }
  </mat-menu>
</div>
