import { SourceType, Status } from './ui';
import { Indicator, Metric, PlatformValueGroupSetStatus, ValueGroupSet } from './metrics';
import { Permission } from './configs';
import { FiscalYear } from './fiscal-years';
import { PeerDataValueNullCategories, Value } from './values';
import { MinimalTaxonomy } from './taxonomies';
import { SOURCE_CONFIGURATION } from './client';

interface Entity {
  id: string;
  name: string;
  code?: string;
  created?: Date | string;
  created_by?: string;
  updated?: Date | string;
  updated_by?: string;
  active?: boolean;
}

interface CreatedUpdatedEntity {
  created?: Date | string;
  created_by?: string;
  updated?: Date | string;
  updated_by?: string;
}

export interface ApiResponseMeta {
  count?: number;
  total_count?: number;
  task_execution_id?: string;
  status_count_map?: Record<PlatformValueGroupSetStatus, number>;
}

export interface ApiCall<T> {
  loading: boolean;
  status?: Status;
  data?: T;
  errors?: any[];
}

export interface ApiError<T> {
  detail?: T;
  type: string;
}

export interface ApiResponse<T = void, E = unknown> {
  meta: ApiResponseMeta;
  errors: ApiError<E>[];
  data: T;
}

export interface VersionInfo {
  date?: string;
  sentry_release_name?: string;
  version: string;
}

// Database models

export {
  // Generic
  Entity,
  CreatedUpdatedEntity,
  // Main Entities
  Doc,
  MinimalEntity,
  Dataset,
  DocMetaData,
  Source,
  UploadDocument,
  // Helpers
  CodeCheck,
  DisplayIndicator,
  Framework,
  Industry,
  MetricEquivalent,
  OauthProvider,
  Org,
  RequestDocMetadata,
  SamlProvider,
  Section,
  SectionDisplay,
  SectionNodeIndicator,
  SectionTemplate,
  SectionNode,
  StandardCodes,
  PublicDocUploadedFileMetaData,
  SourceAddress,
  Tag,
  Token,
  TopicCategory,
  TopicGroup,
  Topic,
  TwoFactorStatus,
  Unit,
  LimitedUser,
  ExternalLimitedUser,
  User,
  UserEmailCheck,
  UserType,
  SelectionSet,
  SelectionSetItem,
  UnitGroup,
  UnitGroupItem,
  Company,
  CompanyAddress,
  Client,
  DataTableDisplay,
  IntegrationPartnerLogin,
  CDPSector,
  WDIIndustry,
  MarketIdentifierCode,
  OrgFiscalYearInfo,
};

interface OauthProvider {
  id: string;
  type: 'microsoft' | 'google' | 'okta' | 'auth0';
  label: string;
}

interface SamlProvider {
  id: string;
  type: 'microsoft' | 'google' | 'okta' | 'auth0' | 'generic';
  label: string;
}

interface Source extends Entity {
  name: string;
  is_default: boolean;
  parent_business_unit_ids: string[];
  type: SourceType;
  company_id: string;
  slug: string;
  consolidation: boolean;
  start_fiscal_year_frequency_code: string;
  start_fiscal_year: FiscalYear;
  industries?: string[];
  org_id?: string;
  fiscal_years?: FiscalYear[];
  address?: SourceAddress;
  external_id?: string;
  end_fiscal_year_frequency_code?: string;
  end_fiscal_year?: FiscalYear;
  level?: number;
}

export interface ListSourceRequest {
  orderBy?: string;
  orderByDirection?: 'asc' | 'desc';
  businessUnitType?: string;
  parent_id?: string;
  page?: number;
  pageSize?: number;
  searchQuery?: string;
  consolidation?: boolean;
  max_level?: number;
  level?: number;
  with_ancestor?: boolean;
}

interface Dataset extends Entity {
  from_year: number;
  to_year: number;
  metrics: Metric[];
  sources?: Source[];
}

interface Doc extends Entity {
  scope: 'client' | 'report' | 'resource';
  org_id?: string;
  org_name?: string;
  format: string;
  category: string;
  type: string;
  url?: string;
  public: boolean;
  year?: number;
  industries?: string[];
  tags?: string[];
  storage_filename: string;
  start: string;
  end: string;
}

interface DocMetaData {
  name: string;
  category: string;
  doc_type: string;
  fiscal_year: string | number;
  data_period_start: string;
  data_period_end: string;
  public?: boolean;
  business_unit_ids: string[];
  tag_ids?: string[];
  deleted?: string;
  extension?: string;
  id?: string;
  original_filename?: string;
  url: string;
}

export enum MetricCategory {
  THIRD_PARTY = 'third_party',
  REFERENCE = 'reference',
  PEER = 'peer',
  CUSTOM = 'custom',
  ARCHIVED = 'archived',
}

export enum StandardCodeCategory {
  RATING = 'rating',
  REGULATION = 'regulation',
  REPORTING_FRAMEWORK = 'reporting_framework',
  REPORTING_STANDARD = 'reporting_standard',
}

export enum FrameworkTaxonomyCategory {
  RATING = 'rating',
  REGULATION = 'regulation',
  REPORTING_FRAMEWORK = 'reporting_framework',
  REPORTING_STANDARD = 'reporting_standard',
  QUESTIONNAIRE_FRAMEWORK = 'questionnaire_framework',
}

export enum FrameworkCategory {
  STANDARD_CODE = 'standard_code',
  METRIC = 'metric',
}

interface Framework extends Entity {
  metrics?: Metric[];
  image_url?: string;
  metric_category?: MetricCategory;
  standard_code_category?: StandardCodeCategory;
  category?: FrameworkCategory;
}

interface StandardCodes {
  active: boolean;
  code: string;
  created: string;
  created_by: string;
  description: string;
  end: string;
  framework?: Framework;
  framework_id: string;
  guidance?: string;
  disclaimers?: string;
  id: string;
  industries: Industry[];
  taxonomies: MinimalTaxonomy[];
  topics: Topic[];
  tags: Tag[];
  metrics: Metric[];
  start: string;
  updated: string;
  updated_by: string;
  name: string;
}

interface Industry extends Entity {
  description?: string;
  sector_id?: string;
}

interface MinimalEntity extends Entity {
  description: string;
}

interface MetricEquivalent {
  original_metric_id: string;
  equivalent_metric_id: string;
  match: 'full' | 'partial';
  created: string;
  updated: string;
}

interface CodeCheck {
  available: boolean;
}

interface CDPSector extends CreatedUpdatedEntity {
  id: string;
  name: string;
}

interface WDIIndustry extends CreatedUpdatedEntity {
  id: string;
  name: string;
}

interface MarketIdentifierCode {
  id: string;
  name: string;
}

interface OrgFiscalYearInfo {
  end?: string | null;
  start: string;
  regular: boolean;
}

interface Org extends Entity {
  slug?: string;
  favorited?: boolean;
  brand_name: string | null;
  website: string | null;
  headquarter_location: string | null;
  listed: boolean | null;
  lucid_axon: boolean | null;
  fte: number | null;
  stock_exchange_market: string | null;
  ticker: string | null;
  primary_isin: string | null;
  lei: string | null;
  cdp_account_number: string | null;
  cdp_sector: CDPSector | null;
  wdi_industry: WDIIndustry | null;
  industries?: Industry[];
  fiscal_year_information?: OrgFiscalYearInfo;
}

interface Section extends SectionTemplate {
  indicators?: Indicator[];
  display?: SectionDisplay[];
}

interface SectionDisplay {
  code?: string;
  description?: string;
  display_indicators?: DisplayIndicator[];
  metametric: boolean;
}

interface DataTableDisplay {
  year: number;
  value?: Value;
  vgs?: ValueGroupSet;
}

interface DisplayIndicator {
  indicator_id: string;
  value_definition_group_id?: string;
  value_definition_id?: string;
  value_label?: string;
  yearlyData?: DataTableDisplay[];
  indicator?: Indicator;
}

interface SectionTemplate extends Entity {
  label?: string;
  position: number;
  parent_id?: string | null;
  children?: Section[];
  metrics?: Metric[];
}

interface SectionNodeIndicator extends SectionDisplay {
  indicator: Indicator;
}

interface SectionNode<T = any> {
  id?: number;
  section_id?: string;
  section_position?: number;
  section_parent_id?: string | null;
  error?: boolean;
  children?: SectionNode<T>[];
  metrics?: Metric[];
  item?: T;
  parent?: Section;
  isLastItem?: boolean;
  display_items?: SectionDisplay[];
  level?: number;
  isExpanded?: boolean;
  loading?: boolean;
}

interface RequestDocMetadata {
  data_period_end: string;
  data_period_start: string;
  extension: string;
  fiscal_year: number;
  id: string;
  name: string;
  updated: string;
  deleted?: string;
}

interface PublicDocUploadedFileMetaData extends Omit<Entity, 'name'> {
  container: string;
  file_hash: string;
  extension: string;
  meta: {
    business_unit_ids: string[];
    category: string;
    data_period_end: string;
    data_period_start: string;
    doc_type: string;
    fiscal_year: string;
    name: string;
    org_id: string;
    public: boolean;
    scope: string;
    tag_ids: string[];
  };
  original_filename: string;
}

export interface Association {
  count: number;
  resource_ids: string[];
}

export interface TagAssociations {
  metric: Association;
  report: Association;
  benchmark: Association;
  document: Association;
}

interface Tag extends Entity {
  label: string;
  short_label: string;
  core_tag_id?: string;
  associations?: TagAssociations;
}

interface TopicCategory extends Entity {
  slug: string;
  topic_groups: TopicGroup[];
}

interface TopicGroup extends Entity {
  slug: string;
  topic_category_id: string;
  topics: Topic[];
}

interface Topic extends Entity {
  slug: string;
  topic_group_id: string;
}

interface Token {
  access_token: string;
  refresh_token: string;
  redirect_uri?: string;
  hydra_redirect_uri?: string;
}

interface TwoFactorStatus {
  enabled: boolean;
}

interface Unit {
  id: string;
  code: string;
  symbol: string | null;
  label: string;
  family: string;
  position: number;
}

interface UploadDocument {
  document_uploaded_id: string;
  parsing_job_id: string;
}

interface LimitedUser {
  id: string;
  active: boolean;
  first_name: string;
  last_name: string;
  email: string;
  type: UserType;
  title?: string;
  department?: string;
}

interface ExternalLimitedUser {
  email: string;
  first_name: boolean;
  last_name: string;
  user_id: string;
  client_code: string;
  roles: string[];
  permissions: Permission[];
  scopes: string[];
}

interface User extends LimitedUser, Entity {
  aud: string;
  active: boolean;
  allow_basic_auth: boolean;
  expire_on?: Date | string;
  client_code: string;
  client_id: string;
  exp: number;
  iat: number;
  iss: string;
  org_id: string;
  org_name: string;
  roles: string[];
  user_id: string;
  username: string;
  access_token: string;
  refresh_token: string;
  rexp: number;
  password_last_changed?: string;
  default_to_hq_fiscal_year: boolean;
  require_source_on_documents: boolean;
  permissions: Permission[];
  twofactor_status?: TwoFactorStatus;
}

interface UserEmailCheck {
  available: boolean;
}

enum UserType {
  STANDARD = 'standard',
  PUBLIC = 'public',
}

interface SelectionSet extends Entity {
  description: string;
  public: boolean;
  selection_set_items: SelectionSetItem[];
}

interface SelectionSetItem extends Entity {
  tooltip?: string;
  position: number;
  public: boolean;
  selection_set_id: string;
  display_explanation?: boolean;
  explanation_required?: boolean;
}

interface UnitGroup extends Entity {
  description: string;
  public: boolean;
}

interface UnitGroupItem extends Entity {
  tooltip?: string;
  symbol?: string;
  position: number;
  public: boolean;
  unit_group_id: string;
}

interface Company extends Entity {
  name: string;
  business_units: Source[];
  parent_company_id: string;
  child_companies: Company[];
  address?: CompanyAddress;
}

interface CompanyAddress {
  address_1: string;
  address_2?: string;
  city: string;
  country: string;
  state: string;
  zip?: string;
}

// https://www.npmjs.com/package/iso3166-2-db/v/2.2.0?activeTab=readme
export interface Iso3166Region {
  // next keys will contain name in selected language
  name: string;
  // next keys is not exists in language-reduced pack
  names?: {
    geonames?: string | null;
    ru?: string | null;
    en?: string | null;
    de?: string | null;
    es?: string | null;
    fr?: string | null;
    it?: string | null;
    zh?: string | null;
  } | null;
  iso: string; // iso3166-2 code
  fips: string; // FIPS code
  // next key is exist only in `_ref` exports
  reference?: {
    geonames?: number | null; // geonames.org
    openstreetmap?: number | null; // openstreetmap relation id
    openstreetmap_level?: number | null; // openstreetmap administrative division level
    wikipedia?: string | null; // wikipedia article
    wof?: string | null; // WOF code
  } | null;
}

// https://www.npmjs.com/package/iso3166-2-db/v/2.2.0?activeTab=readme
export interface Iso3166Country {
  iso: string; //iso3166-1 `two` letter code
  iso3: string; //iso3166-1 `three` letter code
  numeric: number; //iso3166-1 `numeric` code
  fips: string; //FIPS code
  reference?: {
    // external reference to
    geonames?: number | null; // geonames.org
    openstreetmap?: number | null; // openstreetmap releation id
    wikipedia?: string | null; // wikipedia article
  } | null;
  // next key is not exist in full pack
  name: string;
  // next key is not exist in language-reduced pack
  names?: {
    // names in different languages
    geonames?: string | null;
    en?: string | null;
    ar?: string | null;
    de?: string | null;
    es?: string | null;
    fr?: string | null;
    it?: string | null;
    ru?: string | null;
    zh?: string | null;
  } | null;
  regions: Iso3166Region[] /* set of regions */;
}

// https://www.npmjs.com/package/iso3166-2-db/v/2.2.0?activeTab=readme
export interface Iso3166Countries {
  [key: string]: Iso3166Country;
}

export interface Country {
  name: string;
  iso: string;
}

export interface CountryState {
  name: string;
  iso: string;
  countryIso: string;
}

interface SourceAddress {
  address_1?: string;
  address_2?: string;
  city?: string;
  country?: string;
  state?: string;
  zip?: string;
}

interface Client {
  created: string;
  created_by: string | null;
  updated: string;
  updated_by: string | null;
  id: string;
  name: string;
  org_id: string;
  code: string;
  agent_id: string | null;
  require_source_on_documents: boolean;
  allow_draft_templates: boolean;
  communication_lang: string[];
  source_configuration: SOURCE_CONFIGURATION;
  enable_basic_auth: boolean;
  regular_fiscal_years: boolean;
  integration_green_project_enabled: boolean;
  integration_edci_enabled: boolean;
  integration_tableau_enabled: boolean;
  integration_s_and_p_enabled: boolean;
  integration_cdp_enabled: boolean;
  integration_minimum_enabled: boolean;
  integration_sinai_enabled: boolean;
  client_id: string;
  org_name: string;
  integration_partner_logins: IntegrationPartnerLogin[];
  real_time_consolidation: boolean;
  real_time_calculated_field_massive_calculations: boolean;
}

interface IntegrationPartnerLogin {
  client_id: string;
  integration_type: string;
  url: string;
  enabled: boolean;
}

export enum DeactivateEntityTypes {
  FIELD = 'Field',
  GROUP = 'Group',
  TABLE = 'Table',
}

export enum MetricValueDefinitionAttribute {
  all = 'all',
  explanation = 'explanation',
  pageNumber = 'page_number',
  url = 'url',
}

export enum FieldValueComparisonTableExportFormat {
  csv = 'csv',
  xlsx = 'xlsx',
}

export interface FieldValueComparisonTableRowListValue {
  empty?: boolean;
  link?: string;
  value: string;
  children?: FieldValueComparisonTableRowListValue[];
}

export interface FieldValueComparisonTableRowValue {
  nullCategory: boolean;
  empty: boolean;
  value: string | FieldValueComparisonTableRowListValue[];
}

export interface FieldValueComparisonTableColumn {
  attribute: MetricValueDefinitionAttribute;
  entityId: string;
  nullCategories?: (PeerDataValueNullCategories | null)[];
  valueDefinitionId: string;
  values?: unknown[];
}

export interface FieldValueComparisonTableRow
  extends Record<string, FieldValueComparisonTableRowValue | boolean | string | undefined> {
  id: string;
  attribute: MetricValueDefinitionAttribute;
  code: string;
  description: string;
  label: string;
  list: boolean;
  richText: boolean;
  unit?: string;
  table: boolean;
  valueDefinitionId: string;
}

export interface FieldValueComparisonTableValueDefinition {
  id: string;
  attribute: MetricValueDefinitionAttribute;
}

export interface FieldValueComparisonTableMetric {
  id: string;
  value_definitions: FieldValueComparisonTableValueDefinition[];
}

export interface FieldValueComparisonTablePayloadState {
  horizontalEntities: string[];
  metrics: FieldValueComparisonTableMetric[];
  verticalEntity: string;
}

export interface MetricFieldVisibility {
  value_definition_id: string | null;
  metric_table_definition_id: string | null;
  visibility: boolean;
}

export interface MetricFieldVisibilityPerFiscalYear {
  fiscal_year_frequency_code: string;
  field_visibility: MetricFieldVisibility[];
}

export interface MetricVisibleFields {
  value_definition_ids: string[];
  metric_table_definition_ids: string[];
}
