<button mat-menu-item class="menu-title" disabled>{{ "Framework Taxonomy" | translate }}</button>
@if (control && items.length > minMenuScrollItems) {
  <div class="menu-search" (click)="$event.stopPropagation()">
    <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
    <input
      matInput
      type="text"
      [placeholder]="'Search' | translate"
      autocomplete="off"
      (keydown)="$event.stopPropagation()"
      [formControl]="control"
    />
    @if (control.value) {
      <button class="clear-search" (click)="control.setValue('')">
        <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
      </button>
    }
  </div>
}
<hr />
<button mat-menu-item (click)="setItem.emit()">{{ "All" | translate }}</button>
<div [ngClass]="{ 'menu-items-container': items.length > minMenuScrollItems }">
  @if (filteredItems | async; as taxonomies) {
    <lib-filter-with-category
      [filterCategoryTranslations]="frameworkTaxonomyCategoryTranslations"
      [filteredOptions]="taxonomies"
      [filterFunction]="filterTaxonomies"
      [activeOptionId]="selectedItemId"
      (optionSelected)="setItem.emit($event)"
    ></lib-filter-with-category>
  }
</div>
