import { Pipe, PipeTransform } from '@angular/core';
import { FiscalYearPeriod, Frequency } from '../../models';
import dayjs from 'dayjs';

@Pipe({
  name: 'frequencyFieldLabel',
})
export class FrequencyFieldLabelPipe implements PipeTransform {
  transform(fiscalYearPeriod: FiscalYearPeriod, frequency?: Frequency): string {
    return this.formatLabel(fiscalYearPeriod, frequency);
  }

  private formatLabel(fiscalYearPeriod: FiscalYearPeriod, frequency?: Frequency): string {
    if (frequency === Frequency.MONTHLY && fiscalYearPeriod.start) {
      const startDate = dayjs(fiscalYearPeriod.start);
      if (!startDate.isValid()) {
        return fiscalYearPeriod.period;
      }
      return startDate.format('MMMM');
    }
    return fiscalYearPeriod.period;
  }
}
