<div class="panel panel-tools panel-expanded">
  <div class="panel-tools-bar">
    <a class="close-tools" (click)="close()" role="button" aria-label="close-panel">
      <mat-icon svgIcon="arrow-head-right"></mat-icon>
    </a>
    @for (panelAction of _panelActions; track panelAction) {
      <a
        (click)="applyAction(panelAction)"
        [ngClass]="{ active: panelAction.id === selectedAction?.id }"
        role="button"
        [attr.aria-label]="panelAction.title"
      >
        <mat-icon svgIcon="{{ panelAction.icon }}"></mat-icon>
      </a>
    }
  </div>
  <div class="panel-container" [ngClass]="{ loaded: loaded }">
    <div class="fx-col h-100" style="position: relative">
      <div class="item-dummies-container">
        <div class="item-dummies bkg-white h-100">
          <div class="caption2 mb-1 rounded-corners bkg-blink w-25">&nbsp;</div>
          <h3 class="mat-body-1 rounded-corners bkg-blink w-100 mb-2">&nbsp;</h3>
          <div class="caption2 mb-3 rounded-corners bkg-blink w-50">&nbsp;</div>
          <div class="mat-body-1 rounded-corners bkg-blink w-100 mb-5">&nbsp;</div>
        </div>
      </div>
      <div class="panel-header" data-testid="panel-header">
        <div class="w-100">
          <div class="fx-row justify-space-between align-center">
            <div>
              <h3>{{ selectedAction?.title }}</h3>
            </div>
            <div>
              <ng-content select="[titleAction]"></ng-content>
            </div>
          </div>
          <lib-divider class="mv-2 color-grey-800"></lib-divider>
        </div>
      </div>

      <div class="panel-content overlay-container" [ngClass]="{ loaded: loaded }">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
