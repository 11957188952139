<div mat-dialog-title class="mb-4">
  <h3 class="mat-h3">{{ "Displayed values for {code}" | translate: { code: templateData.metricCode } }}</h3>
  <a (click)="closeDialog()">
    <mat-icon svgIcon="close"></mat-icon>
  </a>
</div>
<div mat-dialog-content>
  @if (form) {
    <form [formGroup]="form">
      @for (dv of form.controls.displayedValues.controls; track dv) {
        @if (dv.controls.type.value !== eValueDefinitionDisplayType.tip) {
          <div>
            @if (dv.controls.type.value === eValueDefinitionDisplayType.subtitle) {
              <h4>
                {{ getSubtitleValue(dv.controls.valueDefinitionId.value) }}
              </h4>
            }
            @if (dv.controls.type.value !== eValueDefinitionDisplayType.subtitle) {
              <div>
                <lib-checkbox
                  [attr.data-testid]="'checkbox-' + dv.controls.valueDefinitionId.value"
                  [defaultValue]="dv.controls.isChecked.value"
                  [label]="dv.controls.label.value || ''"
                  (checkboxToggle)="handleCheckboxChange($event, dv.controls.isChecked)"
                >
                </lib-checkbox>
                <div class="ml-3">
                  @if (dv.controls.isChecked.value) {
                    <lib-text-input
                      [attr.data-testid]="'overriden-label-input-' + dv.controls.valueDefinitionId.value"
                      type="text"
                      [label]="'Label override' | translate"
                      [control]="dv.controls.overriddenLabel"
                      [size]="eValueDefinitionSize.small"
                    ></lib-text-input>
                  }
                </div>
              </div>
            }
          </div>
        }
      }
    </form>
  }
</div>
<div mat-dialog-actions>
  <button
    lib-button
    buttonType="primary"
    (click)="save()"
    [disabled]="!form?.hasCheckedValue"
    data-testId="saveDisplayedValuesButton"
  >
    <span>{{ "Save" | translate }}</span>
  </button>
  <button lib-button buttonType="cancel" (click)="resetAll()">
    <span>{{ "Reset all" | translate }}</span>
  </button>
</div>
