import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { BtnTypeCssClass, EmptyResults, ViewerActionType } from '../../models';

@Component({
  selector: 'lib-empty-results',
  templateUrl: './empty-results.component.html',
  styleUrls: ['./empty-results.component.scss'],
})
export class EmptyResultsComponent implements OnChanges {
  @Input({ required: true }) displayData!: EmptyResults;

  @Output() action: EventEmitter<void> = new EventEmitter<void>();

  viewerActionType: typeof ViewerActionType = ViewerActionType;
  btnClass: typeof BtnTypeCssClass = BtnTypeCssClass;
  showDivider: boolean = false;

  public ngOnChanges(): void {
    this.showDivider = this.displayData.subtitle || this.displayData.linkText || this.displayData.button ? true : false;
  }

  public onBtnClick(): void {
    this.action.emit();
  }
}
