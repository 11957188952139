@if (widget) {
  <div class="chart-widget-card">
    @if (!chartOptions.navigation?.buttonOptions?.enabled) {
      <button
        lib-button
        class="action-button"
        [matMenuTriggerFor]="menu"
        [matMenuTriggerData]="{ item: widget }"
        data-testid="widgetContextMenu"
      >
        <mat-icon svgIcon="more"></mat-icon>
      </button>
    }
    <lib-chart [chartOptions]="chartOptions"></lib-chart>
  </div>
}

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="removeItem(item)">
      <mat-icon svgIcon="trash"></mat-icon>
      <span>Remove</span>
    </button>
    <button mat-menu-item (click)="editItem(item)">
      <mat-icon svgIcon="edit"></mat-icon>
      <span>Edit</span>
    </button>
  </ng-template>
</mat-menu>
