<section class="section section-primary" *libPermission="allPermissions">
  <div class="section-header">
    <h4 class="mat-subtitle-2 color-grey-900">{{ title }}</h4>
    <lib-link class="mat-body-1" [routerLink]="link" [text]="linkText"></lib-link>
  </div>
  <div class="grid grid-6">
    <ng-content></ng-content>
    @for (item of items; track item) {
      <ng-container *libPermission="item.permissions">
        <lib-card-primary
          *libFeatureFlag="{ featureFlags: item.featureFlag, hiddenWhenEnabled: item.featureFlagHiddenWhenEnabled }"
          [item]="item"
        ></lib-card-primary>
      </ng-container>
    }
  </div>
</section>
