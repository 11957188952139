@if (control) {
  <div class="fx-col">
    <mat-checkbox
      color="primary"
      class="mat-body-2"
      [formControl]="control"
      [required]="required"
      (click)="$event.stopPropagation()"
    >
      <label [ngClass]="{ required: required }">{{ label }}</label>
    </mat-checkbox>
  </div>
}
