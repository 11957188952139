<p-tabView class="safe-anchor" [activeIndex]="selectedIndex" (activeIndexChange)="selectedIndexChange.emit($event)">
  @for (tab of tabs; track tab; let index = $index) {
    <p-tabPanel [header]="tab.customHeader ? '' : tab.title" [selected]="selectedIndex === index">
      @if (tab.customHeader) {
        <ng-template pTemplate="header">
          @if (tab.customHeader) {
            <ng-container [ngTemplateOutlet]="tab.customHeader"></ng-container>
          }
        </ng-template>
      }
      <ng-template pTemplate="content">
        @if (tab.content) {
          <ng-container [ngTemplateOutlet]="tab.content"></ng-container>
        }
      </ng-template>
    </p-tabPanel>
  }
</p-tabView>
