<div class="resizable-vertical-wrapper">
  <div class="resizable-vertical-content">
    <lib-vertical-resizable-content>
      <ng-content></ng-content>
    </lib-vertical-resizable-content>
  </div>
  @if (isResizable) {
    <div class="resizable-bar" (libVerticalResizable)="onResize($event)"></div>
  }
</div>
