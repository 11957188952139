@if (searchFilters$ | async; as searchFilters) {
  <lib-search
    [layout]="eLayout.list"
    [presentation]="ePresentation.card"
    [itemType]="itemTypeAdminTemplatesMetrics"
    mode="drag"
    [sortOrder]="sortOrder"
    [filters]="searchFilters"
    [showActions]="true"
    (action)="handleSearchAction($event)"
  >
  </lib-search>
}
