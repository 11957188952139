@if (value?.value?.length) {
  <ul class="pl-4 square-bullets">
    @for (fileValue of value?.value; track fileValue; let isLast = $last) {
      <li [ngClass]="{ 'mb-1': !isLast }">
        @if (documents | getItemById: fileValue.file_id; as docInfo) {
          <button
            lib-button
            buttonType="link"
            [attr.data-testid]="'fileV2List-' + fileValue.file_id"
            [innerHTML]="docInfo.name + '.' + docInfo.format | sanitizeHtml"
            (click)="downloadFile.emit(docInfo)"
          ></button>
        }
        <ul>
          @if (fileValue.url) {
            <li>
              {{ "URL: {fileUrl}" | translate: { fileUrl: fileValue?.url } | truncate: 40 }}
            </li>
          }
          @if (fileValue.page_number) {
            <li>
              {{ "Page number: {pageNumber}" | translate: { pageNumber: fileValue.page_number } }}
            </li>
          }
          @if (fileValue.explanation) {
            <li>
              {{ value?.type_details.explanation_label || (DEFAULT_EXPLANATION_LABEL | translate) }}:
              {{ fileValue.explanation }}
            </li>
          }
        </ul>
      </li>
    }
  </ul>
}
