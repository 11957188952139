@if (control) {
  <div
    libFormField
    [size]="size"
    [required]="required"
    [control]="control"
    [readonly]="readonly"
    [labelPosition]="labelPosition"
    [dataTestId]="'datetime-field'"
  >
    <lib-form-field-label [label]="label" [control]="control" [for]="_inputId"></lib-form-field-label>
    <p-calendar
      appendTo="body"
      [style]="{ width: '100%' }"
      [showIcon]="true"
      [showClear]="true"
      [inputId]="_inputId"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [showTime]="true"
      [dateFormat]="format"
      hourFormat="12"
      [required]="required"
      [readonlyInput]="readonly"
      [placeholder]="placeholder"
      [formControl]="control"
    ></p-calendar>
    @if (hint) {
      <small class="hint">
        {{ hint }}
      </small>
    }
  </div>
}
