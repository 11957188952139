<mat-menu #menu="matMenu">
  @if (startedRequests.length) {
    <a class="menu-title" mat-menu-item disabled>{{ "In progress" | translate }}</a>
    @for (request of startedRequests; track request) {
      <button mat-menu-item (click)="navigateToRequest(request.id)">
        <span>{{ request.name }}</span>
      </button>
    }
  }

  @if (startedRequests.length && notStartedRequests.length) {
    <lib-divider></lib-divider>
  }

  @if (notStartedRequests.length) {
    <a class="menu-title" mat-menu-item disabled>{{ "Not started" | translate }}</a>
    @for (request of notStartedRequests; track request) {
      <button mat-menu-item (click)="navigateToRequest(request.id)">
        <span>{{ request.name }}</span>
      </button>
    }
  }
</mat-menu>

@if ((metricStructureService.canEditEveryMetrics$ | async) === false) {
  <div class="mt-2">
    @if (startedRequests.length || notStartedRequests.length) {
      <div class="fx-row align-center">
        <mat-icon svgIcon="info" class="color-grey-800 mr-2"></mat-icon>
        <span>{{ "This metric is in an open request." | translate }}</span>
        <button lib-button buttonType="link" [matMenuTriggerFor]="menu">
          <span>{{ "See requests" | translate }}</span>
          <mat-icon svgIcon="arrow-head-down" class="ml-1"></mat-icon>
        </button>
      </div>
    }
  </div>
}
