<div class="card w-50">
  @if (metric) {
    <div class="card-content">
      <div class="pr-2">
        <div class="caption2 color-grey-800 line-clamp">
          <span> {{ metric.code }}</span>
        </div>
        <div class="mat-body-2 color-primary-900 text-clamp text-clamp-2 mt-1 mb-2">
          {{ metric.description }}
        </div>
      </div>
    </div>
  }
  <div class="card-footer">
    <a class="btn btn-primary" (click)="onEdit()">{{ "Edit" | translate }}</a>
  </div>
</div>
