<mat-menu #errorDetailsMenu="matMenu" yPosition="above">
  <ng-template matMenuContent>
    <div class="p-2">
      {{ calculationErrorMsg }}
    </div>
  </ng-template>
</mat-menu>

<div
  class="numeric-input-container"
  [title]="this.readonly ? (controlNumberDisplay.value | numberMask: maxDecimals) : ''"
>
  <div
    class="error-menu-trigger"
    #errorDetailsMenuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="errorDetailsMenu"
  ></div>

  @if (control) {
    <div
      libFormField
      [size]="size"
      [required]="required"
      [control]="control"
      [readonly]="readonly"
      [labelPosition]="labelPosition"
      [dataTestId]="'numeric-field'"
    >
      @if (numberMaskConfig$ | async; as numberMaskConfig) {
        <lib-form-field-label
          [sameSizeLabels]="sameSizeLabels"
          [label]="label"
          [control]="control"
          [for]="_inputId"
          [displayReset]="!isCalculated"
          [fieldInfo]="fieldInfo"
          [isBypassConsolidation]="isBypassConsolidation"
        ></lib-form-field-label>
        <mat-form-field [ngClass]="{ 'maximum-field-width': true, 'input-with-left-label': labelPosition === 'left' }">
          @switch (control | numericInputType: control.disabled || this.readonly) {
            @case (eNumericInputType.notApplicable) {
              <input
                #input
                matInput
                class="color-grey-900"
                type="text"
                [formControl]="control"
                [errorStateMatcher]="errorStateMatcher"
                [attr.aria-label]="_inputId"
                [id]="_inputId"
                [readonly]="control.disabled || this.readonly"
                data-testid="numeric-field-input-not-applicable"
              />
            }
            @case (eNumericInputType.readonly) {
              <input
                #input
                matInput
                class="color-grey-900"
                type="text"
                [formControl]="controlNumberDisplay"
                [errorStateMatcher]="errorStateMatcher"
                [attr.aria-label]="_inputId"
                [id]="_inputId"
                [placeholder]="placeholder"
                [readonly]="true"
                [mask]="maskNumberDisplay"
                [thousandSeparator]="numberMaskConfig.thousandSeparator"
                [decimalMarker]="numberMaskConfig.decimalMarker"
                [allowNegativeNumbers]="true"
                [clearIfNotMatch]="false"
                libFocusState
                data-testid="numeric-field-input-display"
              />
            }
            @case (eNumericInputType.full) {
              <input
                #input
                matInput
                class="color-grey-900"
                type="text"
                [formControl]="control"
                [errorStateMatcher]="errorStateMatcher"
                [attr.aria-label]="_inputId"
                [id]="_inputId"
                [placeholder]="placeholder"
                [readonly]="control.disabled || this.readonly"
                [mask]="maskInput"
                [thousandSeparator]="numberMaskConfig.thousandSeparator"
                [decimalMarker]="numberMaskConfig.decimalMarker"
                [allowNegativeNumbers]="true"
                [clearIfNotMatch]="false"
                libFocusState
                data-testid="numeric-field-input"
              />
            }
          }
          @if (hint) {
            <mat-hint class="fx-row justify-space-between w-100">
              <span>{{ hint }}</span>
            </mat-hint>
          }
          @if (control.status === "PENDING") {
            <lib-spinner [diameter]="16" matSuffix></lib-spinner>
          }
          <div matSuffix class="color-grey-900 fx-row align-center">
            <span>{{ suffix }}</span>
            @if (isCalculated && calculationErrorMsg) {
              <div class="error-msg-icon">
                <mat-icon
                  aria-hidden="false"
                  aria-label="errorIcon"
                  class="ml-1 color-warning"
                  svgIcon="warning"
                  (mouseenter)="openErrorDetailsmenu()"
                ></mat-icon>
              </div>
            }
          </div>
          @for (errorKey of parentControl?.errors ?? null | errorKeys; track errorKey) {
            <mat-hint class="mat-error">{{ errorMessages[errorKey] }}</mat-hint>
          }
          @for (errorKey of control.errors | errorKeys; track errorKey) {
            <mat-error>{{ errorMessages[errorKey] }}</mat-error>
          }
          @if (control.errors?.min && !messages.min) {
            <mat-error
              >{{ "Value must not be below {count}" | translate: { count: control.errors?.min.min } }}
            </mat-error>
          }
          @if (control.errors?.max && !messages.max) {
            <mat-error>
              {{ "Value must not be above {count}" | translate: { count: control.errors?.max.max } }}
            </mat-error>
          }
          @if (control.errors?.nonNumericError) {
            <mat-error>
              {{ "Value must be a valid number" | translate }}
            </mat-error>
          }
        </mat-form-field>
      }
    </div>
  }
</div>
