<div #metricTable class="metric-table-container" [attr.data-testid]="'metric-table-' + metricTableGroup.table_id">
  <lib-metric-structure-table-details
    [metricTableGroup]="metricTableGroup"
    [isDeactivated]="(metricTableGroup | isDeactivated) || !!metricTableGroup.hidden_by_taxonomy"
  >
  </lib-metric-structure-table-details>

  <div
    class="overlay"
    [ngClass]="{
      active: isActive,
    }"
    (click)="setSelectedItem(metricTableGroup)"
    [attr.data-testid]="'overlay-' + metricTableGroup.table_id"
  >
    <div class="actions">
      @if (!(metricTableGroup.valueDefinitionGroups[0] | isEntityFromCoreRefV2Metric: metric)) {
        @if (canMoveDown) {
          <button class="btn btn-sm" data-testid="moveTableDown" (click)="moveTableDown($event)">
            {{ "Move Down" | translate }}
          </button>
        }
        @if (canMoveUp) {
          <button class="btn btn-sm" data-testid="moveTableUp" (click)="moveTableUp($event)">
            {{ "Move Up" | translate }}
          </button>
        }
      }
      @if (
        (!isAdmin && !metricTableGroup.core_metric_table_definition_id) ||
        (isAdmin && !metricTableDefinition?.published)
      ) {
        <button class="btn btn-sm" (click)="deleteTable($event)">
          {{ "Delete" | translate }}
        </button>
      }

      @if (deactivationEnabled || (isAdmin && metricTableDefinition?.published)) {
        @if (
          (metricTableGroup | isDeactivated) ||
          (metricTableGroup.hidden_by_taxonomy && !metricTableGroup.ignore_taxonomies)
        ) {
          <button class="btn btn-sm" (click)="activateMetricTableGroup()" aria-label="activate-table">
            {{ "Activate" | translate }}
          </button>
        } @else {
          <button aria-label="group-deactivation" class="btn btn-sm" (click)="deactivateMetricTableGroup()">
            {{ "Deactivate" | translate }}
          </button>
        }
      }
    </div>
  </div>
</div>

<div class="mt-5 table-totals-container">
  @for (tableTotal of tableTotals; track tableTotal) {
    <div class="bkg-grey-50 p-2 color-primary-900 table-total-card" [ngClass]="{ inactive: !tableTotal.active }">
      <p>{{ tableTotal.label }}</p>
      <p>
        <span>{{ "Total renders here" | translate }}</span>
        @if (tableTotal.type_details.units | unitSymbol; as unit) {
          <span>
            {{ " (in " + unit + ")" }}
          </span>
        }
      </p>
    </div>
  }
</div>
