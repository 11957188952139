@if (control) {
  <div
    libFormField
    [size]="size"
    [required]="required"
    [control]="control"
    [readonly]="readonly"
    [labelPosition]="labelPosition"
    [dataTestId]="'date-field'"
  >
    <lib-form-field-label [label]="label" [control]="control" [for]="_inputId"></lib-form-field-label>
    <mat-form-field class="w-100" floatLabel="auto">
      <input
        matInput
        [formControl]="tempControl"
        [matDatepicker]="picker"
        class="color-grey-900"
        [placeholder]="placeholder"
        [id]="_inputId"
        name="date-field"
        #focusElement
        libFocusState
        [focusStateControl]="control"
        [readonly]="readonly"
        [min]="minDate"
        [max]="maxDate"
        data-testid="date-field-input"
      />
      <mat-datepicker-toggle
        class="datepicker-toggle-padding"
        matSuffix
        [for]="picker"
        [disabled]="tempControl.disabled"
      >
        <mat-icon matDatepickerToggleIcon svgIcon="calendar" class="datepicker-icon"></mat-icon>
      </mat-datepicker-toggle>
      @if (tempControl.value && !tempControl.disabled) {
        <mat-datepicker-toggle matSuffix (click)="clearSelectedDate()">
          <mat-icon
            matDatepickerToggleIcon
            class="datepicker-icon"
            svgIcon="clear"
            data-testid="clearSelectedDate"
          ></mat-icon>
        </mat-datepicker-toggle>
      }
      <mat-datepicker #picker></mat-datepicker>
      @if (hint) {
        <mat-hint>{{ hint }}</mat-hint>
      }
      @for (error of tempControl.errors | errorKeys; track error) {
        <mat-error> {{ errorMessages[error] }}</mat-error>
      }
      @if (tempControl.errors?.maxDateRange) {
        <mat-error>
          {{
            "Date must not be above maximum Date {maxDate}"
              | translate: { maxDate: tempControl.errors?.maxDateRange.maxDate }
          }}
        </mat-error>
      }
      @if (tempControl.errors?.minDateRange) {
        <mat-error>
          {{
            "Date must not be below minimum Date {minDate}"
              | translate: { minDate: tempControl.errors?.minDateRange.minDate }
          }}
        </mat-error>
      }
    </mat-form-field>
  </div>
}
