@if (cardListView$ | async; as view) {
  <div class="card-list pt-2">
    <p class="pl-2 selected-count">
      {{ "{count} Selected" | translate: { count: view.count || ("None" | translate) } }}
    </p>
    <ul (libScrollToBottom)="onLoadMore()" class="card-list-scroll" data-testid="card-list-scroll">
      @for (item of items; track trackById($index, item)) {
        <li [attr.aria-label]="item.title">
          <lib-select-item-card
            [item]="item"
            [menuItems]="menuItems[item.id]"
            [disabled]="view.disabledMap[item.id]"
            [selected]="view.selectedMap[item.id] || (markDisabledSelected && view.disabledMap[item.id])"
            (menuAction)="action.emit($event)"
            (selectChange)="onSelectChange(item, $event)"
          >
            <ng-template #extraContent let-item>
              @if (extraContentTmpl) {
                <ng-container
                  [ngTemplateOutlet]="extraContentTmpl"
                  [ngTemplateOutletContext]="{ $implicit: item }"
                ></ng-container>
              }
            </ng-template>
          </lib-select-item-card>
        </li>
      }
      @if (isLoading) {
        <lib-spinner></lib-spinner>
      }
    </ul>
  </div>
}
