@if (routerLink) {
  <a
    [routerLink]="routerLink"
    [fragment]="fragment"
    [target]="openInNewTab ? '_blank' : '_self'"
    [attr.aria-label]="text"
    class="link"
    >{{ text }}
    @if (openInNewTab) {
      <mat-icon svgIcon="external" data-testid="openInNewTabIcon"></mat-icon>
    }
  </a>
} @else {
  <a class="link" [attr.aria-label]="text" [href]="url" [target]="openInNewTab ? '_blank' : '_self'">
    {{ text }}
    @if (openInNewTab) {
      <mat-icon svgIcon="external" data-testid="openInNewTabIcon"></mat-icon>
    }
  </a>
}
