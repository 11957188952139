<!-- Context menu -->
<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>
<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent>
    @for (action of linkActions; track action) {
      <a mat-menu-item (click)="applyLinkAction(action)">
        <mat-icon class="icon-sm" [svgIcon]="action?.icon!"></mat-icon>
        <span>{{ action.title }}</span>
      </a>
    }
  </ng-template>
</mat-menu>

<ng-container>
  <div class="formfield large p-2" [ngClass]="{ 'formfield-masked': linkedDocumentControl.value }">
    <lib-form-field-label
      [label]="'Linked document' | translate"
      [control]="linkedDocumentControl"
      for="linkedDocumentInput"
    ></lib-form-field-label>

    <mat-form-field floatLabel="auto">
      <input
        matInput
        id="linkedDocumentInput"
        [value]="getDocumentDisplayValue()"
        placeholder="{{ 'Select a document' | translate }}"
        (click)="launchLinkDocDialog()"
        readonly
      />
      <a matSuffix (click)="handleLinkAction($event)">
        <mat-icon svgIcon="link" class="icon-md"></mat-icon>
      </a>
      @if (linkedDocumentControl.hasError("required")) {
        <mat-error>
          {{ "Document id is " | translate }}
          <strong>{{ "required" | translate }}</strong>
        </mat-error>
      }
    </mat-form-field>
  </div>
</ng-container>

<ng-container>
  <div class="formfield large p-2">
    <lib-text-input [label]="'Document display name' | translate" [control]="documentDisplayControl"></lib-text-input>
  </div>
</ng-container>
