@if (fiscalYears$ | async; as fiscalYearsOptions) {
  @if (fiscalYearsOptions.length > 0) {
    <lib-filter
      [buttonProps]="{ disabled: disabled, active: !disabled, icon: 'calendar', title: getButtonTitle() }"
      [optionProps]="{
        options: fiscalYearsOptions,
        activeOption: activeOption,
        defaultValue: { id: currentYear.toString(), title: currentYear.toString() },
        defaultValueTitle: getDefaultValueTitle(),
        title: titlePlaceholder,
      }"
      (optionSelected)="optionSelected.emit($event)"
    ></lib-filter>
  }
}
