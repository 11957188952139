<div class="card-dummies-container" [ngClass]="{ loaded: docLoaded }">
  <div class="h-100 card-item-dummies">
    <lib-dummy [presentation]="docLinkPresentation"></lib-dummy>
  </div>
  <a class="card">
    <div class="card-content">
      <div class="pr-2">
        <div class="caption2 color-grey-800 line-clamp">
          @if (document?.category) {
            <span> {{ document?.category }}</span>
          }
        </div>
        <div class="mat-body-1 color-primary-900 text-clamp text-clamp-2 mt-1">
          {{ docFileName || "Document link" }}
        </div>
      </div>
      <div class="file-icon" [ngClass]="'file-' + (documentIcon || 'no-fill')">
        <mat-icon [svgIcon]="documentIcon"></mat-icon>
      </div>
    </div>
    <div class="card-footer">
      <a
        class="btn btn-primary"
        (click)="downloadDocument()"
        [ngClass]="{ 'btn-disabled': !value?.type_details?.document_id }"
        >{{ "Download" | translate }}</a
      >
    </div>
  </a>
</div>
