import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Observable, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import {
  ActionItem,
  CalculatedValueReferences,
  EmptyResults,
  Metric,
  MetricCategory,
  MetricVisibleFields,
  MinimalIndicator,
  Presentation,
  SOURCE_CONFIGURATION,
} from '../../../models';

import { TranslateService } from '../../../services/common/translate/translate.service';
import { AlphabeticKeys } from '../../../translations';
import { MetricApiService } from '../../../services/types';
import cloneDeep from 'lodash/cloneDeep';
import { AutoCompleteInputComparisonMethod } from '../../../components';
import { IndicatorsApiService } from '../../../services/api-services';

export interface Variables {
  variablesDict: {
    [key: string]: CalculatedValueReferences;
  };
}

export interface AddVariableDialogData {
  metric: Metric;
  targetValueDefinitionId: string;
  variables?: ActionItem<CalculatedValueReferences>[];
  selectedMetric?: Metric;
  sourceConfiguration: SOURCE_CONFIGURATION;
  isAdmin: boolean;
}

@Component({
  selector: 'lib-add-variable-dialog',
  templateUrl: './add-variable-dialog.component.html',
  styleUrls: ['./add-variable-dialog.component.scss'],
})
export class AddVariableDialogComponent implements OnInit {
  valueDefinitionsChecked: CalculatedValueReferences[] = [];
  isValueDefinitionsCheckedValid: boolean = false;
  isValueDefinitionsCheckedOverLimit: boolean = false;
  tabIndex: number = 1;
  keys = this.translateService.listResources(AlphabeticKeys);
  valueDefinitionsCheckedLimit = this.keys.length;
  loaded: boolean = false;
  isLoadingInterMetrics: boolean = true;
  isAdmin: boolean = false;

  selectedMetric?: Metric;
  targetValueDefinitionId: string = '';
  sourceConfiguration: SOURCE_CONFIGURATION;

  readonly emptyResults: EmptyResults = {
    title: this.translateService.instant('No metric values'),
    subtitle: this.translateService.instant('Select a metric to see its values'),
    image: 'laptop-neutral',
  };

  indicators$: Observable<(Metric | MinimalIndicator)[]> = of([]);
  metricVisibleFields$?: Observable<MetricVisibleFields>;
  metricFormControl: UntypedFormControl = new UntypedFormControl();
  readonly eAutoCompleteInputComparisonMethod = AutoCompleteInputComparisonMethod;
  readonly ePresentation = Presentation;

  constructor(
    private dialogRef: MatDialogRef<AddVariableDialogComponent, Variables>,
    private metricsService: MetricApiService,
    private translateService: TranslateService,
    private indicatorsApiService: IndicatorsApiService,
    @Inject(MAT_DIALOG_DATA) public data: AddVariableDialogData,
  ) {
    this.isAdmin = this.data.isAdmin;
    this.sourceConfiguration = this.data.sourceConfiguration;
  }

  ngOnInit(): void {
    this.selectedMetric = cloneDeep(this.data.metric);
    this.targetValueDefinitionId = this.data.targetValueDefinitionId;
    this.valueDefinitionsChecked = cloneDeep(this.data.variables?.map((variable: ActionItem) => variable.item) || []);
    this.loaded = true;

    this.indicators$ = this.indicatorsApiService
      .listMinimalIndicators(this.data.metric.framework_id, {
        categories: [MetricCategory.CUSTOM, MetricCategory.REFERENCE, MetricCategory.THIRD_PARTY],
      })
      .pipe(
        map((response) => response.data),
        finalize(() => {
          this.isLoadingInterMetrics = false;
        }),
      );

    this.metricVisibleFields$ = this.metricsService
      .getMetricVisibleFields(this.data.metric.id)
      .pipe(map((response) => response.data));
  }

  isValueDefAdded(valueDefinitionId: string): boolean {
    return this.valueDefinitionsChecked.some((x) => x.value_definition_id == valueDefinitionId);
  }

  checkboxChanged(event: MatCheckboxChange, valuedefinitionGroupId: string, valueDefinitionId: string): void {
    const valueRef = this.valueDefinitionsChecked.find((def) => def.value_definition_id === event.source.value);
    if (event.checked && !valueRef) {
      this.valueDefinitionsChecked.push({
        metric_id: this.selectedMetric?.id ?? '',
        value_definition_group_id: valuedefinitionGroupId,
        value_definition_id: valueDefinitionId,
        mandatory: false,
      });
    } else if (valueRef) {
      this.valueDefinitionsChecked.splice(this.valueDefinitionsChecked.indexOf(valueRef), 1);
    }

    this.validateValueDefinitionsChecked(this.valueDefinitionsChecked);
  }

  addVariables(): void {
    const variablesDict: { [key: string]: CalculatedValueReferences } = {};
    this.valueDefinitionsChecked.forEach((valueRef, index) => {
      variablesDict[this.keys[index].title] = valueRef;
    });
    this.dialogRef.close({ variablesDict });
  }

  selected(selectedIndicator: Metric | MinimalIndicator): void {
    this.loaded = false;
    const metricId = selectedIndicator.metric_id ?? selectedIndicator.id;
    this.metricsService
      .getMetric(metricId, {
        load_value_definition_groups: true,
        load_value_definitions: true,
        show_deactivated: true,
        load_hidden_by_taxonomy: false,
      })
      .pipe(
        finalize(() => {
          this.loaded = true;
        }),
      )
      .subscribe((result) => {
        this.selectedMetric = cloneDeep(result.data);
      });
  }

  switchTab(tabIndex: number): void {
    this.loaded = false;
    switch (tabIndex) {
      case 1:
        this.tabIndex = 1;
        this.selectedMetric = cloneDeep(this.data.metric);
        this.metricFormControl.reset();
        break;
      case 2:
        this.tabIndex = 2;
        this.selectedMetric = undefined;
        this.isLoadingInterMetrics = true;
        break;
    }
    this.loaded = true;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  public bindMetricLabel(metric: Metric): string {
    return `${metric.code}: ${metric.description}`;
  }

  private validateValueDefinitionsChecked(valueDefinitionsChecked: CalculatedValueReferences[]): void {
    const valueDefinitionsCheckedCount = valueDefinitionsChecked.length;
    this.isValueDefinitionsCheckedOverLimit = valueDefinitionsCheckedCount > this.valueDefinitionsCheckedLimit;
    this.isValueDefinitionsCheckedValid = valueDefinitionsCheckedCount > 0 && !this.isValueDefinitionsCheckedOverLimit;
  }
}
