<div class="doc-card-container" [ngClass]="{ 'not-uploaded': !isFileAdded }">
  @if (fileValue.file_id && fileDoc) {
    <div class="fx-row">
      <div
        class="card card-link w-25 file mb-2"
        [ngClass]="{ 'card-downloading': isDownloading }"
        [attr.data-testid]="'file-card-' + fileDoc.id"
      >
        @if (!isDownloading) {
          <div class="fx-row justify-space-between">
            <div class="fx-row mr-1">
              <mat-icon
                class="icon-sm mr-2 color-grey-600"
                [ngClass]="{ 'color-primary-600': isFileAdded }"
                svgIcon="link"
              ></mat-icon>
              <button
                lib-button
                buttonType="link"
                [attr.data-testid]="'fileLink-' + fileDoc.id"
                (click)="downloadDoc()"
                [ngClass]="{ disabled: !isFileAdded }"
              >
                {{ fileDoc.name }}.{{ fileDoc.format }}
              </button>
            </div>
            @if (!fileFormGroup?.disabled) {
              <button
                type="button"
                [attr.aria-label]="'deleteIcon-' + fileDoc.id"
                (click)="deleteDocument()"
                class="icon-btn"
              >
                <mat-icon class="icon-sm" svgIcon="trash"></mat-icon>
              </button>
            }
          </div>
        }
        @if (isDownloading) {
          <div class="fx-row justify-space-between white-spinner">
            <span class="color-white">{{ fileDoc.name }}.{{ fileDoc.format }}</span>
            <lib-spinner ariaLabel="download-spinner" [diameter]="15"></lib-spinner>
          </div>
        }
      </div>
      @if (!isFileAdded) {
        <div class="fx-row ml-3 align-center">
          <mat-icon class="color-error mr-1" svgIcon="info"></mat-icon>
          <span> {{ "Complete all required fields to upload the file." | translate }}</span>
        </div>
      }
    </div>
  }
  @if (index | isFileGroupDeletable: isFileAdded : fileValue.file_id) {
    <div class="fx-row justify-end">
      <button
        class="icon-btn"
        type="button"
        (click)="deleteDocument()"
        [attr.aria-label]="'deleteIcon-' + index"
        [disabled]="isFormInProgress()"
      >
        <mat-icon svgIcon="trash"></mat-icon>
      </button>
    </div>
  }

  @if (fileFormGroup) {
    <form
      [formGroup]="fileFormGroup"
      class="form"
      (libBeforeUnload)="updateValueFormControl()"
      data-testid="url-section"
    >
      @if (fileFormGroup.docUrl) {
        <lib-text-input
          data-testid="doc-url"
          class="medium-large"
          [control]="fileFormGroup.docUrl"
          [label]="'URL' | translate"
          (handleBlur)="updateValueFormControl()"
        ></lib-text-input>
      }
      <lib-text-input
        data-testid="page-number"
        class="small"
        [control]="fileFormGroup.pageNumber"
        [label]="'Page Number' | translate"
        (handleBlur)="updateValueFormControl()"
      ></lib-text-input>
      <lib-text-area-input
        class="large"
        data-testid="explanation"
        [control]="fileFormGroup.explanation"
        [label]="explanationLabel"
        (handleBlur)="updateValueFormControl()"
        [maxLength]="explanationMaxLength"
      ></lib-text-area-input>
    </form>
  }
</div>
