<ng-container *libFeatureFlag="{ featureFlags: featureFlags }">
  <ng-container *libPermission="permissions">
    @if (navigationGroups.length) {
      <div class="nav-container" [ngClass]="{ 'root-active': isDirectoryPath() }">
        <button
          mat-button
          class="nav-button"
          [matMenuTriggerFor]="matMenu"
          [ngClass]="{ 'root-active--button': isDirectoryPath() }"
        >
          <ng-container *ngTemplateOutlet="content"></ng-container>
          <mat-icon svgIcon="arrow-head-down" class="more-option-icon" data-testid="moreOptionIcon"></mat-icon>
        </button>
      </div>
    } @else {
      <div class="nav-container" [ngClass]="{ 'root-active': isDirectoryPath() }">
        <a mat-button class="nav-button" [routerLink]="directoryRootPath" routerLinkActive="root-active--button">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </a>
      </div>
    }
  </ng-container>
</ng-container>

<mat-menu #matMenu="matMenu">
  @for (group of filteredNavigationGroups$ | async; track group; let last = $last) {
    <ng-container
      *libFeatureFlag="{ featureFlags: group?.featureFlag, hiddenWhenEnabled: group?.featureFlagHiddenWhenEnabled }"
    >
      @if (group.groupLabel) {
        <label class="group-label"> {{ group.groupLabel | titlecase }} </label>
      }
      @for (menu of group.options; track menu) {
        <ng-container
          *libFeatureFlag="{ featureFlags: menu?.featureFlag, hiddenWhenEnabled: menu?.featureFlagHiddenWhenEnabled }"
        >
          <ng-container *libPermission="menu?.permissions">
            @if (!menu.externalLink) {
              <a
                mat-menu-item
                routerLinkActive="active-item"
                class="item"
                target="_self"
                [routerLink]="menu.routerLink"
              >
                {{ menu.label | titlecase }}
              </a>
            } @else {
              <a mat-menu-item class="item" target="_blank" [href]="menu.externalLink">
                @if (menu.iconName) {
                  <mat-icon [svgIcon]="menu.iconName" data-testid="partner-login-icon"></mat-icon>
                }
                {{ menu.label }}
              </a>
            }
          </ng-container>
        </ng-container>
      }
      @if (!last && group.options.length > 0) {
        <lib-divider></lib-divider>
      }
    </ng-container>
  }
</mat-menu>

<ng-template #content><ng-content></ng-content></ng-template>
