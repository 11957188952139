<button lib-button [matMenuTriggerFor]="dropdownMenu" [disabled]="disabled" [label]="label" [buttonType]="type">
  <ng-content></ng-content>
</button>

<mat-menu #dropdownMenu="matMenu">
  @for (option of options; track option) {
    <button mat-menu-item class="custom-option" (click)="handleSelection.emit(option)">
      {{ option.title }}
    </button>
  }
</mat-menu>
