<mat-toolbar class="context-bar" [ngClass]="{ separator: withSeparator }">
  <div
    class="context-bar-primary-section"
    [ngClass]="{ 'pt-0 pb-2': !pageName && !subtitles.length && !additionalActions.length }"
  >
    <ng-content select="[logo]"></ng-content>
    <div class="title-container">
      <div class="fx-row w-100">
        @if (pageName) {
          <h4 #title libEllipsifyMe libTitleOnEllipsis data-testid="pageTitle" class="page-name">
            {{ pageName }}
          </h4>
        }
        @if (badgePageName) {
          <lib-chip [text]="badgePageName"></lib-chip>
        }
        <ng-content select="[title-extra-info]"></ng-content>
      </div>
      @if (subtitles.length > 0) {
        <div class="subtitle-container">
          <span class="spacer">—</span>
          @for (subtitle of subtitles; track subtitle) {
            <span [ngClass]="'subtitle-' + subtitle.themePalette" data-testid="pageSubtitle">{{ subtitle.title }}</span>
          }
        </div>
      }
    </div>

    <div class="ml-3 actions-container justify-end">
      @if (additionalActions.length > 0) {
        <div class="mr-2">
          @for (additionalAction of additionalActions; track additionalAction.id) {
            <ng-container
              *libFeatureFlag="{
                featureFlags: additionalAction.featureFlag,
                hiddenWhenEnabled: additionalAction.featureFlagHiddenWhenEnabled,
              }"
            >
              <ng-container *libPermission="additionalAction.permissions">
                <button
                  mat-button
                  (click)="action.emit(additionalAction.id)"
                  [disabled]="additionalAction.disabled"
                  [color]="additionalAction?.themePalette"
                  [attr.aria-label]="additionalAction.title"
                  [libViewerAction]="additionalAction?.viewerAction"
                  data-testid="additionalAction"
                >
                  <ng-container>
                    @if (additionalAction.icon; as icon) {
                      <mat-icon [svgIcon]="icon" class="additional-actions-icon"></mat-icon>
                    }
                    <span>{{ additionalAction.title }}</span>
                  </ng-container>
                </button>
              </ng-container>
            </ng-container>
          }
        </div>
      }

      <ng-content select="[main-actions]"></ng-content>
    </div>
  </div>

  @if (tabs.length) {
    <nav class="tabs-container">
      @for (tab of tabs; track tab) {
        <ng-container *libPermission="tab.permissions">
          <a
            role="tab"
            [routerLink]="tab.id"
            routerLinkActive="active"
            [title]="tab.title"
            *libFeatureFlag="{ featureFlags: tab.featureFlag, hiddenWhenEnabled: tab.featureFlagHiddenWhenEnabled }"
            data-testid="tabs"
          >
            {{ tab.title }}
          </a>
        </ng-container>
      }
    </nav>
  }
</mat-toolbar>
