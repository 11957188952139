<div class="content pt-1 pb-4 ph-4" cdkDropList [cdkDropListData]="valueDefinitionTemplates" cdkDropListSortingDisabled>
  @for (actionItem of formRequirementsItems; track actionItem) {
    @if (actionItem.id === "category") {
      <div class="caption-3 mv-3">
        {{ actionItem.title }}
      </div>
    }
    @if (actionItem.id !== "category" && !actionItem.disabled) {
      <a
        class="card card-condensed mb-1"
        [ngClass]="{ 'disabled no-blur': disableSpecialTypes || !!(isCreatingField$ | async) }"
        (click)="create(actionItem.id)"
        [attr.data-testid]="actionItem.id"
      >
        <div class="fx-row align-center">
          <mat-icon class="icon-md mr-2" [svgIcon]="actionItem.icon || ''"></mat-icon>
          <span>{{ actionItem.title }}</span>
        </div>
      </a>
    }
  }

  @for (valueDefinitionTemplate of valueDefinitionTemplates; track valueDefinitionTemplate) {
    @if (valueDefinitionTemplate.id === "category") {
      <div class="caption-3 mv-3" cdkDrag [cdkDragDisabled]="true">
        {{ valueDefinitionTemplate.name }}
      </div>
    }
    @if (valueDefinitionTemplate.id !== "category") {
      <a
        class="card card-condensed mb-1"
        [ngClass]="{
          'disabled no-blur': disableFields || valueDefinitionTemplate.disabled || !!(isCreatingField$ | async),
        }"
        cdkDrag
        [cdkDragDisabled]="disableFields"
        [attr.data-testid]="valueDefinitionTemplate.name"
      >
        <div class="fx-row align-center">
          <mat-icon class="icon-md mr-2" [svgIcon]="valueDefinitionTemplate.code || ''"></mat-icon>
          <span>{{ valueDefinitionTemplate.name }}</span>
        </div>
        <div class="fx-row align-center datatype-placeholder" *cdkDragPlaceholder>
          <mat-icon class="icon-md mr-2" [svgIcon]="valueDefinitionTemplate.code || ''"></mat-icon>
          <span>{{ valueDefinitionTemplate.name }}</span>
        </div>
      </a>
    }
  }
  @for (actionItem of specialActionItems; track actionItem) {
    @if (actionItem.id !== "category" && !actionItem.disabled) {
      <a
        class="card card-condensed mb-1"
        [ngClass]="{ 'disabled no-blur': disableSpecialTypes || !!(isCreatingField$ | async) }"
        (click)="create(actionItem.id)"
        [attr.data-testid]="actionItem.id"
      >
        <div class="fx-row align-center">
          <mat-icon class="icon-md mr-2" [svgIcon]="actionItem.icon || ''"></mat-icon>
          <span>{{ actionItem.title }}</span>
        </div>
      </a>
    }
  }
</div>
