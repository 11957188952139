<mat-menu #moreMenu="matMenu">
  <ng-template matMenuContent>
    <div class="ph-3 pt-1 menu-view">
      <ng-container *ngTemplateOutlet="chips; context: { items: remainingRelatedMetrics }"></ng-container>
    </div>
  </ng-template>
</mat-menu>

<div class="chip-card-container fx-row">
  <div class="chip-cards" [ngClass]="{ 'limited-view': maxItems === 1 }">
    <ng-container *ngTemplateOutlet="chips; context: { items: resourcesToShow }"></ng-container>
  </div>
  @if (remainingRelatedMetrics.length) {
    <button
      class="btn btn-xs bkg-grey-200 btn-more-link more-related-btn"
      [ngClass]="{ active: trigger.menuOpen }"
      [matMenuTriggerFor]="moreMenu"
      #trigger="matMenuTrigger"
      (click)="stopDefaultClickAction($event)"
      data-testid="additionalResources"
    >
      + {{ remainingRelatedMetrics.length }}
    </button>
  }
</div>

<ng-template #chips let-items="items">
  @for (chip of items; track chip) {
    <button
      class="btn btn-xs mr-1 related-metric"
      [ngClass]="{
        'btn-stroked': chip.type === relatedMetricsResourceType.standard_code,
        'btn-primary': chip.type === relatedMetricsResourceType.imported_metric,
        'bkg-gray-200 color-grey-900': chip.type === relatedMetricsResourceType.core_metric,
      }"
      (click)="onChipsClick($event, chip.item, chip.type, metricActionItem?.item?.code)"
    >
      {{ chip.item.code }}
    </button>
  }
</ng-template>
